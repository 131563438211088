/* eslint-disable react/jsx-no-comment-textnodes */
import React from 'react';
import { AppRoutes } from './routes';
import "bootstrap/dist/css/bootstrap.min.css";


function AppReact() {
  return (
    <>
      {
        <AppRoutes />
      }
    </>
  );
}
export default AppReact;

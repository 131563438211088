import React, { useState, useEffect } from 'react';
import { TextField } from '@mui/material';
import { Form } from 'react-bootstrap';
import Alert from '@mui/material/Alert';
import { getDetailBlog, updateBlog } from '../../../services/blog';
import { uploadStaffFile } from '../../../services/promotion';
import LoadingSpinner from '../../../components/Loading/LoadingSpinner';
import FileInput from '../../../components/FileInput/FileInput';
import { Editor } from '@tinymce/tinymce-react';
import { useParams } from 'react-router-dom';
import './detailblog.scss';
export function DetailBlog() {
  const [loading, setLoading] = useState(true);
  const [updating, setUpdating] = useState(false);
  const { blog_id } = useParams();
  const [imageblog, setidBlogImg] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');
  const [error, setError] = useState(null);
  const [is_active, setIsActive] = useState(true);

  const handleIsActiveChange = (e) => {
    const newValue = e.target.checked;
    setIsActive(newValue);
  };
  const UpdateChange = (e) => {
    const { name, value, type } = e.target;
    if (type === 'date') {
      value = new Date(value);
    }
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleIdBlogImgChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      let fData = new FormData();
      fData.append("image", file);
      const resultUpload = await uploadStaffFile(fData);
      if (resultUpload.status == 200) {
        const url = resultUpload.data.url;
        setidBlogImg(url);
      }
    }
  };
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    content: '',
    image: '',
  });
  useEffect(() => {
    fetchDetailBLog(blog_id);
  }, []);
  const fetchDetailBLog = async (blog_id) => {
    try {
      let dataUpdate = {
        ...formData
      };
      if (imageblog) {
        dataUpdate.image = imageblog;
      }
      dataUpdate.is_active = is_active;
      const response = await getDetailBlog(blog_id);
      const BlogData = response.data.data;
      setLoading(false);
      setFormData({
        title: BlogData.title,
        description: BlogData.description,
        content: BlogData.content,
        image: BlogData.image,
      });
      setIsActive(BlogData.is_active);
    } catch (error) {
      setLoading(false);
      console.error('Lỗi, không thể lấy thông tin chi tiết blog:', error);
    }
  };
  //sự kiện ẩn thông báo
  const closeAlerts = () => {
    setTimeout(() => {
      setSuccessMessage('');
      setError(null);
    }, 1500);
  };
  // cập nhật blog
  const handleUpdate = async (event) => {
    event.preventDefault();
    try {
      setUpdating(true);

      let dataUpdate = {
        ...formData,
        is_active: is_active,
      };
      if (imageblog) {
        dataUpdate.image = imageblog;
      }
      setIsActive(dataUpdate.is_active);
      await updateBlog(blog_id, dataUpdate);
      await fetchDetailBLog(blog_id);
      setSuccessMessage('Cập nhật thành công!');
      window.scrollTo(0, 0);
      closeAlerts();
    } catch (error) {
      setError('Cập nhật thất bại!');
      window.scrollTo(0, 0);
      closeAlerts();
      console.error('Lỗi khi cập nhật:', error);
    } finally {
      setUpdating(false);
    }
  };
  return (
    <Form onSubmit={handleUpdate} className="container-deltaiblog m-3">
      <h3 className='h3 text-center mb-4' style={{ marginTop: '20px', height: '40px' }}>Chi tiết Blog</h3>
      {successMessage && (
        <Alert
          variant="filled" severity="success"
          onClose={() => setSuccessMessage('')}
        >
          Cập nhật thành công!
        </Alert>
      )}
      {/* Error alert */}
      {error && (
        <Alert variant="filled" severity="error"
          onClose={() => setError('')}>
          Cập nhật thất bại!
        </Alert>
      )}
      {loading ? (
        <LoadingSpinner />
      ) : (
        <div className='row'>
          <div className='col-sm-6'>
            <Form.Group className="mb-3" controlId="">
              <TextField
                id="title"
                name="title"
                label={
                  <span>
                    Tiêu đề <span className="red-asterisk">*</span>
                  </span>
                }
                variant="outlined"
                size="small"
                className="custom-textfield"
                value={formData.title}
                onChange={UpdateChange}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="description">
              <TextField
                id="description"
                name="description"
                label={
                  <span>
                    Mô tả <span className="red-asterisk">*</span>
                  </span>
                }
                variant="outlined"
                fullWidth
                multiline
                minRows={5}
                className="custom-textfield"
                value={formData.description}
                onChange={UpdateChange}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="content">
              <Form.Label>Nội dung <span style={{ color: 'red' }}>*</span></Form.Label>
              <p style={{ color: 'red' }}>
                <strong style={{ textDecoration: 'underline' }}>Ghi chú:</strong> khi viết song nhớ copy nội dung vừa viết rồi chọn chữ là màu đen!
              </p>
              <Editor
                apiKey='00v9uql5l6aaerb4fcev4roo2porrtzammbxsbghcmo7lm22'
                value={formData.content}
                onEditorChange={(content) => UpdateChange({ target: { name: 'content', value: content } })}
                init={{
                  plugins: 'link image lists textcolor',
                  toolbar: 'undo redo | formatselect | bold italic underline strikethrough | alignleft aligncenter alignright | bullist numlist outdent indent | forecolor backcolor | image',
                  content_style: `body { color: black !important; line-height: 1.6; }h1, h2, h3, h4, h5, h6, p, span {margin: 5px; height: auto;} `
                }} />
            </Form.Group>
            <Form.Group className="mb-2" controlId="is_active">
              <Form.Label style={{ marginRight: '10px' }}>Trạng thái:</Form.Label>
              <div style={{ display: 'inline-block', marginRight: '20px' }}>
                <Form.Switch
                  name="is_active"
                  checked={is_active}
                  onChange={handleIsActiveChange}
                />
              </div>
              <span
                style={{
                  backgroundColor: is_active ? '#00ff62' : '#df2d05',
                  color: '#f9f8f8',
                  borderRadius: '8px',
                  border: `1px solid ${is_active ? '#00ff62' : '#df2d05'}`,
                  padding: '5px 10px',
                  fontSize: '13px',
                  fontWeight: 'bold',
                }}
              >
                {is_active ? 'Đang hoạt động' : 'Đã khóa'}
              </span>
            </Form.Group>
          </div>
          <div className="col-sm-6 d-flex justify-content-center">
            <div className="d-flex flex-column align-items-center">
              <Form.Group>
                <div className="d-flex flex-column align-items-center">
                  {formData.image && (
                    <img src={imageblog || formData.image}
                      alt="ảnh blog"
                      width="100%" height='auto'
                    />
                  )}
                  <FileInput id="avataInput" text='Tải ảnh blog' onChange={handleIdBlogImgChange} />
                </div>
              </Form.Group>
            </div>
          </div>
        </div>
      )}
      <div className="d-flex justify-content-center mt-3" style={{ marginBottom: '30px' }}>
        <button className="btn btn-success">
          {updating ? "Đang cập nhật..." : "Cập nhật"}
        </button>
      </div>
    </Form >
  )
}
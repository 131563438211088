import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { TextField, } from '@mui/material';
import { getListUser } from '../../../services/user';
import moment from 'moment';
import LoadingSpinner from '../../../components/Loading/LoadingSpinner'
import Pagination from '../../../components/Pagination/Pagination';
import './ListUser.scss'
export function ListUser() {
  const [users, setUsers] = useState([]);
  const [searchPhone, setSearchPhone] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [UserPerPage,] = useState(20);
  const [totalPage, setTotalPage] = useState(0);
  const [loading, setLoading] = useState(true);
  const [ErrorSearch, setErrorSearch] = useState(false);
  const formatDate = (date) => { return moment(date).format('YYYY-MM-DD'); };
  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
    window.scrollTo({ top: 0, behavior: 'smooth' });
    fetchData();
  };
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };
  const handleSearch = async () => {
    setCurrentPage(1);
    await fetchData();
  };
  const handleInputChange = (event) => { setSearchPhone(event.target.value); };
  const getStatusClass = (is_online) => {
    return is_online ? 'btn-active' : 'btn-inactive';
  };
  const fetchData = async () => {
    try {
      const params = {
        phone: searchPhone,
        current_page: currentPage,
        limit: UserPerPage,
      };

      const response = await getListUser(params);
      const data = response.data.data;
      if (data.length === 0) {
        setErrorSearch(true);
      } else {
        setErrorSearch(false);
      }
      setUsers(data);
      setTotalPage(response.data.total_page);
      setLoading(false);
    } catch (error) {
      console.error('Lỗi không tim thấy khách hàng:', error);
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, [currentPage]);
  return (
    <div className='container-user'>
      <div className='container'>
        <h3 className="h3">Danh sách khách hàng</h3>
        <div className='container'>
          <div class="col-sm-7 mb-3" style={{ marginTop: '40px' }}>
            <div className='row d-flex '>
              <div className="col-sm-4">
                <TextField
                  type='number'
                  id="phone"
                  label="Số điện thoại"
                  variant="outlined"
                  size="small"
                  value={searchPhone}
                  onChange={handleInputChange}
                  className='custum-rpsive-user'
                  onKeyDown={handleKeyDown}
                />
              </div>
              <div className='col-sm-3 col-sm-2'>
                <button type="button"
                  className="btn btn-outline-success pe-auto custum-rpsive-user"
                  onClick={handleSearch}
                >
                  Tìm kiếm
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className='container'>
          {loading ? (
            <LoadingSpinner />
          ) : (
            ErrorSearch ? (
              <h3 className="text-center h3 error-search" style={{ marginTop: '50px', color: '#949191' }}>Không có số điện thoại của khách hàng này</h3>
            ) : (
              <div className='cart-table table-responsive'>
                <table className="table table-prodcut table-sm table-hover">
                  <thead>
                    <tr className='text-center'>
                      <th>ID</th>
                      <th>Tên khách hàng</th>
                      <th>Số điện thoại</th>
                      <th >Địa chỉ</th>
                      <th>Ngày đăng ký</th>
                      <th>Trạng thái</th>
                    </tr>
                  </thead>
                  <tbody>
                    {users.map((user, index) => {
                      let address = user.Locations && user.Locations.find(location => location.is_default);
                      return (
                        <tr key={index} className='text-center'>
                          <td>
                            <Link to={`/user/${user.id}`}>{user.id}</Link>
                          </td>
                          <td > {user.fullname}</td>
                          <td>{user.phone}</td>
                          {user.Locations && user.Locations.length > 0 ? (
                            <td >
                              {address && address.formatted_address
                                ? address.other_street + address.formatted_address
                                : address?.other_street
                                  ? address.other_street + ", " + address?.street + ", " + address?.ward + ", " + address?.district + ", " + address?.province + ", " + address?.country
                                  : ""}
                            </td>
                          ) : (
                            <td></td>
                          )}
                          <td>{formatDate(user.created_at)}</td>
                          <td>
                            {user.is_active === false ? (
                              <button className={`status-button ${getStatusClass(user.is_online)}`}>
                                Tài khoản bị khóa
                              </button>
                            ) : user.is_online ? (
                              <button className={getStatusClass(user.is_online)}>
                                Online
                              </button>
                            ) : (
                              <button className={getStatusClass(user.is_online)}>
                                Đăng xuất vào lúc: {user.logged_out_at && moment(user.logged_out_at).format('HH:mm:ss DD/MM/YYYY')}
                              </button>
                            )}
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
            )
          )}
        </div>

        {
          totalPage > 1 && (
            <Pagination totalPage={totalPage} currentPage={currentPage} handleClick={handlePageClick} />
          )
        }
      </div>
    </div>
  )
}
import React from 'react';
import { Link } from 'react-router-dom';

export default function TabMenu({ activeTab, handleTabClick }) {
  return (
    <ul className="nav nav-pills m-3" role="tablist">
      <li className="nav-item">
        <Link
          className={`tag-offering nav-link ${activeTab === 'tag-offerings' ? 'active' : ''}`}
          onClick={() => handleTabClick('tag-offerings')}
        >
          Danh mục đồ cúng
        </Link>
      </li>
      <li className="nav-item">
        <Link
          className={`tag-offering nav-link ${activeTab === 'store-listings' ? 'active' : ''}`}
          onClick={() => handleTabClick('store-listings')}
        >
          Danh mục cửa hàng
        </Link>
      </li>
      {/*<li className="nav-item">
        <Link
          className={`tag-offering nav-link ${activeTab === 'tag-category' ? 'active' : ''}`}
          onClick={() => handleTabClick('tag-category')}
        >
          Danh mục sản phẩm
        </Link>
  </li>*/}
    </ul>
  )
}

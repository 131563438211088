import React, { useState, useEffect } from "react";
import { Form } from 'react-bootstrap';
import { Navigate } from "react-router-dom";
import Alert from '@mui/material/Alert';
import "react-datepicker/dist/react-datepicker.css";
import FileInput from "../../../components/FileInput/FileInput";
import { createPartner, getListProvince, getListDistrict, getListWard } from "../../../services/partner";
import { uploadStaffFile } from '../../../services/promotion';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import './addpartner.scss';
export function AddPartner() {
  const [fullname, setFullName] = useState("");
  const [sex, setSex] = useState("");
  const [isGenderSelected, setIsGenderSelected] = useState(false);
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [dob, setDob] = useState(null);
  const [id_card_number, setIdCardNumber] = useState("");
  const [is_active, setIsActive] = useState(true);
  const [type_partner, setTypePartner] = useState("");
  const [ward, setWard] = useState("")
  const [district, setDistrict] = useState("")
  const [province, setProvince] = useState("");
  const [address, setAddress] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordMatch, setPasswordMatch] = useState(false);
  const [bank_name, setBankName] = useState("");
  const [account_bank_number, setAccountBankNumber] = useState("");
  const [account_bank_holder_name, setAccountBankHolderName] = useState("");
  const [validationErrors, setValidationErrors] = useState({});
  const [avatar_partner, setPartnerImagePreview] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');
  const [error, setError] = useState("");
  const [id_card_img_behind, setidCardImgBehind] = useState(null);
  const [id_card_img_front, setidCardImgFront] = useState(null);
  const [create, setCreate] = useState(false);
  const handlesetWardChange = (event) => {
    setWard(event.target.value);
  };
  const handlesetDistrictChange = async (event) => {
    setDistrict(event.target.value);
    await fetchListWard(province, event.target.value);
  };
  const handleProvinceChange = async (event) => {
    const selectedProvinceCode = event.target.value;
    setProvince(selectedProvinceCode);

    const response = await getListDistrict({
      province_code: selectedProvinceCode
    });
    setListDistrict(response.data.list_district);
    setListWard([]);
  };
  const handleNameChange = (event) => setFullName(event.target.value);
  const handleGenderChange = (event) => {
    setSex(event.target.value);
    setIsGenderSelected(true);
  };
  const handlePhoneNumberChange = (event) => {
    const newPhoneNumber = event.target.value;
    const sanitizedPhoneNumber = newPhoneNumber.replace(/\D/g, '');
    setPhone(sanitizedPhoneNumber);
    if (sanitizedPhoneNumber.length !== 10) {
      setValidationErrors({ ...validationErrors, phone: 'Số điện thoại phải có đúng 10 số!' });
    } else {
      setValidationErrors({ ...validationErrors, phone: null });
    }
  };
  const handlePhoneNumberBlur = () => {
    if (phone.length !== 10) {
      setValidationErrors({ ...validationErrors, phone: 'Vui lòng nhập lại số điện thoại 10 số!' });
    } else {
      setValidationErrors({ ...validationErrors, phone: null });
    }
  };
  const handleEmailChange = (event) => {
    const newEmail = event.target.value;
    setEmail(newEmail);

    if (!newEmail.endsWith('@gmail.com')) {
      setValidationErrors({ ...validationErrors, email: 'Email phải có đuôi @gmail.com!' });
    } else {
      setValidationErrors({ ...validationErrors, email: null });
    }
  };
  const handleEmailBlur = () => {
    if (!email.endsWith('@gmail.com')) {
      setValidationErrors({ ...validationErrors, email: 'Email phải có đuôi @gmail.com!' });
    } else {
      setValidationErrors({ ...validationErrors, email: null });
    }
  };
  const handleDateChange = (event) => { setDob(event.target.value); };
  const handleIdCodeChange = (event) => {
    const newIdCardNumber = event.target.value.replace(/\D/g, '');
    setIdCardNumber(newIdCardNumber);

    if (newIdCardNumber.length !== 9 && newIdCardNumber.length !== 12) {
      setValidationErrors({ ...validationErrors, id_card_number: 'CCCD/CMND phải là 9 hoặc 12 số!' });
    } else {
      setValidationErrors({ ...validationErrors, id_card_number: null });
    }
  };
  const handleIdCodeBlur = () => {
    if (id_card_number.length !== 9 && id_card_number.length !== 12) {
      setValidationErrors({ ...validationErrors, id_card_number: 'CCCD/CMND phải là 9 hoặc 12 số!' });
    } else {
      setValidationErrors({ ...validationErrors, id_card_number: null });
    }
  };
  const handleJobPositionChange = (event) => setTypePartner(event.target.value);
  const handleAddressChange = (event) => setAddress(event.target.value);
  const handleBankNameChange = (event) => setBankName(event.target.value);
  const handleAccountNameChange = (event) => setAccountBankHolderName(event.target.value);

  const handleBankAccountNumberChange = (event) => {
    const newAccountNumber = event.target.value;
    const sanitizedInput = newAccountNumber.replace(/\D/g, '');
    setAccountBankNumber(sanitizedInput);
  };
  const handlePasswordChange = (event) => {
    const newPassword = event.target.value;
    setPassword(newPassword);
    if (confirmPassword !== newPassword) {
      setPasswordMatch(false);
    } else {
      setPasswordMatch(true);
    }
  };
  const handleConfirmPasswordChange = (event) => {
    const newConfirmPassword = event.target.value;
    setConfirmPassword(newConfirmPassword);
    if (password !== newConfirmPassword) {
      setPasswordMatch(false);
    } else {
      setPasswordMatch(true);
    }
  };
  const handleBlur = (e) => {
    const { name, value } = e.target;
    if (!value) {
      setValidationErrors({ ...validationErrors, [name]: 'Vui lòng điền dữ liệu!' });
    } else {
      setValidationErrors({ ...validationErrors, [name]: null });

      if (name === 'password' && value.length < 6) {
        setValidationErrors({ ...validationErrors, password: 'Vui lòng nhập mật khẩu có ít nhất 6 ký tự!' });
      }
    }
  };
  const handleImageChange = async (e) => {
    const file = e.target.files[0];

    if (file) {
      let fData = new FormData();
      fData.append("image", file);
      const resultUpload = await uploadStaffFile(fData);
      if (resultUpload.status === 200) {
        const url = resultUpload.data.url;
        setPartnerImagePreview(url);
      }
    }
  };
  const handleIsActiveChange = (e) => {
    setIsActive(e.target.checked);
  };
  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };
  const handleidCardImgBehindChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      let fData = new FormData();
      fData.append("image", file);
      const resultUpload = await uploadStaffFile(fData);
      if (resultUpload.status === 200) {
        const url = resultUpload.data.url;
        setidCardImgBehind(url);
      }
    }
  };
  const handleIdCardImgFrontChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      let fData = new FormData();
      fData.append("image", file);
      const resultUpload = await uploadStaffFile(fData);
      if (resultUpload.status === 200) {
        const url = resultUpload.data.url;
        setidCardImgFront(url);
      }
    }
  };
  const closeAlerts = () => {
    setTimeout(() => {
      setSuccessMessage('');
      setError(null);
    }, 1500);
  };
  const handleSubmit = async () => {
    try {
      setCreate(true);
      const errors = {};
      let hasError = false;

      if (!fullname) {
        errors.fullname = "Vui lòng điền họ tên!";
        hasError = true;
      }
      if (!sex) {
        errors.sex = "Vui lòng chọn giới tính!";
        hasError = true;
      }
      if (!phone || phone.length !== 10) {
        errors.phone = "Số điện thoại phải có đúng 10 số!";
        hasError = true;
      }
      if (!email) {
        errors.email = "Vui lòng điền email của bạn!";
        hasError = true;
      } else if (!email.endsWith('@gmail.com')) {
        errors.email = "Email phải có đuôi @gmail.com!";
        hasError = true;
      }
      if (!dob) {
        errors.dob = "Vui lòng điền ngày tháng năm sinh!";
        hasError = true;
      }
      if (!id_card_number || (id_card_number.length !== 9 && id_card_number.length !== 12)) {
        errors.id_card_number = "CCCD/CMND phải là 9 hoặc 12 số!";
        hasError = true;
      }
      if (!type_partner) {
        errors.type_partner = "Vui lòng chọn vị trí công việc!";
        hasError = true;
      }
      if (!ward) {
        errors.ward = "Vui lòng điền Phường/Xã!";
        hasError = true;
      }
      if (!district) {
        errors.district = "Vui lòng điền Quận/Huyện!";
        hasError = true;
      }
      if (!province) {
        errors.province = "Vui lòng điền Tỉnh/TP!";
        hasError = true;
      }
      if (!address) {
        errors.address = "Vui lòng điền địa chỉ của bạn!";
        hasError = true;
      }
      if (!password || password.length < 6) {
        errors.password = "Vui lòng nhập mật khẩu có ít nhất 6 ký tự!";
        hasError = true;
      }
      if (!bank_name) {
        errors.bank_name = "Vui lòng chọn ngân hàng";
        hasError = true;
      }

      if (!account_bank_number) {
        errors.account_bank_number = "Vui lòng nhập số tài khoản ngân hàng";
        hasError = true;
      }

      if (!account_bank_holder_name) {
        errors.account_bank_holder_name = "Vui lòng nhập tên chủ tài khoản ngân hàng";
        hasError = true;
      }

      if (!avatar_partner) {
        errors.avatar_partner = "Chọn ảnh chân dung của bạn";
        hasError = true;
      }

      if (!id_card_img_front) {
        errors.id_card_img_front = "Chọn ảnh CCCD/CMND mặt sau";
        hasError = true;
      }

      if (!id_card_img_behind) {
        errors.id_card_img_behind = "Chọn ảnh CCCD/CMND mặt trước";
        hasError = true;
      }
      if (hasError) {
        setValidationErrors(errors);
        return;
      }
      const data = {
        fullname,
        sex,
        phone,
        email,
        dob,
        id_card_number,
        ward,
        district,
        province,
        address,
        password,
        bank_name,
        account_bank_number,
        account_bank_holder_name,
        avatar_partner,
        id_card_img_front,
        id_card_img_behind,
        is_active,
        type_partner,
      };
      await createPartner(data);
      setSuccessMessage('Tạo mới thành công!');
      window.scrollTo(0, 0);
      closeAlerts();
      return <Navigate to="/partners" />;
    } catch (error) {
      setError('Tạo mới thất bại!');
      window.scrollTo(0, 0);
      closeAlerts();
      console.error('Lỗi không thể tạo đối tác:', error);
    } finally {
      setCreate(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  const fetchData = async () => {
    try {
      const response = await getListProvince();
      setListProvince(response.data.list_province);
      const data = response.data.data;
    } catch (error) {
      console.error("Lỗi lấy dữ liệu tỉnh huyện xã:", error);
    }
  };
  const fetchListWard = async (provinceCode, districtCode) => {
    try {
      const response = await getListWard({
        province_code: provinceCode,
        district_code: districtCode
      });
      setListWard(response.data.list_ward);
    } catch (error) {
      console.error("Error fetching ward data:", error);
    }
  };
  const [list_province, setListProvince] = useState([]);
  const [list_district, setListDistrict] = useState([]);
  const [list_ward, setListWard] = useState([]);
  return (
    <div className="container-addpartner container mt-3">
      {successMessage && (
        <Alert
          variant="filled" severity="success"
          onClose={() => setSuccessMessage('')}
        >
          Bạn đã tạo mới nhân viên thành công!
        </Alert>
      )}
      {error && (
        <Alert variant="filled" severity="error"
          onClose={() => setError('')}>
          Tạo mới nhân viên thất bại!
        </Alert>
      )}
      <div className='row'>
        <h3 className="h3">Thông tin chi tiết</h3>
        <form className='col-sm-6'>
          <div className="row">
            <div className="col-sm-6 mb-3 mt-3">
              <label htmlFor="fullname">Nhập họ và tên <span style={{ color: "red" }}>*</span>:</label>
              <input
                type="text"
                className={`form-control ${validationErrors.fullname ? 'invalid-border' : ''}`}
                id="fullname"
                name="fullname"
                value={fullname}
                onChange={handleNameChange}
                onBlur={handleBlur}
              />
              {validationErrors.fullname && <span style={{ color: 'red' }}>{validationErrors.fullname}</span>}
            </div>
            <div className="col-sm-6 mb-3 mt-3">
              <label htmlFor="phone">Nhập số điện thoại<span style={{ color: "red" }}>*</span>:</label>
              <input
                type="number"
                className={`form-control ${validationErrors.phone ? 'invalid-border' : ''}`}
                id="phone"
                name="phone"
                value={phone}
                onChange={handlePhoneNumberChange}
                onBlur={handlePhoneNumberBlur}
              />
              {validationErrors.phone && <span style={{ color: 'red' }}>{validationErrors.phone}</span>}
            </div>
          </div>
          <div className="mb-3 mt-3">
            <label>Chọn giới tính <span style={{ color: "red" }}>*</span>:</label> <br />
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                name="sex"
                id="0"
                value="0"
                checked={sex === "0"}
                onChange={handleGenderChange}
                onBlur={handleBlur}
              />
              <label className="form-check-label" htmlFor="0">
                Nam
              </label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                name="sex"
                id="1"
                value="1"
                checked={sex === "1"}
                onChange={handleGenderChange}
              />
              <label className="form-check-label" htmlFor="1">
                Nữ
              </label>
            </div>
            {(!isGenderSelected && validationErrors.sex) && (
              <span style={{ color: 'red' }}>{validationErrors.sex}</span>
            )}
          </div>
          <div className="mb-3 mt-3">
            <label htmlFor="email">Nhập Email<span style={{ color: "red" }}>*</span>:</label>
            <input
              type="email"
              className={`form-control ${validationErrors.email ? 'invalid-border' : ''}`}
              id="email"
              name="email"
              value={email}
              onChange={handleEmailChange}
              onBlur={handleEmailBlur}
            />
            {validationErrors.email && <span style={{ color: 'red' }}>{validationErrors.email}</span>}
          </div>
          <div className="row">
            <div className="col-sm-6 mb-3 mt-3">
              <label htmlFor="dob">Nhập ngày tháng năm sinh<span style={{ color: "red" }}>*</span>:</label>
              <div className="">
                <input
                  type="date"
                  className={`form-control ${validationErrors.dob ? 'invalid-border' : ''}`}
                  id="dob"
                  name="dob"
                  value={dob}
                  onChange={handleDateChange}
                  onBlur={handleBlur}
                />
                {validationErrors.dob && <span style={{ color: 'red' }}>{validationErrors.dob}</span>}
              </div>
            </div>
            <div className="col-sm-6 mb-3 mt-3">
              <label htmlFor="number">Nhập số CCCD/CMND <span style={{ color: "red" }}>*</span>:</label>
              <input
                type="number"
                className={`form-control ${validationErrors.id_card_number ? 'invalid-border' : ''}`}
                id="cccdCmnd"
                name="id_card_number"
                value={id_card_number}
                onChange={handleIdCodeChange}
                onBlur={handleIdCodeBlur}
              />
              {validationErrors.id_card_number && <span style={{ color: 'red' }}>{validationErrors.id_card_number}</span>}
            </div>
          </div>
          <Form.Group className="mb-3" controlId="type_partner">
            <Form.Label>Chức vụ<span style={{ color: 'red' }}>*</span></Form.Label>
            <Form.Select
              name="type_partner"
              className={`form-control ${validationErrors.type_partner ? 'invalid-border' : ''}`}
              value={type_partner}
              required
              onChange={handleJobPositionChange}
              onBlur={handleBlur}
            >
              <option value="">-- Chọn phân loại --</option>
              <option value="cleaner" defaultValue={type_partner.type_partner === "cleaner"}>
                Nhân viên vệ sinh
              </option>
              <option value="shop_worship" defaultValue={type_partner.type_partner === "shop_worship"}>
                Đối tác bán đồ cúng
              </option>
              <option value="repair_man" defaultValue={type_partner.type_partner === "repair_man"}>
                Vệ sinh máy lạnh
              </option>
            </Form.Select>
            {validationErrors.type_partner && <span style={{ color: 'red' }}>{validationErrors.type_partner}</span>}
          </Form.Group>
          <div className="row">
            <div className="col-sm-6 mt-3">
              <Form.Group>
                <Form.Label>Nhập Tỉnh/TP <span style={{ color: "red" }}>*</span>:</Form.Label>
                <Form.Control
                  as="select"
                  name="province"
                  className={`form-control ${validationErrors.province ? 'invalid-border' : ''}`}
                  value={province}
                  onChange={handleProvinceChange}
                  onBlur={handleBlur}
                >
                  <option value="">--Chọn tỉnh/TP--</option>
                  {list_province && list_province.length > 0 ? (
                    list_province.map((province) => (
                      <option key={province.id} value={province.code}>
                        {province.name.toUpperCase()}
                      </option>
                    ))
                  ) : (
                    <option value="" disabled>
                      Loading provinces...
                    </option>
                  )}
                </Form.Control>
                {validationErrors.province && <span style={{ color: 'red' }}>{validationErrors.province}</span>}
              </Form.Group>
            </div>
            <div className="col-sm-6  mt-3">
              <Form.Group>
                <Form.Label>Nhập Quận/Huyện <span style={{ color: "red" }}>*</span>:</Form.Label>
                <Form.Control
                  as="select"
                  name="district"
                  className={`form-control ${validationErrors.district ? 'invalid-border' : ''}`}
                  value={district}
                  onChange={handlesetDistrictChange}
                  onBlur={handleBlur}
                >
                  <option value="">--Chọn Quận/Huyện--</option>
                  {list_district && list_district.length > 0 ? (
                    list_district.map((district) => (
                      <option key={district.id} value={district.code}>
                        {district.name.toUpperCase()}
                      </option>
                    ))
                  ) : (
                    <option value="" disabled>
                      Loading district...
                    </option>
                  )}
                </Form.Control>
                {validationErrors.district && <span style={{ color: 'red' }}>{validationErrors.district}</span>}
              </Form.Group>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6  mt-3">
              <Form.Group>
                <Form.Label>Nhập Phường/Xã <span style={{ color: "red" }}>*</span>:</Form.Label>
                <Form.Control
                  as="select"
                  name="ward"
                  className={`form-control ${validationErrors.ward ? 'invalid-border' : ''}`}
                  value={ward}
                  onChange={handlesetWardChange}
                  onBlur={handleBlur}
                >
                  <option value="">--Chọn Phường/Xã--</option>
                  {list_ward && list_ward.length > 0 ? (
                    list_ward.map((ward) => (
                      <option key={ward.id} value={ward.id}>
                        {ward.name.toUpperCase()}
                      </option>
                    ))
                  ) : (
                    <option value="" disabled>
                      Loading ward...
                    </option>
                  )}
                </Form.Control>
                {validationErrors.ward && <span style={{ color: 'red' }}>{validationErrors.ward}</span>}
              </Form.Group>
            </div>

          </div>
          <div className="mt-4" style={{ marginTop: '10px', }}>
            <label htmlFor="address">Nhập địa chỉ chi tiết<span style={{ color: "red" }}>*</span>:</label>
            <input
              type="address"
              className={`form-control ${validationErrors.address ? 'invalid-border' : ''}`}
              id="address"
              name="address"
              value={address}
              onChange={handleAddressChange}
              onBlur={handleBlur}
            />
            {validationErrors.address && <span style={{ color: 'red' }}>{validationErrors.address}</span>}
          </div>
          <h3 className="h3 mt-3">Thông tin mật khẩu</h3>
          <div className="mb-3 mt-3 position-relative">
            <label htmlFor="pass">Nhập mật khẩu <span style={{ color: "red" }}>*</span>:</label>
            <div className="password-input-wrapper">
              <input
                type="password"
                className={`form-control ${validationErrors.password ? 'invalid-border' : ''}`}
                id="password"
                name="password"
                value={password}
                onChange={handlePasswordChange}
                onBlur={handleBlur}
              />
              {password.length >= 6 && (
                <div className="password-check-icon">
                  <CheckCircleOutlineIcon />
                </div>
              )}
            </div>
            {validationErrors.password && <span style={{ color: 'red' }}>{validationErrors.password}</span>}
          </div>
          <div className="mb-3 mt-3">
            <label htmlFor="pwd" className="form-label">
              Xác nhận lại mật khẩu <span style={{ color: "red" }}>*</span>:
            </label>
            <div className="password-input-wrapper">
              <input
                type="password"
                className='form-control'
                id="confirmPassword"
                name="confirmPassword"
                value={confirmPassword}
                onChange={handleConfirmPasswordChange}
                onBlur={handleBlur}
              />
              {validationErrors.confirmPassword && <span style={{ color: 'red' }}>{validationErrors.confirmPassword}</span>}
              {confirmPassword && (
                <div className="password-check-icon">
                  {passwordMatch ? (
                    <CheckCircleOutlineIcon style={{ color: 'green', fontWeight: 'bold' }} />
                  ) : (
                    <ClearRoundedIcon style={{ color: 'red', fontWeight: 'bold' }} />
                  )}
                </div>
              )}
            </div>
          </div>
          <h3 className="h3 mt-3">Thông tin tài khoản ngân hàng</h3>
          <div className="mb-3 mt-3">
            <label htmlFor="bankName">Nhập tên ngân hàng <span style={{ color: "red" }}>*</span>:</label>
            <input
              type="text"
              className={`form-control ${validationErrors.bank_name ? 'invalid-border' : ''}`}
              id="bankName"
              name="bank_name"
              value={bank_name}
              onChange={handleBankNameChange}
              onBlur={handleBlur}
            />
            {validationErrors.bank_name && <span style={{ color: 'red' }}>{validationErrors.bank_name}</span>}
          </div>

          <div className="mb-3 mt-3">
            <label htmlFor="bankAccountNumber">Nhập số tài khoản ngân hàng <span style={{ color: "red" }}>*</span>:</label>
            <input
              type="number"
              className={`form-control ${validationErrors.account_bank_number ? 'invalid-border' : ''}`}
              id="bankAccountNumber"
              name="account_bank_number"
              value={account_bank_number}
              onChange={handleBankAccountNumberChange}
              onBlur={handleBlur}
            />
            {validationErrors.account_bank_number && <span style={{ color: 'red' }}>{validationErrors.account_bank_number}</span>}
          </div>

          <div className="mb-3 mt-3">
            <label htmlFor="accountName">Nhập tên chủ tài khoản <span style={{ color: "red" }}>*</span>:</label>
            <input
              type="text"
              className={`form-control ${validationErrors.account_bank_holder_name ? 'invalid-border' : ''}`}
              id="accountName"
              name="account_bank_holder_name"
              value={account_bank_holder_name}
              onChange={handleAccountNameChange}
              onBlur={handleBlur}
            />
            {validationErrors.account_bank_holder_name && <span style={{ color: 'red' }}>{validationErrors.account_bank_holder_name}</span>}
          </div>
          <Form.Group className="mb-2" controlId="is_active">
            <Form.Label style={{ marginRight: '10px' }}>Trạng thái:</Form.Label>
            <div style={{ display: 'inline-block', marginRight: '20px' }}>
              <Form.Switch
                name="is_active"
                checked={is_active}
                onChange={handleIsActiveChange}
              />
            </div>
            <span
              style={{
                backgroundColor: is_active ? '#00ff62' : '#df2d05',
                color: '#ffffff',
                borderRadius: '8px',
                border: `1px solid ${is_active ? '#00ff62' : '#df2d05'}`,
                padding: '5px 10px',
                fontSize: '13px',
                fontWeight: 'bold',
              }}
            >
              {is_active ? 'Đang hoạt động' : 'Đã khóa'}
            </span>
          </Form.Group>
        </form>
        <div className="col-sm-6" >
          <Form.Group>
            <div className="d-flex flex-column align-items-center">
              <Form.Label>Ảnh chân dung<span style={{ color: 'red' }}>*</span></Form.Label>
              {avatar_partner && (
                <img
                  src={avatar_partner}
                  alt="Preview"
                  style={{ width: "200px", height: "200px", objectFit: "cover" }}
                />
              )}
              <FileInput id="AvatarPartner" text='Chụp ảnh' onChange={handleImageChange} />
              {!avatar_partner && validationErrors.avatar_partner && (
                <span style={{ color: 'red' }}>{validationErrors.avatar_partner}</span>
              )}
            </div>
          </Form.Group>
          {/* Front CCCD/CMND image */}
          <div className="container text-center mt-3">
            <div className="row">
              <div className="col-sm-6 d-flex justify-content-center id-card-mobile">
                <div className="d-flex flex-column align-items-center">
                  <Form.Group>
                    <Form.Label>Ảnh mặt trước CCCD<span style={{ color: 'red' }}>*</span></Form.Label>
                    <div className="image-container">
                      {id_card_img_behind && (
                        <img
                          src={id_card_img_behind}
                          alt="Front Preview"
                          width="175" height="210"

                        />
                      )}
                    </div>
                    <FileInput id="idCardBehindInput" text='Chọn ảnh' onChange={handleidCardImgBehindChange} />
                  </Form.Group>
                  {!id_card_img_behind && validationErrors.id_card_img_behind && (
                    <span style={{ color: 'red' }}>{validationErrors.id_card_img_behind}</span>
                  )}
                </div>
              </div>
              <div className="col-sm-6 d-flex justify-content-center id-card-mobile">
                <div className="d-flex flex-column align-items-center">
                  <Form.Group>
                    <Form.Label>Ảnh mặt sau CCCD<span style={{ color: 'red' }}>*</span></Form.Label>
                    <div className="image-container">
                      {id_card_img_front && (
                        <img
                          src={id_card_img_front}
                          alt="Back Preview"
                          width="175" height="210"

                        />
                      )}
                    </div>
                    <FileInput id="idCardFrontInput" text='Chọn ảnh' onChange={handleIdCardImgFrontChange} />
                  </Form.Group>
                  {!id_card_img_front && validationErrors.id_card_img_front && (
                    <span style={{ color: 'red' }}>{validationErrors.id_card_img_front}</span>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-center">
        <button
          type="button"
          className="btn-add-new btn btn-success"
          onClick={handleSubmit}
          style={{ marginBottom: '30px' }}
        >
          {create ? "Đang thêm mới nhân viên..." : "Thêm mới"}
        </button>
      </div>
    </div>
  );
}
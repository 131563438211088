export const PARTNER = '@partner';

export const STATUS_NEW = 1;
export const STATUS_HAS_PARTNER = 2;
export const STATUS_WAITTING = 3;
export const STATUS_DOING = 4;
export const STATUS_DONE = 5;
export const STATUS_CANCEL = 6;


export const DEPOSIT = 'Nạp tiền';
export const WITHDRAW = 'Rút tiền';
export const INCREASE = 'Cộng tiền';
export const DECREACSE = 'Trừ tiền';

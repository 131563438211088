import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import {
    FaHome,
    FaHeart,
    FaHandshake,
    FaTags,
    FaClipboardList,
    FaUsers,
    FaStar,
    FaFileInvoiceDollar,
    FaBlogger,
    FaFileAlt,
    FaMizuni,
    FaChair,
    FaRegBell,
} from "react-icons/fa";
import { FiX } from "react-icons/fi";
import { CiPower } from "react-icons/ci";
import { useDashboardLayout } from '../../components/Layouts/dashboard';
import LogoTing from '../../assets/images/logoting.jpg';
import avatar from '../../assets/images/avata.jpg';
import './sidebar.scss';
import { BsCashCoin, BsCardChecklist } from 'react-icons/bs';
export function Sidebar() {
    const [isNotificationsDropdownVisible, setIsNotificationsDropdownVisible] = useState(true);
    const { user, logout } = useAuth();
    const navigate = useNavigate();
    const dropdownRef = useRef(null);
    const [isLogoVisible, setIsLogoVisible] = useState(true);
    const { isOpenSidebar, ToggleSidebar } = useDashboardLayout();
    const [isOpen, setIsOpen] = useState(false);
    const handleSidebarToggle = () => {
        ToggleSidebar(isOpenSidebar);
    };
    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };
    const handleResize = () => {
        if (window.innerWidth <= 600) {
            setIsLogoVisible(false);
            setIsNotificationsDropdownVisible(true);
        } else {
            setIsLogoVisible(true);
            setIsNotificationsDropdownVisible(false);
        }
    };
    const handleLogout = () => {
        logout();
        navigate('/login');
    };
    useEffect(() => {
        handleResize();
        window.addEventListener('resize', handleResize);
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            window.removeEventListener('resize', handleResize);
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };
    const menuItems = [
        { to: '/home', icon: <FaHome />, title: 'Trang chủ', color: '#2196f3' },
        { to: '/users', icon: <FaUsers />, title: 'Khách hàng', color: '#FF6600' },
        { to: '/partners', icon: <FaHandshake />, title: 'Đối tác', color: '#795548' },
        { to: '/commission', icon: <BsCashCoin />, title: 'Tiền huê Hồng', color: 'red' },
        { to: '/transactions', icon: <FaFileInvoiceDollar />, title: 'Giao dịch', color: '#CCCC00' },
        { to: '/list-product', icon: <FaFileAlt />, title: 'Sản phẩm', color: '#993300' },
        { to: '/jobs', icon: <FaClipboardList />, title: 'Công việc', color: 'blue' },
        { to: '/conditioners', icon: <FaMizuni />, title: 'Vệ sinh máy lạnh', color: '#FF6600' },
        { to: '/sofas', icon: <FaChair />, title: 'Vệ sinh Sofa', color: '#FFCC00' },
        { to: '/workships', icon: <BsCardChecklist />, title: 'Danh mục', color: '#8B4513' },
        { to: '/promotions', icon: <FaTags />, title: 'Mã khuyễn mãi', color: '#e91e63' },
        { to: '/popups', icon: <FaHeart />, title: 'Popup', color: 'red' },
        { to: '/stars', icon: <FaStar />, title: 'Đánh giá', color: 'rgb(40, 175, 40)' },
        { to: '/Blogs', icon: <FaBlogger />, title: 'Blog', color: '#d1056c' },
        { to: '/notification', icon: <FaRegBell />, title: 'Gửi thông báo', color: 'red' },
    ];
    return (
        <div className="sidebar-container">
            <div className={`sidebar-inner ${window.innerWidth <= 600 ? (!isOpenSidebar ? 'is-collapsed' : 'is-fulled') : (!isOpenSidebar ? 'is-fulled' : 'is-collapsed')}`}>
                <span className="icon-close" onClick={handleSidebarToggle}><FiX /></span>
                <ul className='side-inner-menu'>
                    {isNotificationsDropdownVisible && (
                        <div className='notifications-dropdown' ref={dropdownRef} style={{
                            borderBottom: '1px solid #d4d9db', display: 'flex', alignItems: 'center',
                            justifyContent: 'center', height: '65px',
                        }}>
                            <button
                                type="button"
                                className="dropdown-toggle"
                                data-bs-toggle="dropdown"
                            >
                                {user && (
                                    <span className="avatar-container" onClick={toggleDropdown}>
                                        <img src={user.avatar || avatar} alt="" className="user-avatar" width='35' height='35' />
                                        <span className="user-fullname">{user.fullname}</span>
                                    </span>
                                )}
                            </button>
                            <ul className={`dropdown-menu ${isOpen ? 'show' : ''}`}>
                                <li>
                                    <Link className="dropdown-item" to="/login" onClick={handleLogout}>
                                        <span className='side-icon'>
                                            <CiPower />
                                        </span>
                                        Đăng xuất
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    )}
                    {isLogoVisible && (
                        <li className='side-menu-item-logo' style={{
                            borderBottom: '1px solid #d4d9db', display: 'flex', alignItems: 'center',
                            justifyContent: 'center', height: '65px',
                        }} id="logo-list-item">
                            <Link to="/home">
                                <span className="" style={{ color: '#2196f3', with: '110', }} >
                                    <img src={LogoTing} alt='logo Ting' width={100} height={50} className='sidebar-logoting' />
                                </span>
                            </Link>
                        </li>
                    )}
                    <div className='bar-sidebar'>
                        {menuItems.map((menuItem, index) => (
                            <li className='side-inner-menu-item' key={index}>
                                <Link to={menuItem.to} style={{ padding: '10px 20px' }}>
                                    <span className="icon-holder" style={{ color: menuItem.color }}>{menuItem.icon}</span>
                                    <span className="title">{menuItem.title}</span>
                                </Link>
                            </li>
                        ))}
                    </div>
                </ul>
            </div>
        </div>
    );
}
import { Link } from "react-router-dom";
import numeral from 'numeral';
import moment from 'moment';
import AddButton from "../../../components/Button/AddButton";
import TitleText from "../../../components/Title/Title";
import ClearIcon from '@mui/icons-material/Clear';
import React, { useState, useEffect } from 'react';
import { getWorShip } from "../../../services/worship";
import { getListProdcut, updateProdcut } from "../../../services/product";
import { showToastSuccess, showToastError } from "../../../components/Toast/toastHandler";
import {
  InputLabel,
  MenuItem,
  Select,
  FormControl,
  IconButton,
  InputAdornment,
  Button,
  Alert,
  Tooltip,
} from '@mui/material';
import Pagination from "../../../components/Pagination/Pagination";
import LoadingSpinner from "../../../components/Loading/LoadingSpinner";
import './index.scss'
import CustomTextField from "../../../components/TextField/CusTumTextField";
export function ListProdcut() {
  const [JobPerPage,] = useState(20);
  const [totalPage, setTotalPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [ErrorSearch, setErrorSearch] = useState('');
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [workships, setWorkships] = useState([]);
  const [productCode, setProductCode] = useState('');
  const [categoryId, setCategoryId] = useState('');
  const [selectedMenuItem, setSelectedMenuItem] = useState(null);
  const [hasSelectedItem, setHasSelectedItem] = useState(false);
  const [clearSelect, setClearSelect] = useState(false);
  const handleProductChange = (event) => {
    const selectedValue = event.target.value;
    setCategoryId(selectedValue);
    setSelectedMenuItem(selectedValue);
    setHasSelectedItem(true);
  };
  const handleClearSelect = () => {
    setCategoryId('');
    setSelectedMenuItem(null);
    setClearSelect(false);
  };
  const getStatusClass = (is_active) => {
    return is_active ? 'status-done' : 'status-cancelled';
  };
  const translateAtegoryId = (category_id) => {
    switch (category_id) {
      case 1:
        return (
          <span >Thôi nôi, đầy tháng</span>
        );
      case 2:
        return (
          <span >Cúng khai trương</span>
        );
      case 3:
        return (
          <span>Cúng vào nhà mới</span>
        );
      case 4:
        return (
          <span >Cúng rằm, mùng 1</span>
        );
      case 5:
        return (
          <span>Cúng tất niên</span>
        );
      case 6:
        return (
          <span>Cúng cô hồn</span>
        );
      case 7:
        return (
          <span>Cúng xe hơi</span>
        );
      default:
        return (
          <span >Cúng sao giải hạn</span>
        );
    }
  };
  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
    window.scrollTo({ top: 0, behavior: 'smooth' });
    fetchData();
  };
  const fetchData = async () => {
    try {
      const params = {
        current_page: currentPage,
        limit: JobPerPage,
        product_code: productCode,
        category_id: categoryId,
      };
      const response = await getListProdcut(params);
      const data = response.data.data;
      if (data.length === 0) {
        setErrorSearch('Tìm kiếm thất bại!');
      } else {
        setErrorSearch('');
      }
      setData(data);
      setTotalPage(response.data.total_page);
      setLoading(false);
      closeAlerts();
    } catch (error) {
      closeAlerts();
      setLoading(false);
    }
  };
  const fetchCategory = async () => {
    try {
      const response = await getWorShip();
      const workShipData = response.data.data;
      setWorkships(workShipData);
    } catch (error) {
    }
  };
  const handleStatusToggle = async (productId, currentStatus) => {
    try {
      const updatedProduct = await updateProdcut(productId, { is_active: !currentStatus });
      const successMessage = updatedProduct.data.message;
      const updatedData = data.map((element) =>
        element.id === productId ? { ...element, is_activ: updatedProduct.data.is_active } : element
      );
      setData(updatedData);
      await fetchData();
      showToastSuccess(successMessage);
    } catch (error) {
      showToastError(error.response?.data?.message);
    }
  };
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };
  const handleSearch = async () => {
    setCurrentPage(1);
    await fetchData();
  };
  const closeAlerts = () => {
    setTimeout(() => {
      setErrorSearch(null);
    }, 2500);
  };
  useEffect(() => {
    fetchCategory();
  }, []);
  useEffect(() => {
    fetchData();
  }, [currentPage]);
  return (
    <div className="container-List__prodcut">
      <div className="container">
        {ErrorSearch && (
          <Alert variant="filled" severity="error" className='search-error'>
            {ErrorSearch}
          </Alert>
        )}
        <div className="text_btn mt-3">
          <TitleText
            text='Danh sách sản phẩm'
          />
          <Link to='/create-product'>
            <AddButton text='Tạo mới' />
          </Link>
        </div>
        <div className="filter__search mt-4">
          <div className="row ">
            <div className="col-sm-2">
              <CustomTextField
                type='text'
                id="productCode"
                label="Mã sản phẩm"
                value={productCode}
                onChange={(e) => setProductCode(e.target.value)}
                onKeyDown={handleKeyDown}
                className='custum-rpsive-job'
              />
            </div>
            <div className="col-sm-4">
              <FormControl fullWidth>
                <InputLabel id='category_id'>
                  Danh mục sản phẩm
                </InputLabel>
                <Select
                  labelId='category_id'
                  id='category_id'
                  name='category_id'
                  label='Danh mục sản phẩm'
                  size='small'
                  value={categoryId}
                  onChange={handleProductChange}
                  className={`custum-rpsive-job selectContainer ${selectedMenuItem !== null ? 'hide-dropdown-icon' : ''}`}
                  endAdornment={
                    categoryId && (
                      <InputAdornment position='end'>
                        <IconButton
                          aria-label='Clear Selection'
                          edge='end'
                          onClick={handleClearSelect}
                        >
                          <ClearIcon className='icon-clear-product' />
                        </IconButton>
                      </InputAdornment>
                    )
                  }
                  inputProps={{
                    onFocus: () => setClearSelect(!!categoryId),
                    onBlur: () => setClearSelect(false),
                  }}
                >
                  {workships.map((element) => (
                    <MenuItem key={element.id} value={element.id} style={{ textAlign: 'center' }}>
                      <img
                        src={element.icon}
                        alt={element.name}
                        className="worship-icon"
                        width={30}
                        height={30}
                        style={{ marginRight: '10px' }}
                      />
                      {element.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className=" col-sm-2">
              <Button
                type="button"
                variant="contained"
                color="success"
                style={{ marginLeft: '10px', textTransform: 'math-auto' }}
                onClick={handleSearch}
              >
                Tìm Kiếm
              </Button>
            </div>
          </div>
        </div>
        <div className='mt-3'>
          {loading ? (
            <LoadingSpinner />
          ) : (
            <div className='cart-table table-responsive'>
              <table className="table table-prodcut table-sm table-hover">
                <thead>
                  <tr className='text-center'>
                    <th>Mã sản phẩm</th>
                    <th>Hình sản phẩm</th>
                    <th>Tên sản phẩm</th>
                    <th>Danh Mục</th>
                    <th>Giá gốc</th>
                    <th>Giá đã giảm</th>
                    <th>Ngày tạo</th>
                    <th>Tồn</th>
                    <th>Trạng thái</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((element, index) => (
                    <tr key={index} className='text-center ' style={{ verticalAlign: 'middle', textAlign: 'center' }}>
                      <td>
                        <Link to={`/prodcut/${element.id}`}>{element.product_code}</Link>
                      </td>
                      <td>
                        <img
                          src={element.product_image}
                          alt={element.name}
                          width={40} height={40}
                          className="object-fit-cover"
                        />
                      </td>
                      <td>
                        {element.product_name}
                      </td>
                      <td>
                        {translateAtegoryId(element.category_id)}
                      </td>
                      <td>
                        {numeral(element.price).format("0,0")} VNĐ
                      </td>
                      <td>
                        {numeral(element.price_sale).format("0,0")} VNĐ
                      </td>
                      <td>
                        {moment(element.created_at).format("DD-MM-YYYY")}
                      </td>
                      <td>
                        {element.stock}
                      </td>
                      <td>
                        <Tooltip title="Click để thay đổi trạng thái" placement="left">
                          <button
                            className={getStatusClass(element.is_active)}
                            onClick={() => handleStatusToggle(element.id, element.is_active)}
                          >
                            {element.is_active ? 'Đang Mở' : 'Bị khóa'}
                          </button>
                        </Tooltip>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
        {
          totalPage > 1 && (
            <Pagination totalPage={totalPage} currentPage={currentPage} handleClick={handlePageClick} />
          )
        }
      </div>
    </div>

  )
}
import { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';
import { useDashboardLayout } from '../../components/Layouts/dashboard';
import { FaBars } from "react-icons/fa";
import { CiPower } from "react-icons/ci";
import avatar from '../../assets/images/avata.jpg';
import LogoTing from '../../assets/images/logoting.jpg';
import './navigation.scss';

export function Navigation() {
  const { user, logout } = useAuth();
  const { isOpenSidebar, ToggleSidebar } = useDashboardLayout();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleSidebarToggle = () => {
    ToggleSidebar(isOpenSidebar);
  };

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 600);
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const renderLogoMenuItem = () => {
    return (
      <ul className='side-inner-menu m-0 p-0'>
        <li className='side-menu-item-logo'
          style={{
            display: 'flex',
            alignItems: 'center', justifyContent: 'center'
          }}>
          <Link to="/home">
            <span className="" style={{
              color: '#2196f3', width: '110px',
            }}>
              <img src={LogoTing} alt='logo Ting' width={90} height={40} className='sidebar-logoting' />
            </span>
          </Link>
        </li>
      </ul>
    );
  };
  return (
    <div className="container-navigation-page">
      <div className='container-navigation'>
        <div className="nav-left" >
          <div className='nav-bar' onClick={handleSidebarToggle}><FaBars /></div>
        </div>
        <div className='nav-right'>
          {isSmallScreen && renderLogoMenuItem()}
          {!isSmallScreen && (
            <div className='notifications-dropdown' ref={dropdownRef}>
              <button
                type="button"
                className="dropdown-toggle"
                data-bs-toggle="dropdown"
                onClick={toggleDropdown}>
                {user && (
                  <span className="avatar-container">
                    <img src={user.avatar || avatar} alt="" className="user-avatar" width='35' height='35' />
                    <span className="user-fullname">{user.fullname}</span>
                  </span>
                )}
              </button>
              <ul className={`dropdown-menu ${isOpen ? 'show' : ''}`}>
                <li>
                  <Link className="dropdown-item" to="/login" onClick={handleLogout}>
                    <span className='side-icon'>
                      <CiPower />
                    </span>
                    Đăng xuất
                  </Link>
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

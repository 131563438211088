import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import Button from '@mui/material/Button';
import numeral from 'numeral';
import Alert from '@mui/material/Alert';
import moment from 'moment';
import { getDetailPartner, updatePartner, getListProvince, getListDistrict, getListWard, Withdrawal, PlusMoney } from '../../../services/partner';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import LoadingSpinner from '../../../components/Loading/LoadingSpinner';
import FileInput from "../../../components/FileInput/FileInput";
import { uploadStaffFile } from '../../../services/promotion';
import { useAuth } from '../../../hooks/useAuth';
import { Modal } from 'react-bootstrap';
import './detailPartner.scss';
import { FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";

export function DetailPartner() {
    const { user } = useAuth();
    const [partnerImagePreview, setPartnerImagePreview] = useState(null);
    const [idCardImgBehind, setidCardImgBehind] = useState(null);
    const [idCardImgFront, setidCardImgFront] = useState(null);
    const [successMessage, setSuccessMessage] = useState('');
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [list_district, setListDistrict] = useState([]);
    const [list_ward, setListWard] = useState([]);
    const [list_province, setListProvince] = useState([]);
    const [ward, setWard] = useState("")
    const [province, setProvince] = useState("");
    const [district, setDistrict] = useState("");
    const [lockSuccessMessage, setLockSuccessMessage] = useState('');
    const [unlockSuccessMessage, setUnlockSuccessMessage] = useState('');
    const [confirmPassword, setConfirmPassword] = useState("");
    const [passwordMatch, setPasswordMatch] = useState(false);
    const [newpassword, setNewPassword] = useState("");
    const [isOpen, setIsOpen] = useState(false);
    const [isOpenMoney, setIsOpenMoney] = useState(false);
    const [deductionAmount, setDeductionAmount] = useState('');
    const [plusAmount, setplusAmount] = useState('');
    const [deductionReason, setDeductionReason] = useState('');
    const [ReasonAddingMoney, setReasonAddingMoney] = useState('');
    const [deductionSuccessMessage, setDeductionSuccessMessage] = useState('');
    const [deductionErrorMessage, setDeductionErrorMessage] = useState('');
    const [PlusMoneyMessage, setPlusMoneyMessage] = useState('');
    const [PlusMoneyError, setPlusMoneyError] = useState('');
    const handleOpenMoneyDeduction = () => {
        setIsOpen(true);
    };
    const handleOpenPublicMoney = () => {
        setIsOpenMoney(true);
    };
    const handleCancel = () => {
        setIsOpen(false);
        resetForm();
    };
    const handleCancelMoney = () => {
        setIsOpenMoney(false);
        resetForm();
    };
    const resetForm = () => {
        setplusAmount('');
        setReasonAddingMoney('');
        setDeductionAmount('');
        setDeductionReason('');
    };
    const formatMoney = (value) => numeral(value).format('0,0');
    const formatDate = (date) => { return moment(date).format('YYYY-MM-DD'); };
    const getColorClass = (rating) => {
        if (rating >= 4.5) {
            return "star-green";
        } else if (rating >= 4) {
            return "star-orange";
        } else {
            return "star-red";
        }
    };
    const handleMoneyChange = (event) => {
        setDeductionAmount(event.target.value);
    };
    const handlePlusAmountChange = (event) => {
        setplusAmount(event.target.value);
    };
    const handleReasonChange = (event) => {
        const newReason = event.target.value;
        setDeductionReason(newReason);
    };
    const handleAddingMoneyChange = (event) => {
        const newReason = event.target.value;
        setReasonAddingMoney(newReason);
    };
    const handleDeductionSubmit = async () => {
        try {
            const deductionData = {
                content: deductionReason,
                amount: parseFloat(deductionAmount),
                partner_id: parseInt(partnerId),
            };

            await Withdrawal(deductionData);
            setDeductionSuccessMessage('Trừ tiền thành công!');
            window.scrollTo(0, 0);
            closeAlerts();
        } catch (error) {
            window.scrollTo(0, 0);
            closeAlerts();
            setDeductionErrorMessage('Trừ tiền thất bại!');
        } finally {
            setIsOpen(false);
            resetForm();
        }
    };
    const handlePlusMoneySubmit = async () => {
        try {
            const PlusData = {
                content: ReasonAddingMoney,
                amount: parseFloat(plusAmount),
                partner_id: parseInt(partnerId),
            };

            await PlusMoney(PlusData);
            setDeductionSuccessMessage('Cộng tiền thành công!');
            window.scrollTo(0, 0);
            closeAlerts();
        } catch (error) {
            window.scrollTo(0, 0);
            closeAlerts();
            setDeductionErrorMessage('Cộng tiền thất bại!');
        } finally {
            setIsOpenMoney(false);
            resetForm();
        }
    };
    const handleToggleChange = async (e) => {
        try {
            setFormData({ ...formData, is_active: e.target.checked });
            const locked = !e.target.checked;
            await updatePartner(partnerId, { is_active: !locked });
            if (locked) {
                setLockSuccessMessage('Tài khoản đã bị khóa!');
                setUnlockSuccessMessage('');
                window.scrollTo(0, 0);
                setTimeout(() => {
                    setLockSuccessMessage('');
                }, 1500);
            } else {
                setUnlockSuccessMessage('Tài khoản của bạn đã được mở lại!');
                setLockSuccessMessage('');
                window.scrollTo(0, 0);
                setTimeout(() => {
                    setUnlockSuccessMessage('');
                }, 1500);
            }
        } catch (error) {
            setError('Cập nhật thất bại!');
            setLockSuccessMessage('');
            setUnlockSuccessMessage('');
            window.scrollTo(0, 0);
        }
    };
    const handlePasswordChange = (event) => {
        const newPassword = event.target.value;
        setNewPassword(newPassword);
        if (confirmPassword !== newPassword) {
            setPasswordMatch(false);
        } else {
            setPasswordMatch(true);
        }
    };
    const handleConfirmPasswordChange = (event) => {
        const newConfirmPassword = event.target.value;
        setConfirmPassword(newConfirmPassword);
        if (newpassword !== newConfirmPassword) {
            setPasswordMatch(false);
        } else {
            setPasswordMatch(true);
        }
    };
    const handleChange = (e) => {
        const { name, value, type } = e.target;
        if (type === 'date') {
            const updatedValue = new Date(value);
            setFormData({ ...formData, [name]: updatedValue });
        } else {
            const updatedValue = name === 'code' ? value.toUpperCase() : value;
            setFormData({ ...formData, [name]: updatedValue });
        }
    };
    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const partnerResponse = await getDetailPartner(partnerId);
            const partnerData = partnerResponse.data.data;
            const provinceResponse = await getListProvince();
            setListProvince(provinceResponse.data.list_province);
            const districtResponse = await getListDistrict({
                province_code: partnerData.province,
            });
            setListDistrict(districtResponse.data.list_district);
            const wardResponse = await getListWard({
                province_code: partnerData.province,
                district_code: partnerData.district,
            });
            setListWard(wardResponse.data.list_ward);
        } catch (error) {
            console.error("Error fetching partner details:", error);
        }
    };
    const handleProvinceChange = async (event) => {
        const province = event.target.value;
        setProvince(province);
        try {
            const response = await getListDistrict({
                province_code: province,
            });
            setListDistrict(response.data.list_district);
            setListWard([]);
            setDistrict("");
            setFormData({
                ...formData,
                province: province,
            });
        } catch (error) {
            console.error("không lấy được dữ liệu Quận/Huyện:", error);
        }
    };
    const handlesetDistrictChange = async (event) => {
        const district = event.target.value;
        setDistrict(district);
        setFormData({
            ...formData,
            district: district,
        });
        await fetchListWard(province, district);
    };
    const fetchListWard = async (provinceCode, districtCode) => {
        try {
            const response = await getListWard({
                province_code: provinceCode,
                district_code: districtCode
            });
            setListWard(response.data.list_ward);
        } catch (error) {
            console.error("không lấy được dữ liệu Xã Phường:", error);
        }
    };
    const handlesetWardChange = (event) => {
        const ward = event.target.value;
        setWard(ward);
        setFormData({
            ...formData,
            ward: ward,
        });
    };
    const { partnerId } = useParams();
    const [formData, setFormData] = useState({
        fullname: '',
        dob: '',
        sex: '',
        email: '',
        phone: '',
        province: '',
        district: '',
        ward: '',
        address: '',
        id_card_number: '',
        is_active: true,
        type_partner: '',
        current_balance: '',
        password: '',
        bank_name: '',
        account_bank_number: '',
        account_bank_holder_name: '',
        average_vote_star: '',
        avatar_partner: null,
        id_card_img_behind: null,
        id_card_img_front: null,
    });
    useEffect(() => {
        fetchDetailPartner(partnerId);
    }, [partnerId]);
    const fetchDetailPartner = async (partnerId) => {
        try {
            const response = await getDetailPartner(partnerId);
            const PartnerData = response.data.data;
            setLoading(false);
            setFormData({
                fullname: PartnerData.fullname,
                dob: PartnerData.dob,
                sex: PartnerData.sex,
                id_card_number: PartnerData.id_card_number,
                email: PartnerData.email,
                phone: PartnerData.phone,
                province: PartnerData.province,
                district: PartnerData.district,
                ward: PartnerData.ward,
                address: PartnerData.address,
                type_partner: PartnerData.type_partner,
                password: '',
                is_active: PartnerData.is_active,
                bank_name: PartnerData.bank_name,
                account_bank_number: PartnerData.account_bank_number,
                account_bank_holder_name: PartnerData.account_bank_holder_name,
                avatar_partner: PartnerData.avatar_partner,
                id_card_img_behind: PartnerData.id_card_img_behind,
                id_card_img_front: PartnerData.id_card_img_front,
                current_balance: PartnerData.current_balance,
                average_vote_star: PartnerData.average_vote_star,
            });
        } catch (error) {
            setLoading(false);
            console.error('Lỗi, không thể lấy thông tin chi tiết parter:', error);
        }
    };
    const handleImageChange = async (e) => {
        const file = e.target.files[0];
        if (file) {
            let fData = new FormData();
            fData.append("image", file);
            const resultUpload = await uploadStaffFile(fData);
            if (resultUpload.status == 200) {
                const url = resultUpload.data.url;
                setPartnerImagePreview(url);
            }
        }
    };
    const handleidCardImgBehindChange = async (e) => {
        const file = e.target.files[0];

        if (file) {
            let fData = new FormData();
            fData.append("image", file);
            const resultUpload = await uploadStaffFile(fData);
            if (resultUpload.status == 200) {
                const url = resultUpload.data.url;
                setidCardImgBehind(url);
            }
        }
    };
    const handleIdCardImgFrontChange = async (e) => {
        const file = e.target.files[0];
        if (file) {
            let fData = new FormData();
            fData.append("image", file);
            const resultUpload = await uploadStaffFile(fData);
            if (resultUpload.status == 200) {
                const url = resultUpload.data.url;
                setidCardImgFront(url);
            }
        }
    };
    const closeAlerts = () => {
        setTimeout(() => {
            setSuccessMessage('');
            setDeductionSuccessMessage('');
            setLockSuccessMessage('');
            setUnlockSuccessMessage('');
            setDeductionErrorMessage('');
            setPlusMoneyMessage('');
            setPlusMoneyError('');
            setError(null);
        }, 2000);
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const dataUpdate = { ...formData };
            if (partnerImagePreview) {
                dataUpdate.avatar_partner = partnerImagePreview;
            }
            if (newpassword.length >= 6) {
                dataUpdate.password = newpassword;
            }
            if (idCardImgFront) {
                dataUpdate.id_card_img_front = idCardImgFront;
            }
            if (idCardImgBehind) {
                dataUpdate.id_card_img_behind = idCardImgBehind;
            }
            if (!province) {
                dataUpdate.province = formData.province;
            }
            if (!district) {
                dataUpdate.district = formData.district;
            }
            if (!ward) {
                dataUpdate.ward = formData.ward;
            }
            await updatePartner(partnerId, dataUpdate);
            setSuccessMessage('Cập nhật thành công!');
            setFormData(dataUpdate);
            setNewPassword('');
            window.scrollTo(0, 0);
            closeAlerts();
            fetchDetailPartner(partnerId);
        } catch (error) {
            setError('Cập nhật thất bại!');
            window.scrollTo(0, 0);
            closeAlerts();
            console.error('Lỗi khi cập nhật:', error);
        }
    };

    return (
        <Form onSubmit={handleSubmit} className="container container-detailPartner">
            {lockSuccessMessage && (<Alert variant="filled" severity="error"
                onClose={() => setLockSuccessMessage('')}
                dismissible className="custum-alert">
                {lockSuccessMessage}
            </Alert>
            )}
            {unlockSuccessMessage && (<Alert variant="filled" severity="success"
                onClose={() => setUnlockSuccessMessage('')}
                dismissible className="custum-alert">
                {unlockSuccessMessage}
            </Alert>
            )}
            {/* Thông báo thành công */}
            {successMessage && (
                <Alert variant="filled" severity="success"
                    onClose={() =>
                        setSuccessMessage('')} className="custum-alert">
                    Cập thành công!
                </Alert>
            )}
            {/* Error alert */}
            {error && (<Alert variant="filled"
                severity="error"
                onClose={() => setError('')} className="custum-alert">
                Cập nhật thất bại!
            </Alert>
            )}
            {deductionSuccessMessage && (
                <Alert
                    variant="filled"
                    severity="success"
                    onClose={() => setDeductionSuccessMessage('')}
                    dismissible
                    className="custum-alert"
                >
                    {deductionSuccessMessage}
                </Alert>
            )}

            {PlusMoneyMessage && (
                <Alert
                    variant="filled"
                    severity="error"
                    onClose={() => setPlusMoneyMessage('')}
                    dismissible
                    className="custum-alert"
                >
                    {PlusMoneyMessage}
                </Alert>
            )}
            {PlusMoneyError && (
                <Alert
                    variant="filled"
                    severity="success"
                    onClose={() => setPlusMoneyError('')}
                    dismissible
                    className="custum-alert"
                >
                    {PlusMoneyError}
                </Alert>
            )}
            {deductionErrorMessage && (
                <Alert
                    variant="filled"
                    severity="error"
                    onClose={() => setDeductionErrorMessage('')}
                    dismissible
                    className="custum-alert"
                >
                    {deductionErrorMessage}
                </Alert>
            )}
            {loading ? (
                <LoadingSpinner />
            ) : (
                <div className="row">
                    <h3 className="h3 mt-2">Thông tin chi tiết</h3>
                    <div className="row">
                        {/* Display full name   */}
                        <Form.Group className="col-sm-3 " controlId="fullname">
                            <Form.Label className="information-partner">Họ và tên
                                <span style={{ color: 'red' }}>*</span>
                            </Form.Label>
                            <Form.Control
                                type="text"
                                name="fullname"
                                required
                                value={formData.fullname}
                                onChange={handleChange}
                            />
                        </Form.Group>
                        {/* Display Số dư hiện tại */}
                        <Form.Group className="col-sm-2 custom-partner" controlId="current_balance">
                            <Form.Label className="information-partner">Số dư hiện tại
                                <span style={{ color: 'red' }}>*</span>
                            </Form.Label>
                            <Form.Control
                                type="text"
                                name="current_balance"
                                readOnly
                                value={formatMoney(formData.current_balance) + "đ"}
                                className='input-disabled custum-current_balance'
                            />
                        </Form.Group>
                        {/* Display Star */}
                        <Form.Group className="col-sm-1 custom-partner" controlId="average_vote_star">
                            <Form.Label className="information-partner">
                                Star
                                <span style={{ color: "red" }}>*</span>
                            </Form.Label>
                            <Form.Control
                                type="text"
                                name="average_vote_star"

                                readOnly
                                value={formData.average_vote_star + " " + String.fromCharCode(9733)}
                                className={`input-disabled  ${getColorClass(formData.average_vote_star)}`}
                            />
                        </Form.Group>
                    </div>
                    <div className="col-sm-6">
                        <div className="row">
                            {/* Display date of birth */}
                            <Form.Group className="col-sm-6 mb-3" controlId="dob">
                                <Form.Label>Ngày sinh<span style={{ color: 'red' }}>*</span></Form.Label>
                                <Form.Control
                                    type="date"
                                    name="dob"
                                    required
                                    value={formatDate(formData.dob)}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                            {/* Display phone number */}
                            <Form.Group className="col-sm-6 mb-3" controlId="phone">
                                <Form.Label>Số điện thoại<span style={{ color: 'red' }}>*</span></Form.Label>
                                <Form.Control
                                    type="number"
                                    name="phone"
                                    required
                                    value={formData.phone}
                                    onChange={handleChange}
                                    className='input-disabled'
                                    style={{ border: 'none' }}
                                />
                            </Form.Group>
                        </div>
                        <div className="row">
                            <div className="col-sm-6">
                                <Form.Label>Giới tính<span style={{ color: 'red' }}>*</span></Form.Label><br></br>
                                <div className="form-check form-check-inline">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="sex"
                                        id="male"
                                        value="0"
                                        checked={formData.sex === "0"}
                                        onChange={(e) => setFormData({ ...formData, sex: e.target.value })}
                                    />
                                    <label className="form-check-label" htmlFor="male">
                                        Nam
                                    </label>
                                </div>
                                <div className="form-check form-check-inline">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="sex"
                                        id="female"
                                        value="1"
                                        checked={formData.sex === "1"}
                                        onChange={(e) => setFormData({ ...formData, sex: e.target.value })}
                                    />
                                    <label className="form-check-label" htmlFor="female">
                                        Nữ
                                    </label>
                                </div>
                            </div>
                            <Form.Group className="col-sm-6 mb-3" controlId="id_card_number">
                                <Form.Label>Số CCCD<span style={{ color: 'red' }}>*</span></Form.Label>
                                <Form.Control
                                    type="number"
                                    name="id_card_number"
                                    required
                                    value={formData.id_card_number}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                        </div>
                        {/* Display email */}
                        <Form.Group className="mb-3" controlId="email">
                            <Form.Label>Email<span style={{ color: 'red' }}>*</span></Form.Label>
                            <Form.Control
                                type="email"
                                name="email"
                                required
                                value={formData.email}
                                onChange={handleChange}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="type_partner">
                            <Form.Label>Chức vụ<span style={{ color: 'red' }}>*</span></Form.Label>
                            <Form.Select
                                name="type_partner"
                                value={formData.type_partner}
                                required
                                onChange={handleChange}
                            >
                                <option value="">-- Chọn phân loại --</option>
                                <option value="cleaner" defaultValue={formData.type_partner === "cleaner"}>
                                    Nhân viên vệ sinh
                                </option>
                                <option value="shop_worship" defaultValue={formData.type_partner === "shop_worship"}>
                                    Đối tác bán đồ cúng
                                </option>
                                <option value="repair_man" defaultValue={formData.type_partner === "repair_man"}>
                                    Vệ sinh máy lạnh
                                </option>
                            </Form.Select>
                        </Form.Group>
                        {/* Display current address */}
                        <div className="row">
                            <Form.Group className="col-sm-6 mb-3" controlId="province">
                                <Form.Label>Tỉnh/TP<span style={{ color: 'red' }}>*</span></Form.Label>
                                <Form.Control
                                    as="select"
                                    name="province"
                                    value={formData.province}
                                    onChange={handleProvinceChange}
                                >
                                    <option value="">--Chọn tỉnh/TP--</option>
                                    {list_province && list_province.length > 0 ? (
                                        list_province.map((province) => (
                                            <option key={province.id} value={province.code}>
                                                {province.name.toUpperCase()}
                                            </option>
                                        ))
                                    ) : (
                                        <option value="" disabled>
                                            Loading provinces...
                                        </option>
                                    )}
                                </Form.Control>
                            </Form.Group>
                            <Form.Group className="col-sm-6 mb-3" controlId="district">
                                <Form.Label>Quận/Huyện<span style={{ color: 'red' }}>*</span></Form.Label>
                                <Form.Control
                                    as="select"
                                    name="district"
                                    value={formData.district}
                                    onChange={handlesetDistrictChange}
                                >
                                    <option value="">-- Chọn Quận/Huyện --</option>
                                    {list_district.map((district) => (
                                        <option key={district.id} value={district.code}>
                                            {district.name.toUpperCase()}
                                        </option>
                                    ))}
                                </Form.Control>
                            </Form.Group>
                        </div>
                        <div className="row">
                            <Form.Group className="col-sm-6 mb-3" controlId="ward">
                                <Form.Label>Xã/Phường<span style={{ color: 'red' }}>*</span></Form.Label>
                                <Form.Control
                                    as="select"
                                    name="ward"
                                    value={formData.ward}
                                    onChange={handlesetWardChange}
                                >
                                    <option value="">--Chọn Phường/Xã--</option>
                                    {list_ward.map((ward) => (
                                        <option key={ward.id} value={ward.id}>
                                            {ward.name.toUpperCase()}
                                        </option>
                                    ))}
                                </Form.Control>
                            </Form.Group>
                        </div>
                        <Form.Group className="mb-3" controlId="address">
                            <Form.Label>Địa chỉ hiện tại<span style={{ color: 'red' }}>*</span></Form.Label>
                            <Form.Control
                                type="address"
                                name="address"
                                required
                                value={formData.address}
                                onChange={handleChange}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="is_active">
                            <Form.Label style={{ marginRight: '10px' }}>Trạng thái:</Form.Label>
                            <div style={{ display: 'inline-block', marginRight: '20px' }}>
                                <Form.Switch
                                    name="is_active"
                                    checked={formData.is_active}
                                    onChange={handleToggleChange}
                                />
                            </div>
                            <span
                                style={{
                                    backgroundColor: formData.is_active ? '#00ff62' : '#df2d05',
                                    color: '#ffffff',
                                    borderRadius: '8px',
                                    border: `1px solid ${formData.is_active ? '#00ff62' : '#df2d05'}`,
                                    padding: '5px 10px',
                                    fontSize: '13px',
                                    fontWeight: 'bold',
                                }}
                            >
                                {formData.is_active ? 'Mở tài khoản' : 'Khóa tài khoản'}
                            </span>
                        </Form.Group>
                        {/* Display password*/}
                        <h2 className="sectionTitle">Thông tin mật khẩu</h2>
                        <div className="mb-3 mt-3 position-relative">
                            <label htmlFor="pass">Nhập mật khẩu <span style={{ color: "red" }}>*</span>:</label>
                            <div className="password-input-wrapper">
                                <input
                                    type="password"
                                    className='form-control'
                                    id="password"
                                    name="password"
                                    checked={formData.password}
                                    onChange={handlePasswordChange}
                                />
                                {newpassword.length >= 6 && (
                                    <div className="password-check-icon">
                                        <CheckCircleOutlineIcon />
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="mb-3 mt-3">
                            <label htmlFor="pwd" className="form-label">
                                Xác nhận lại mật khẩu <span style={{ color: "red" }}>*</span>:
                            </label>
                            <div className="password-input-wrapper">
                                <input
                                    type="password"
                                    className='form-control'
                                    id="confirmPassword"
                                    name="confirmPassword"
                                    value={confirmPassword}
                                    onChange={handleConfirmPasswordChange}
                                />
                                {confirmPassword && (
                                    <div className="password-check-icon">
                                        {passwordMatch ? (
                                            <CheckCircleOutlineIcon style={{ color: 'green', fontWeight: 'bold' }} />
                                        ) : (
                                            <ClearRoundedIcon style={{ color: 'red', fontWeight: 'bold' }} />
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                        {/* Display bank information */}
                        <h2 className="sectionTitle">Thông tin ngân hàng</h2>
                        <Form.Group className="mb-3" controlId="bank_name">
                            <Form.Label>Tên Ngân hàng</Form.Label>
                            <Form.Control
                                type="text"
                                name="bank_name"
                                value={formData.bank_name}
                                onChange={handleChange}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="account_bank_number">
                            <Form.Label>Số tài khoản ngân hàng</Form.Label>
                            <Form.Control
                                type="number"
                                name="account_bank_number"
                                value={formData.account_bank_number}
                                onChange={handleChange}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="account_bank_holder_name">
                            <Form.Label>Tên chủ tài khoản</Form.Label>
                            <Form.Control
                                type="text"
                                name="account_bank_holder_name"
                                value={formData.account_bank_holder_name}
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </div>
                    <div className="col-sm-6">
                        {/* Avatar Image */}
                        <Form.Group>
                            <div className="d-flex flex-column align-items-center">
                                <Form.Label>Ảnh chân dung<span style={{ color: 'red' }}>*</span></Form.Label>
                                {formData.avatar_partner && (
                                    <img src={partnerImagePreview || formData.avatar_partner}
                                        alt="ảnh chân dung"
                                        width="200" height="250"
                                        className="img_avata"
                                    />
                                )}
                                <FileInput id="avataInput" text='Chụp ảnh' onChange={handleImageChange} />
                            </div>
                        </Form.Group>
                        {/* ID Card */}
                        <div className="container text-center">
                            <div className="row">
                                {/* Hiển thị ảnh mặt trước CCCD */}
                                <div className="col-sm-6 d-flex justify-content-center id-card-mobile">
                                    <div className="d-flex flex-column align-items-center">
                                        <Form.Group>
                                            <Form.Label>Ảnh mặt trước CCCD<span style={{ color: 'red' }}>*</span></Form.Label>
                                            <div className="image-container">
                                                {formData.id_card_img_front && (
                                                    <img
                                                        src={idCardImgBehind || formData.id_card_img_front}
                                                        alt="Ảnh mặt trước CCCD/CMND"
                                                        className="img-thumbnail"
                                                        width="200" height="250"
                                                    />
                                                )}
                                            </div>
                                            <FileInput id="idCardFrontInput" text='Chọn ảnh'
                                                onChange={handleidCardImgBehindChange} />
                                        </Form.Group>
                                    </div>
                                </div>
                                {/* Hiển thị ảnh mặt sau CCCD */}
                                <div className="col-sm-6 d-flex justify-content-center id-card-mobile">
                                    <div className="d-flex flex-column align-items-center">
                                        <Form.Group>
                                            <Form.Label>Ảnh mặt sau CCCD<span style={{ color: 'red' }}>*</span></Form.Label>
                                            <div className="image-container">
                                                {formData.id_card_img_behind && (
                                                    <img
                                                        src={idCardImgFront || formData.id_card_img_behind}
                                                        alt="Ảnh mặt sau CCCD/CMND"
                                                        className="img-thumbnail"
                                                        width="200" height="250"
                                                    />
                                                )}
                                            </div>
                                            <FileInput
                                                id="idCardBehindInput"
                                                text='Chọn ảnh'
                                                onChange={handleIdCardImgFrontChange}
                                            />
                                        </Form.Group>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-center align-items-center">
                                    <Button
                                        variant="contained"
                                        color="error"
                                        className='btn-statistical custum-button-mui'
                                        style={{ textTransform: 'capitalize', marginTop: '25px', marginRight: '10px' }}
                                        onClick={handleOpenMoneyDeduction}
                                    >
                                        trừ tiền
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="success"
                                        className='btn-statistical custum-button-mui'
                                        style={{ textTransform: 'capitalize', marginTop: '25px', marginLeft: '10px' }}
                                        onClick={handleOpenPublicMoney}
                                    >
                                        Công Tiền
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <div className="d-flex justify-content-center my-4">
                <button className="btn btn-success"
                >
                    Cập nhật
                </button>
            </div>
            {/*Form trừ tiền*/}
            {isOpen && (
                <Modal show={isOpen} onHide={handleCancel}>
                    <Modal.Header closeButton style={{
                        padding: '8px 8px 0 0',
                        textAlign: 'center',
                        border: 'none',
                        outline: 'none',
                        boxShadow: 'none',
                        position: 'relative',
                    }}>
                        <Modal.Title ></Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{
                        padding: '0',
                        textAlign: 'center',
                        border: 'none',
                        outline: 'none',
                        boxShadow: 'none',
                        position: 'relative',
                    }}>
                        <h5 style={{ fontSize: '17px' }}>Form Trừ Tiền</h5>
                        <form method="post" className="py-3 container form_deduction">
                            <div className="col mb-4">
                                <TextField
                                    id="money"
                                    label={
                                        <span>
                                            Nhập số tiền <span className="red-asterisk">*</span>
                                        </span>
                                    }
                                    variant="outlined"
                                    size="small"
                                    style={{ width: '100%' }}
                                    value={deductionAmount}
                                    name="money"
                                    onChange={handleMoneyChange}

                                />
                            </div>
                            <div className="col mt-4">
                                <FormControl fullWidth>
                                    <InputLabel id='reason'>
                                        <span>
                                            Lý do trừ tiền <span className="red-asterisk">*</span>
                                        </span>
                                    </InputLabel>
                                    <Select
                                        labelId='reason'
                                        id='reason'
                                        label={
                                            <span>
                                                Lý do trừ tiền <span className="red-asterisk">*</span>
                                            </span>
                                        }
                                        value={deductionReason}
                                        onChange={handleReasonChange}
                                    >
                                        <MenuItem value='CTV không thao tác bấm bắt đầu, kết thúc công việc theo quy trình'>
                                            CTV không thao tác bấm bắt đầu,<br /> kết thúc công việc theo quy trình
                                        </MenuItem>
                                        <MenuItem value='CTV đi làm không đúng giờ'>
                                            CTV đi làm không đúng giờ
                                        </MenuItem>
                                        <MenuItem value='CTV bị khách hàng đánh giá không tốt'>
                                            CTV bị khách hàng đánh giá không tốt
                                        </MenuItem>
                                        <MenuItem value=' CTV tự ý không đi làm'>
                                            CTV tự ý không đi làm
                                        </MenuItem>
                                        <MenuItem value='CTV tự ý liên hệ khách hàng xin đổi giờ làm'>
                                            CTV tự ý liên hệ khách hàng xin đổi giờ làm
                                        </MenuItem>
                                        <MenuItem value='CTV không mặc đồng phục khi đi làm'>
                                            CTV không mặc đồng phục khi đi làm
                                        </MenuItem>
                                        <MenuItem value='CTV vi phạm quy đinh của Ting'>
                                            CTV vi phạm quy đinh của Ting
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                        </form>
                    </Modal.Body>
                    <Modal.Footer style={{
                        textAlign: 'center',
                        border: 'none',
                        outline: 'none',
                        boxShadow: 'none',
                        position: 'relative',
                        justifyContent: 'center'
                    }}
                    >
                        <button type="button" class="btn btn-danger" onClick={handleCancel}>
                            Hủy
                        </button>
                        <button type="button" class="btn btn-success" onClick={handleDeductionSubmit}>
                            Xác nhận
                        </button>
                    </Modal.Footer>
                </Modal>
            )}
            {/*Form cộng tiền*/}
            {isOpenMoney && (
                <Modal show={isOpenMoney} onHide={handleCancelMoney}>
                    <Modal.Header closeButton style={{
                        padding: '8px 8px 0 0',
                        textAlign: 'center',
                        border: 'none',
                        outline: 'none',
                        boxShadow: 'none',
                        position: 'relative',
                    }}>
                        <Modal.Title ></Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{
                        padding: '0',
                        textAlign: 'center',
                        border: 'none',
                        outline: 'none',
                        boxShadow: 'none',
                        position: 'relative',
                    }}>
                        <h5 style={{ fontSize: '17px' }}>Form Cộng Tiền</h5>
                        <form method="post" className="py-3 container form_deduction">
                            <div className="col mb-4">
                                <TextField
                                    id="public-money"
                                    label={
                                        <span>
                                            Nhập số tiền <span className="red-asterisk">*</span>
                                        </span>
                                    }
                                    variant="outlined"
                                    size="small"
                                    style={{ width: '100%' }}
                                    value={plusAmount}
                                    name="public-money"
                                    onChange={handlePlusAmountChange}
                                />
                            </div>
                            <div className="col mt-4">
                                <FormControl fullWidth>
                                    <InputLabel id='reason'>
                                        <span>
                                            Lý do cộng tiền <span className="red-asterisk">*</span>
                                        </span>
                                    </InputLabel>
                                    <Select
                                        labelId='reason'
                                        id='reason'
                                        label={
                                            <span>
                                                Lý do cộng tiền <span className="red-asterisk">*</span>
                                            </span>
                                        }
                                        value={ReasonAddingMoney}
                                        onChange={handleAddingMoneyChange}
                                    >
                                        <MenuItem value='Cộng tiền hỗ trợ đối tác'>
                                            Cộng tiền hỗ trợ đối tác
                                        </MenuItem>
                                        <MenuItem value='Đối tác nạp tiền vào hệ thống'>
                                            Đối tác nạp tiền vào hệ thống
                                        </MenuItem>
                                        <MenuItem value='Phần thưởng cho đối tác'>
                                            Phần thưởng cho đối tác
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                        </form>
                    </Modal.Body>
                    <Modal.Footer style={{
                        textAlign: 'center',
                        border: 'none',
                        outline: 'none',
                        boxShadow: 'none',
                        position: 'relative',
                        justifyContent: 'center'
                    }}
                    >
                        <button type="button" class="btn btn-danger" onClick={handleCancelMoney}>
                            Hủy
                        </button>
                        <button type="button" class="btn btn-success" onClick={handlePlusMoneySubmit}>
                            Xác nhận
                        </button>
                    </Modal.Footer>
                </Modal>
            )}
        </Form>
    );
}
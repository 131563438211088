import { TextField } from "@mui/material";

export default function CustomTextField({ onChange, value, id, label, name, required, minRows, onKeyDown, className }) {
  return (
    <TextField
      id={id}
      name={name}
      label={label}
      onChange={onChange}
      value={value}
      variant="outlined"
      size="small"
      className={`edit-text-field ${className}`}
      required={required}
      minRows={minRows}
      onKeyDown={onKeyDown}
    />
  )
}
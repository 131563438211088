import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import { Form } from 'react-bootstrap';
import numeral from "numeral";
import { TextField } from '@mui/material';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import RotateLeftOutlinedIcon from '@mui/icons-material/RotateLeftOutlined';
import { getListDashBoar } from '../../services/dashboarding';
import { useLocation, useNavigate, } from 'react-router-dom';
import queryString from 'query-string';
import './home.scss';
import LoadingSpinner from '../../components/Loading/LoadingSpinner';

function HomePage() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const location = useLocation();
  const navigate = useNavigate();

  const fetchListDashBoar = async () => {
    try {
      const params = {
        from_date: fromDate,
        to_date: toDate,
      };
      const response = await getListDashBoar(params);
      const data = response.data.data;
      setData(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  useEffect(() => {
    const params = queryString.parse(location.search);
    setFromDate(params.fromDate || '');
    setToDate(params.toDate || '');
    fetchListDashBoar();
  }, [location.search]);

  const handleSearch = () => {
    const queryParams = { fromDate, toDate };
    const queryStringParams = queryString.stringify(queryParams);
    navigate(`?${queryStringParams}`);
    fetchListDashBoar();
  };

  const handleRefresh = () => {
    setFromDate('');
    setToDate('');
    navigate('');
    fetchListDashBoar();
  };
  return (
    <div className='container-statistical-page m-5'>
      <h3 className='h3 mb-4 text-center'>Dữ liệu thống kê</h3>
      <div className='row mb-2 form-search-date'>
        <div className='col-sm-3 '>
          <Form.Group className="mb-3" controlId="date">
            <TextField
              id="fromDate"
              label="Từ ngày"
              type="date"
              value={fromDate}
              onChange={(e) => setFromDate(e.target.value)}
              size="small"
              className="custom-textfield"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Form.Group>
        </div>
        <div className='col-sm-3 '>
          <Form.Group className="mb-3" controlId="date">
            <TextField
              id="toDate"
              label="Đến ngày"
              type="date"
              value={toDate}
              onChange={(e) => setToDate(e.target.value)}
              size="small"
              className="custom-textfield"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Form.Group>
        </div>
        <div className='col-sm-2'>
          <Button variant="contained" color="success" startIcon={<SearchOutlinedIcon />} className='btn-statistical custum-button-mui' style={{ textTransform: 'math-auto' }} onClick={handleSearch}>
            Tìm Kiếm
          </Button>
        </div>
        <div className='col-sm-2'>
          <Button variant="contained" color='error' startIcon={<RotateLeftOutlinedIcon />} className='btn-statistical custum-button-mui' style={{ textTransform: 'math-auto' }} onClick={handleRefresh}>
            Làm Mới
          </Button>
        </div>
      </div>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <div className='row d-flex align-items-center justify-content-center'>
          {data && Object.keys(data).length > 0 && (
            <div className='row d-flex align-items-center justify-content-center'>
              <div className='col-sm-3 total-revenue statistical d-flex flex-column align-items-center justify-content-center'>
                <p className='text-center title-statistical'>TỔNG DOANH THU</p>
                <h5 className='h5 text-center'>{numeral(data.total_revenue).format("0,0")}<sup>đ</sup></h5>
              </div>
              <div className='col-sm-3 total-revenue total-promotions d-flex flex-column align-items-center justify-content-center'>
                <p className='text-center title-statistical '>TỔNG KHUYẾN MÃI</p>
                <h5 className='h5 text-center'>{numeral(data.total_discount).format("0,0")}<sup>đ</sup></h5>
              </div>
              <div className='col-sm-3 total-revenue user-total-registration d-flex flex-column align-items-center justify-content-center'>
                <p className='text-center title-statistical '>TỔNG USER ĐĂNG KÝ</p>
                <h5 className='h5 text-center justify-content-center align-items-center'>
                  {data.total_user}
                </h5>
              </div>
              <div className='col-sm-3 total-revenue total-work-completed d-flex flex-column align-items-center justify-content-center'>
                <p className='text-center title-statistical '>TỔNG CÔNG VIỆC HOÀN THÀNH</p>
                <h5 className='h5 text-center'> {data.total_task_success}</h5>
              </div>
              <div className='col-sm-3 total-revenue total-ctv-deposits d-flex flex-column align-items-center justify-content-center'>
                <p className='text-center title-statistical '>TỔNG TIỀN CTV NẠP</p>
                <h5 className='h5 text-center'>
                  {numeral(data.total_deposit_money).format("0,0")}
                  <sup>đ</sup>
                </h5>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
export default HomePage;

import { Routes, Route } from 'react-router-dom';
import { AuthenticatedRoute } from './AuthenticatedRoute';

//import layouts
import { DashboardLayout } from '../components/Layouts/dashboard';
import { AuthLayout } from '../components/Layouts/auth';

//import pages
import HomePage from '../pages/Home';
import LoginPage from '../pages/Login';
import NotFound from '../pages/NotFound';
import { ListPartner } from '../pages/Partner/ListPartner';
import { DetailPartner } from '../pages/Partner/DetailPartner';
import { AddPartner } from '../pages/Partner/AddPartner'
import { ListPromotion } from '../pages/Promotion/ListPromotion';
import { AddPromotion } from '../pages/Promotion/Promotion';
import { UpdatePromotion } from '../pages/Promotion/UpdatePromotion';
import { Popup } from '../pages/Popup/Popup';
import { ListPopup } from '../pages/Popup/ListPopup';
import { DetailPopup } from '../pages/Popup/DetailPopup';
import { ListJob } from '../pages/Job/ListJob/Job';
import { DetailJob } from '../pages/Job/DetailJob/DetailJob';
import { ListUser } from '../pages/User/ListUser/ListUser';
import { DetailUser } from '../pages/User/UpdateUser/UpdateUser';
import { ListStar } from '../pages/Star/ListStar/ListStar';
import { DetailStar } from '../pages/Star/DetailStar/DetailStar';
import { ListWorkShips } from '../pages/WrokShip/ListWorkShips/ListWorkShip';
import { AddWrokShips } from '../pages/WrokShip/AddWorShip/AddWorkShip';
import { UpdateWorkShip } from '../pages/WrokShip/UpdateWorkShip/UpdateWorkShip';
import { ListTranSacTion } from '../pages/Transaction/ListTransaction/ListTransaction';
import { DelTaiTranSacTion } from '../pages/Transaction/DelTailTransaction/DeltailTransaction';
import { ListBlog } from '../pages/Blog/ListBlog/ListBlog';
import { AddBlog } from '../pages/Blog/AddBlog/AddBlog';
import { DetailBlog } from '../pages/Blog/DeltailBlog/DetailBlog';
import { ListProdcut } from '../pages/Product/List';
import { ListConditioner } from '../pages/Air-conditioner/ListAir/ListAir';
import { DetailConditioner } from '../pages/Air-conditioner/DeltailAir/DeltailAir';
import { ListSofa } from '../pages/Sofa/ListSofa/ListSofa';
import { DetailSofa } from '../pages/Sofa/DeltailSofa/DeltailSofa';
import { Noti } from '../pages/Notification/Notification';
import { ListCommistion } from '../pages/Commission/ListCommission';
import { DelTaiCommission } from '../pages/Commission/DeltailCommission';
import { CreateProduct } from '../pages/Product/Create/CreateProduct';
import { DeltailProdcut } from '../pages/Product/Deltail';
export function AppRoutes() {
    return (
        <Routes>
            <Route element={<AuthLayout />}>
                <Route path="/login" element={<AuthenticatedRoute><LoginPage /></AuthenticatedRoute>} />
                <Route path="/" element={<DashboardLayout />}>
                    <Route path="/home" element={<HomePage />} />
                    {/*partner*/}
                    <Route path="/partners" element={<ListPartner />} />
                    <Route path="/partner/create_partner" element={<AddPartner />} />
                    <Route path="/partner/:partnerId" element={<DetailPartner />} />
                    {/*promotion*/}
                    <Route path="/promotions" element={<ListPromotion />} />
                    <Route path="/promotion/create_promotion" element={<AddPromotion />} />
                    <Route path="/promotion/:promotionId" element={<UpdatePromotion />} />
                    {/*popup*/}
                    <Route path="/popups" element={<ListPopup />} />
                    <Route path="/popup/:popupId" element={<DetailPopup />} />
                    <Route path="/popup/create_popup" element={<Popup />} />
                    {/*job*/}
                    <Route path="/jobs" element={<ListJob />} />
                    <Route path="/job/:service_code" element={<DetailJob />} />
                    {/*user*/}
                    <Route path="/users" element={<ListUser />} />
                    <Route path="/user/:Id" element={<DetailUser />} />
                    {/*review*/}
                    <Route path="/stars" element={<ListStar />} />
                    <Route path="/star/:Id" element={<DetailStar />} />
                    <Route path="/job/:service_code" element={<DetailJob />} />
                    <Route path="/partner/:partnerId" element={<DetailPartner />} />
                    <Route path="/user/:Id" element={<DetailUser />} />
                    {/*Offerings*/}
                    <Route path="/workships" element={<ListWorkShips />} />
                    <Route path="/workship/create-worship" element={<AddWrokShips />} />
                    <Route path="/workship/:id" element={<UpdateWorkShip />} />
                    {/*Transaction*/}
                    <Route path="/transactions" element={<ListTranSacTion />} />
                    <Route path="/transaction/:id" element={<DelTaiTranSacTion />} />
                    {/*ListCommistion*/}
                    <Route path="/commission" element={<ListCommistion />} />
                    <Route path="/commission/:id" element={<DelTaiCommission />} />
                    {/*Blog*/}
                    <Route path="/blogs" element={<ListBlog />} />
                    <Route path="/blog/create_blog" element={<AddBlog />} />
                    <Route path="/blog/:blog_id" element={<DetailBlog />} />
                    {/*Product*/}
                    <Route path="/list-product" element={<ListProdcut />} />
                    <Route path="/create-product" element={<CreateProduct />} />
                    <Route path="/prodcut/:id" element={<DeltailProdcut />} />
                    {/*Conditioner*/}
                    <Route path="/conditioners" element={<ListConditioner />} />
                    <Route path="/conditioner/:service_code" element={<DetailConditioner />} />
                    {/*safa*/}
                    <Route path="/sofas" element={<ListSofa />} />
                    <Route path="/sofa/:service_code" element={<DetailSofa />} />
                    {/*Notification*/}
                    <Route path="/notification" element={<Noti />} />
                </Route>
            </Route>
            <Route path="/*" element={<NotFound />} />
        </Routes>
    );
}

import { Axios } from "../utils/apiHelper";
//danh sách transaction
export const getListTransacTion = async ({ current_page = 1, limit = 20, status = 3, transaction_id = null, partner_id = null, created_at, transaction_type = 4 }) => {
  const url = `transactions/staff-get-list-transaction?current_page=${current_page}&limit=${limit}&status=${status}&transaction_id=${transaction_id}&created_at=${created_at}&transaction_type=${transaction_type}&partner_id=${partner_id}`;
  return await Axios.get(url);
};
//chi tiết transaction
export const getDetailTransacTion = async (id) => {
  const url = `transactions/staff-get-detail-transaction/${id}`;
  return await Axios.get(url);
};
// nút hủy giao dịch cho nạp tiền
export const cancelTransacTion = async (transaction_id) => {
  const url = `transactions/staff-cancel-deposit-transaction/${transaction_id}`;
  return await Axios.post(url);
};
// nút xác nhận giao dịch cho nạp tiền
export const conFirmTransacTion = async (transaction_id) => {
  const url = `transactions/staff-accept-deposit-transaction/${transaction_id}`;
  return await Axios.post(url);
};
// nút xác nhận giao dịch cho rút tiền
export const conFirmWithdraw = async (transaction_id) => {
  const url = `transactions/staff-accept-withdraw-transaction/${transaction_id}`;
  return await Axios.post(url);
};
// nút hủy giao dịch cho rút tiền
export const cancelWithdraw = async (transaction_id) => {
  const url = `transactions/staff-cancel-withdraw-transaction/${transaction_id}`;
  return await Axios.post(url);
};
import React, { useEffect, useState } from 'react';
import { getListVotes } from '../../../services/review';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { TextField, } from '@mui/material';
import { Form } from 'react-bootstrap';
import Pagination from '../../../components/Pagination/Pagination';
import LoadingSpinner from '../../../components/Loading/LoadingSpinner';
import { FaStar, } from "react-icons/fa";
import './liststar.scss';

export function ListStar() {
  const [votes, setVotes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchPhone, setSearchPhone] = useState('');
  const [totalPage, setTotalPage] = useState(0);
  const [service_code, setServiceCode] = useState("");
  const [partner_id, setPartnerId] = useState("");
  const [created_at, setcreated_at] = useState("");
  const [votePerPage,] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const [ErrorSearch, setErrorSearch] = useState(false);

  const dateNow = new Date();
  const year = dateNow.getFullYear();
  const monthWithOffset = dateNow.getMonth() + 1;
  const month = monthWithOffset.toString().padStart(2, '0');
  const date = dateNow.getDate().toString().padStart(2, '0');
  const materialDateInput = `${year}-${month}-${date}`;

  const handleInputChange = (event) => { setSearchPhone(event.target.value); };
  const handleDateCreatedChange = (event) => {
    const selectedDate = event.target.value;
    const formattedDate = moment(selectedDate).format('YYYY-MM-DD');
    setcreated_at(formattedDate);
  };
  const formatDate = (date) => { return moment(date).format('YYYY-MM-DD'); };
  const handleReviewCodeChange = (event) => {
    let serviceCode = event.target.value;
    setServiceCode(serviceCode);
  }
  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
    window.scrollTo({ top: 0, behavior: 'smooth' });
    fetchData();
  };
  const handleParterIDChange = (event) => {
    let partnerId = event.target.value;
    setPartnerId(partnerId);
  }
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };
  const handleSearch = async () => {
    setCurrentPage(1);
    await fetchData();
  };

  const fetchData = async () => {
    try {
      const params = {
        partner_id: partner_id,
        phone: searchPhone,
        current_page: currentPage,
        limit: votePerPage,
        service_code: service_code,
        created_at: created_at,
      };
      const response = await getListVotes(params);
      const data = response.data.data;
      if (data.length === 0) {
        setErrorSearch(true);
      } else {
        setErrorSearch(false);
      }
      setVotes(data);
      setTotalPage(response.data.total_page);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error('Lỗi khi tìm dữ liệu:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [currentPage]);
  return (
    <div className="container-review-list">
      <div className='container'>
        <h3 className="h3">Danh sách đánh giá</h3>
        <div className='container-job-filter text-center' style={{ marginTop: '40px' }}>
          <div className="row">
            <div class="col-sm-2">
              <TextField
                type='number'
                id="phone"
                label="Số điện thoại KH"
                variant="outlined"
                size="small"
                value={searchPhone}
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
                className='custum-filter-star'
              />
            </div>
            <div class="col-sm-2">
              <TextField
                id="phone"
                label="Mã nhân viên"
                variant="outlined"
                size="small"
                value={partner_id}
                onChange={handleParterIDChange}
                onKeyDown={handleKeyDown}
                className='custum-filter-star'
              />
            </div>
            <div class="col-sm-3 col-sm-2">
              <TextField
                id="phone"
                label="Mã công việc"
                variant="outlined"
                size="small"
                value={service_code}
                onChange={handleReviewCodeChange}
                onKeyDown={handleKeyDown}
                className='custum-filter-star custom-textfield'
              />
            </div>
            <div class="col-sm-3 custum-create-at">
              <Form.Group className="" controlId="date-created">
                <TextField
                  id="fromDate"
                  name="created_at"
                  label="Ngày tạo"
                  type="date"
                  size="small"
                  inputProps={{ dateFormat: "yyyy-MM-dd" }}
                  className="custom-textfield custum-filter-star custum-create-at"
                  defaultValue={materialDateInput}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={created_at}
                  onChange={handleDateCreatedChange}
                  onKeyDown={handleKeyDown}
                />
              </Form.Group>
            </div>
            <div className='col-sm-2'>
              <button type="button"
                class="btn btn-outline-success pe-auto custum-filter-star"
                onClick={handleSearch}
              >
                Tìm kiếm
              </button>
            </div>
          </div>
        </div>
        <div>
          {loading ? (
            <LoadingSpinner />
          ) : (
            ErrorSearch ? (
              <h3 className="text-center h3 search-results" style={{ marginTop: '50px' }}>Không tìm thấy đánh giá</h3>
            ) : (
              <div className='cart-table table-responsive'>
                <table className="table table-prodcut table-sm table-hover">
                  <thead>
                    <tr className='text-center'>
                      <th> ID</th>
                      <th>STAR</th>
                      <th >Nội dung đánh giá của khách hàng</th>
                      <th>Khách hàng</th>
                      <th>Nhân viên</th>
                      <th>Mã công việc</th>
                      <th >Ngày đánh giá</th>
                    </tr>
                  </thead>
                  <tbody>
                    {votes.map((vote, index) => (
                      <tr key={index} className='text-center'>
                        <td>
                          <Link to={`/star/${vote.id}`}>{vote.id}</Link>
                        </td>
                        <td>
                          <button
                            className={`btn-vote-star ${vote.vote_star === 5 ? 'green' : vote.vote_star === 4 ? 'yellow' : 'red'}`}
                          >
                            {vote.vote_star} <FaStar />
                          </button>
                        </td>
                        <td >{vote.comment}</td>
                        <td>
                          <Link to={`/user/${vote.UserId}`} className='container-review-vote'>
                            {vote.User.fullname}
                            <br />
                            ({vote.User.phone})
                          </Link>
                        </td>
                        <td>
                          <Link to={`/partner/${vote.partner_id}`} className='container-review-vote'>{vote.Partner.fullname}({vote.Partner.id})</Link>
                        </td>
                        <td>
                          <Link to={`/job/${vote.service_code}`} className='container-review-vote'> {vote.service_code}</Link>
                        </td>
                        <td >{formatDate(vote.created_at)}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )
          )}
        </div>
        {
          totalPage > 1 && (
            <Pagination totalPage={totalPage} currentPage={currentPage} handleClick={handlePageClick} />
          )
        }
      </div>
    </div>
  )
}

import React, { useEffect, useState } from 'react';
import { getVotesUserDetail } from '../../../services/review';
import { useParams } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import { Form } from 'react-bootstrap';
import LoadingSpinner from '../../../components/Loading/LoadingSpinner'
import './DetailStar.scss';

export function DetailStar() {
  const { Id } = useParams();
  const [voteData, setVoteData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [zoomedIn, setZoomedIn] = useState(false);
  const [zoomedImageIndex, setZoomedImageIndex] = useState(-1);

  const toggleZoom = (index) => {
    setZoomedIn(!zoomedIn);
    setZoomedImageIndex(index);
  };
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const userResponse = await getVotesUserDetail(Id);
      setVoteData(userResponse.data.data);
      setLoading(false);
    } catch (error) {
      console.error("Lỗi không lấy được dữ liệu đánh giá của khách hàng:", error);
      setLoading(false);
    }
  };
  return (
    <div className="container-detail-review m-3">
      <h3 className="h3 text-center">Chi tiết đánh giá</h3>
      {loading ? (
        <LoadingSpinner />
      ) : voteData ? (
        <div className='row'>
          <div className='col-sm-6'>
            <Form.Group className="mb-3" controlId="UserId">
              <TextField
                id="UserId"
                label="ID_Vote"
                variant="outlined"
                className="edit-textfield custom-text-field"
                value={voteData.UserId}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="service_code">
              <TextField
                id="service_code"
                label="Mã công việc"
                variant="outlined"

                value={voteData.service_code}
                className="edit-textfield custom-text-field"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="fullname">
              <TextField
                id="fullname"
                label="Tên khách hàng"
                variant="outlined"
                value={`${voteData.User.fullname} (${voteData.User.phone})`}
                className="edit-textfield custom-text-field"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="email">
              <TextField
                id="email"
                label="Email khách hàng"
                variant="outlined"
                value={voteData.User.email}
                className="edit-textfield custom-text-field"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="fullname">
              <TextField
                id="fullname"
                label="Tên nhân viên"
                variant="outlined"
                value={`${voteData.Partner.fullname} (${voteData.Partner.id})`}
                className="edit-textfield custom-text-field"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="phone">
              <TextField
                id="phone"
                label="Số điện thoại nhân viên"
                variant="outlined"
                value={voteData.Partner.phone}
                className="edit-textfield custom-text-field"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="vote_star">
              <TextField
                id="vote_star"
                label="Vote_Star"
                variant="outlined"
                value={voteData.vote_star + " " + String.fromCharCode(9733)}
                className="edit-textfield custom-text-field"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="comment">
              <TextField
                id="comment"
                label="Nội dung đánh giá "
                variant="outlined"
                fullWidth
                multiline
                minRows={5}
                value={voteData.comment}
                className="edit-textfield custom-text-field"
              />
            </Form.Group>
          </div>
          <div className="col-md-6 justify-content-center align-items-center">
            <Form.Group className={`mb-3 align-items-center`} controlId="image">
              {voteData.images && (
                <div className="image-gallery">
                  {voteData.images.map((image, index) => (
                    <img
                      key={index}
                      src={image}
                      alt={`Ảnh vote ${index + 1}`}
                      className={`gallery-image ${zoomedIn && index === zoomedImageIndex ? 'zoomed-image' : ''}`}
                      style={{ maxWidth: '350px', height: '400px', cursor: 'pointer', marginRight: '20px' }}
                      onClick={() => toggleZoom(index)}
                    />
                  ))}
                </div>
              )}
            </Form.Group>
          </div>
        </div>
      ) : (
        <div className="container-loading">
          <span className=""></span>
          <div className="line">
            <div className="inner"></div>
          </div>
        </div>
      )}
    </div>
  )
}

import React, { useState, useEffect } from "react";
import { Form } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { getUserDetail, updateUser } from '../../../services/user';
import LoadingSpinner from '../../../components/Loading/LoadingSpinner'
import moment from 'moment';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import './updateuser.scss';

export function DetailUser() {
  const { Id } = useParams();
  const [userData, setUserData] = useState({});
  const [loading, setLoading] = useState(true);
  const [lockSuccessMessage, setLockSuccessMessage] = useState('');
  const [unlockSuccessMessage, setUnlockSuccessMessage] = useState('');
  const [isUpdating, setIsUpdating] = useState(false);
  const formatDate = (date, isBirthDate = false) => {
    if (!date) return '';

    if (isBirthDate) {
      return moment(date).format('DD-MM-YYYY');
    } else {
      return moment(date).format('HH:mm:ss DD-MM-YYYY');
    }
  };
  const handleToggleChange = async (e) => {
    try {
      setIsUpdating(true);
      setUserData({ ...userData, is_active: e.target.checked });
      const locked = !e.target.checked;
      await updateUser(Id, { is_active: !locked });
      if (locked) {
        setLockSuccessMessage('Tài khoản khách hàng đã bị khóa!');
        setUnlockSuccessMessage('');
        window.scrollTo(0, 0);
      } else {
        setUnlockSuccessMessage('Tài khoản của khách hàng đã được mở lại!');
        setLockSuccessMessage('');
        window.scrollTo(0, 0);
      }
      setTimeout(() => {
        setLockSuccessMessage('');
        setUnlockSuccessMessage('');
      }, 1000);
    } catch (error) {
      window.scrollTo(0, 0);
      console.error('cập nhật trạng thái thất bại:', error);
      setLockSuccessMessage('');
      setUnlockSuccessMessage('');
    } finally {
      setIsUpdating(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  const fetchData = async () => {
    try {
      const userResponse = await getUserDetail(Id);
      setUserData(userResponse.data.data);
      setLoading(false);
    } catch (error) {
      console.error("Lỗi khi tải dữ liệu của khách hàng:", error);
      setLoading(false);
    }
  };
  let address = userData.Locations && userData.Locations.find(location => location.is_default);
  return (
    <div className="container-detail-user">
      {lockSuccessMessage && (<Alert variant="filled" severity="error" onClose={() => setLockSuccessMessage('')} dismissible className="custum-alert">
        {lockSuccessMessage}
      </Alert>
      )}
      {unlockSuccessMessage && (<Alert variant="filled" severity="success" onClose={() => setUnlockSuccessMessage('')} dismissible className="custum-alert">
        {unlockSuccessMessage}
      </Alert>
      )}
      {loading ? (
        <LoadingSpinner />
      ) : userData ? (
        <div className='container'>
          <div className="row">
            {/*hiển thị ID khách hàng*/}
            <div className='container-user-ting'>
              <h3 className="h3 text-center mt-4 mb-4"> Chi tiết khách hàng</h3>
              <div className="row">
                <p className="col custum-detail-user">ID khách hàng:</p>
                <p className="col">{userData.id}</p>
              </div>
              <div className="row">
                <p className="col custum-detail-user">Tên khách hàng:</p>
                <p className="col">
                  <td> {userData.fullname}</td>
                </p>
              </div>
              <div className="row">
                <p className="col custum-detail-user">Điểm tích lũy:</p>
                <p className="col">{userData.point} điểm</p>
              </div>
              <div className="row">
                <p className="col custum-detail-user">Số điện thoại:</p>
                <p className="col">{userData.phone}</p>
              </div>
              <div className="row">
                <p className="col custum-detail-user">Email:</p>
                <p className="col">{userData.email}</p>
              </div>
              <div className="row">
                <p className="col custum-detail-user">Địa chỉ:</p>
                <p className="col">{
                  address && address.formatted_address
                    ? address.other_street + address.formatted_address
                    : address?.other_street
                      ? address.other_street + ", " + address?.street + ", " + address?.ward + ", " + address?.district + ", " + address?.province + ", " + address?.country
                      : ""
                }
                </p>
              </div>
              <div className="row">
                <p className="col custum-detail-user">Ngày sinh:</p>
                <p className="col">{formatDate(userData.dob, true)}</p>
              </div>
              <div className="row">
                <p className="col custum-detail-user">Ngày tạo tài khoản:</p>
                <p className="col">{formatDate(userData.created_at)}</p>
              </div>
              <Form.Group className="mb-2" controlId="is_active">
                <Form.Label style={{ marginRight: '10px', fontWeight: 'bold' }} className="custum-detail-user">Trạng thái:</Form.Label>
                <div style={{ display: 'inline-block', marginRight: '20px' }}>
                  <Form.Switch
                    name="is_active"
                    checked={userData.is_active}
                    onChange={handleToggleChange}
                    disabled={isUpdating}
                  />
                </div>
                <span
                  style={{
                    backgroundColor: userData.is_active ? '#00ff62' : '#df2d05',
                    color: '#f9f8f8',
                    borderRadius: '8px',
                    border: `1px solid ${userData.is_active ? '#00ff62' : '#df2d05'}`,
                    padding: '5px 10px',
                    fontSize: '13px',
                    fontWeight: 'bold',
                  }}
                >
                  {isUpdating ? 'Đang cập nhật...' : userData.is_active ? 'Mở tài khoản' : 'Khóa tài khoản'}
                </span>
              </Form.Group>
            </div>
          </div>
        </div>
      ) : (
        <LoadingSpinner />
      )}
    </div>
  );
}
import React, { useEffect, useState, useRef } from 'react';
import { getWorShip, uploadStatusWorships } from '../../../services/worship';
import { Link } from 'react-router-dom';
import LoadingSpinner from '../../../components/Loading/LoadingSpinner'
import AddButon from '../../../components/Button/AddButton';
import './listwrokship.scss';
import TabStore from '../components/TabStore';
import TabWorkShip from '../components/TabWorkShip';
import TitleText from '../../../components/Title/Title';
import TabMenu from '../components/TabMenu';
export function ListWorkShips() {
  const additionalIconsRef = useRef(null);
  const [workships, setWorkships] = useState([]);
  const [activeTab, setActiveTab] = useState('tag-offerings');
  const [showAdditionalIcons, setShowAdditionalIcons] = useState(false);
  const [loading, setLoading] = useState(true);
  const handleClickOutside = (event) => {
    if (additionalIconsRef.current && !additionalIconsRef.current.contains(event.target)) {
      setShowAdditionalIcons(null);
    }
  };
  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };

  const handleEllipsisClick = (index) => {
    setShowAdditionalIcons((prevState) => (prevState === index ? null : index));
  };

  const getStatusWorkShip = (isActive) => {
    return isActive ? 'btn-active' : 'btn-inactive';
  };
  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);
  useEffect(() => {
    fetchWorShip();
  }, []);

  const fetchWorShip = async () => {
    try {
      const response = await getWorShip();
      const workShipData = response.data.data;
      setWorkships(workShipData);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error('Lỗi khi tìm nạp dữ liệu thờ cúng:', error);
    }
  };

  const updateOfferingStatus = async (index) => {
    const updatedOfferings = [...workships];
    const updatedOffering = updatedOfferings[index];
    updatedOffering.is_active = !updatedOffering.is_active;
    try {
      await uploadStatusWorships(updatedOffering.id, updatedOffering.is_active);
      setWorkships(updatedOfferings);
    } catch (error) {
      console.error('Lỗi khi cập nhật trạng thái:', error);
    }
  };
  return (
    <div className="container-tag-tffering">
      <div className='container'>
        <div className='row'>
          <TabMenu activeTab={activeTab} handleTabClick={handleTabClick} />
        </div>
        <div className="tab-content">
          <div id="tag-offerings" className={`container tab-pane ${activeTab === 'tag-offerings' ? 'active' : ''}`}>
            <div>
              <div className='btn-new-Offering' style={{ marginBottom: '30px' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <TitleText
                    text='Danh mục đồ cúng'
                  />
                  <Link to="/workship/create-worship" >
                    <AddButon text='Thêm mới' />
                  </Link>
                </div>
              </div>

              {loading ? (
                <LoadingSpinner />
              ) : (
                <TabWorkShip
                  workships={workships}
                  getStatusWorkShip={getStatusWorkShip}
                  showAdditionalIcons={showAdditionalIcons}
                  handleEllipsisClick={handleEllipsisClick}
                  setShowAdditionalIcons={setShowAdditionalIcons}
                  updateOfferingStatus={updateOfferingStatus}
                />
              )}
            </div>
          </div>
          <div id="store-listings" className={`container tab-pane ${activeTab === 'store-listings' ? 'active' : ''}`}>
            <TitleText
              text='Danh mục cửa hàng'
            />
            <TabStore />
          </div>
          {/*<div id="tag-category" className={`container tab-pane ${activeTab === 'tag-category' ? 'active' : ''}`}>
            <TitleText
              text='Danh mục sản phẩm'
            />
              </div>*/}
        </div>
      </div>
    </div>
  );
}

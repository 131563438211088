import { Button, TextField } from '@mui/material';
import { useState } from 'react';
import { SendNotification } from '../../services/Notification';
import { Modal } from 'react-bootstrap';
import Alert from '@mui/material/Alert';
import './notification.scss';

export function Noti() {
  const [title, setTitle] = useState('');
  const [body, setDescription] = useState('');
  const [successAlert, setSuccessAlert] = useState(false);
  const [errorAlert, setErrorAlert] = useState(false);
  const [isConfirmingCancel, setIsConfirmingCancel] = useState(false);
  const handleCancelClick = () => {
    setIsConfirmingCancel(true);
  };
  const handleCancel = () => {
    setIsConfirmingCancel(false);
  };
  const closeAlerts = () => {
    setTimeout(() => {
      setErrorAlert('');
      setSuccessAlert('');
    }, 2000);
  };
  const handleConfirm = () => {
    SendNotification(title, body)
      .then((response) => {
        setIsConfirmingCancel(false);
        setTitle('');
        setDescription('');
        setSuccessAlert(true);
        closeAlerts();
      })
      .catch((error) => {
        setIsConfirmingCancel(false);
        setErrorAlert(true);
        closeAlerts();
      });
  };

  return (
    <div className='notification_container'>
      {successAlert && (
        <Alert variant="filled" severity="success" className="custum-alert">
          Thông báo đã được gửi thành công!
        </Alert>
      )}
      {errorAlert && (
        <Alert variant="filled" severity="error" className="custum-alert">
          Lỗi gửi thông báo!
        </Alert>
      )}
      <div className='container custum_container'>
        <form style={{ padding: '25px 10px ' }} >
          <h3 className='h3'>Form gửi thông báo</h3>
          <div className="col">
            <TextField
              id=""
              label={
                <span>
                  Tiêu đề <span className="red-asterisk">*</span>
                </span>
              }
              variant="outlined"
              size="small"
              style={{ width: '100%' }}
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </div>
          <div className="col mt-4 mb-4">
            <TextField
              id="body"
              name="body"
              label={
                <span>
                  Nội dung <span className="red-asterisk">*</span>
                </span>
              }
              variant="outlined"
              fullWidth
              multiline
              minRows={5}
              value={body}
              onChange={(e) => setDescription(e.target.value)}
            />
          </div>
          <Button variant="contained" color="warning"
            className='btn-statistical send-require'
            style={{ textTransform: 'math-auto' }}
            onClick={handleCancelClick}
          >
            Gửi thông báo
          </Button>
        </form>
      </div>
      {isConfirmingCancel && (
        <Modal show={isConfirmingCancel} onHide={handleCancel} style={{ top: '30%' }}>
          <Modal.Header closeButton style={{
            padding: '8px 8px 0 0',
            textAlign: 'center',
            border: 'none',
            outline: 'none',
            boxShadow: 'none',
            position: 'relative',
          }}>
            <Modal.Title ></Modal.Title>
          </Modal.Header>
          <Modal.Body style={{
            padding: '0',
            textAlign: 'center',
            border: 'none',
            outline: 'none',
            boxShadow: 'none',
            position: 'relative',
          }}>
            <h5 style={{ fontSize: '17px' }}>Bạn muốn gửi thông báo này?</h5>
          </Modal.Body>
          <Modal.Footer style={{
            textAlign: 'center',
            border: 'none',
            outline: 'none',
            boxShadow: 'none',
            position: 'relative',
            justifyContent: 'center'
          }}>
            <button type="button" class="btn btn-danger" onClick={handleCancel}>
              Hủy
            </button>
            <button type="button" class="btn btn-success" onClick={handleConfirm}>
              Xác nhận
            </button>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  )
}
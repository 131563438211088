import { Axios } from "../utils/apiHelper";
//danh sách transaction
export const GetCommission = async ({ current_page = 1, limit = 20, status = 3, transaction_id = null, user_id = null, created_at, transaction_type = 4 }) => {
  const url = `transactions/staff-get-list-user-transaction?current_page=${current_page}&limit=${limit}&status=${status}&transaction_id=${transaction_id}&created_at=${created_at}&transaction_type=${transaction_type}&user_id=${user_id}`;
  return await Axios.get(url);
};
// chi tiet
export const getDetailCommission = async (id) => {
  const url = `transactions/staff-get-detail-user-transaction/${id}`;
  return await Axios.get(url);
};
// nút xác nhận giao dịch cho rút tiền
export const commissionConFirmWithdraw = async (transaction_id) => {
  const url = `transactions/staff-accept-withdraw-user-transaction/${transaction_id}`;
  return await Axios.post(url);
};
// nút hủy giao dịch cho rút tiền
export const commissionCancelWithdraw = async (transaction_id) => {
  const url = `transactions/staff-cancel-withdraw-user-transaction/${transaction_id}`;
  return await Axios.post(url);
};
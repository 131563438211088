import axios from 'axios';
import qs from 'qs';
import url from 'url';
import { get } from 'lodash';
import { PARTNER } from '../utils/constants';

export class Axios {
    constructor() {
        const auth = JSON.parse(localStorage.getItem(PARTNER));
        const accessToken = get(auth, 'access_token');
        this.instance = axios.create({
            baseURL: process.env.REACT_APP_API_HOST,
            timeout: 50000,
            headers: {
                'Authorization': "Bearer " + accessToken
            }
        });
    }
    static async get(endpoint, query) {
        const object = new this();
        if (query) {
            let parts = url.parse(endpoint, true);
            parts.query = { ...parts.query, ...query };
            parts.search = qs.stringify(parts.query);
            endpoint = url.format(parts);
        }
        return await object.instance.get(endpoint);
    }

    static async post(endpoint, data) {
        const object = new this();
        return await object.instance.post(endpoint, data);
    }

    static async put(endpoint, data) {
        const object = new this();
        return await object.instance.put(endpoint, data);
    }

    static async delete(endpoint, data) {
        const object = new this();
        return await object.instance.put(endpoint, data);
    }

    static async uploadFile(endpoint, formData) {
        const auth = JSON.parse(localStorage.getItem(PARTNER));
        const accessToken = get(auth, 'access_token');
        const instance = axios.create({
            baseURL: process.env.REACT_APP_API_HOST,
            timeout: 100000,
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Authorization': "Bearer " + accessToken,
                'Content-Type': 'multipart/form-data',
            }
        });
        return await instance(endpoint, {
            method: 'POST',
            data: formData,
        });
    }
}
import React, { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import {
  InputLabel,
  MenuItem,
  Select,
  FormControl,
  TextField,
  IconButton,
  InputAdornment,
  Alert,
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { getWorShip } from '../../../services/worship';
import { FaCheck, FaTimes } from "react-icons/fa";
import FileInput from '../../../components/FileInput/FileInput';
import { createNewProdcuts } from '../../../services/product';
import { uploadStaffFile } from '../../../services/promotion';
import Button from '@mui/material/Button';
import No from '../../../assets/images/No.jpg'
import './createproduct.scss'
import CustomTextField from '../../../components/TextField/CusTumTextField';
export function CreateProduct() {
  const [product_name, setProductName] = useState("");
  const [price, setPrice] = useState("");
  const [price_sale, setPriceSale] = useState("");
  const [unit, setUnit] = useState("");
  const [weight, setWeight] = useState("");
  const [stock, setStock] = useState("");
  const [brand, setBrand] = useState("");
  const [description, setDescription] = useState("");
  const [category_id, setCategoryId] = useState('');
  const [clearSelect, setClearSelect] = useState(false);
  const [is_active, setIsActive] = useState(true);
  const [product_image, setProdcutImage] = useState(null);
  const [selectedMenuItem, setSelectedMenuItem] = useState(null);
  const [hasSelectedItem, setHasSelectedItem] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [error, setError] = useState("");
  const [create, setCreate] = useState(false);
  const [workships, setWorkships] = useState([]);
  const handleClearSelect = () => {
    setCategoryId('');
    setSelectedMenuItem(null);
    setClearSelect(false);
  };

  const handleProductName = (event) => setProductName(event.target.value);
  const handlePrice = (event) => setPrice(event.target.value);
  const handlePriceSale = (event) => setPriceSale(event.target.value);
  const handleUnit = (event) => setUnit(event.target.value);
  const handleWeight = (event) => setWeight(event.target.value);
  const handleStock = (event) => setStock(event.target.value);
  const handleBrand = (event) => setBrand(event.target.value);
  const handleDescription = (event) => setDescription(event.target.value);
  const handleIsActive = (event) => { setIsActive(event.target.checked); };
  const handleProductChange = (event) => {
    const selectedValue = event.target.value;
    setCategoryId(selectedValue);
    setSelectedMenuItem(selectedValue);
    setHasSelectedItem(true);
  };
  const handleSubmit = async () => {
    try {
      if (
        !product_name ||
        !price ||
        !price_sale ||
        !unit ||
        !weight ||
        !stock ||
        !brand ||
        !product_image ||
        !category_id
      ) {
        setError('Vui lòng điền đầy đủ thông tin bắt buộc!');
        window.scrollTo(0, 0);
        closeAlerts();
        return;
      }
      setCreate(true);
      const data = {
        product_name,
        price,
        price_sale,
        unit,
        weight,
        stock,
        brand,
        description,
        category_id,
        is_active,
        product_image,
      };
      await createNewProdcuts(data);
      setProductName("");
      setPrice("");
      setPriceSale("");
      setUnit("");
      setWeight("");
      setStock("");
      setBrand("");
      setDescription("");
      setCategoryId("");
      setIsActive(true);
      setProdcutImage(null);
      setSelectedMenuItem(null);
      setHasSelectedItem(false);
      setSuccessMessage('Tạo mới thành công!');
      window.scrollTo(0, 0);
      closeAlerts();
    } catch (error) {
      setError('Tạo mới thất bại!');
      window.scrollTo(0, 0);
      closeAlerts();
    } finally {
      setCreate(false);
    }
  };
  const fetchWorShip = async () => {
    try {
      const response = await getWorShip();
      const workShipData = response.data.data;
      setWorkships(workShipData);
    } catch (error) {
    }
  };
  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      let fData = new FormData();
      fData.append("image", file);
      const resultUpload = await uploadStaffFile(fData);
      if (resultUpload.status === 200) {
        const url = resultUpload.data.url;
        setProdcutImage(url);
      }
    }
  };
  const closeAlerts = () => {
    setTimeout(() => {
      setSuccessMessage('');
      setError(null);
    }, 1500);
  };
  useEffect(() => {
    fetchWorShip();
  }, []);
  return (
    <div className="container-new-product m-3">
      {successMessage && (
        <Alert
          variant="filled" severity="success"
          onClose={() => setSuccessMessage('')}
          className="custum-alert"
        >
          {successMessage}
        </Alert>
      )}
      {error && (
        <Alert variant="filled" severity="error"
          onClose={() => setError('')}
          className="custum-alert"
        >
          {error}
        </Alert>
      )}
      <div className="row justify-content-center">
        <div className='container container-blog-new-product'>
          <h3 className='h3 text-center mt-3'>Tạo sản phẩm mới</h3>
          <div className='row'>
            <div className="col-sm-7">
              {/*danh mục*/}
              <div className="col mt-4">
                <FormControl fullWidth>
                  <InputLabel id='category_id'
                    required>
                    Danh mục sản phẩm
                  </InputLabel>
                  <Select
                    labelId='category_id'
                    id='category_id'
                    name='category_id'
                    label='Danh mục sản phẩm'
                    size='small'
                    value={category_id}
                    onChange={handleProductChange}
                    className={`custum-rpsive-job selectContainer ${selectedMenuItem !== null ? 'hide-dropdown-icon' : ''}`}
                    endAdornment={
                      category_id && (
                        <InputAdornment position='end'>
                          <IconButton
                            aria-label='Clear Selection'
                            edge='end'
                            onClick={handleClearSelect}
                          >
                            <ClearIcon className='icon-clear-product' />
                          </IconButton>
                        </InputAdornment>
                      )
                    }
                    inputProps={{
                      onFocus: () => setClearSelect(!!category_id),
                      onBlur: () => setClearSelect(false),
                    }}
                  >
                    {workships.map((element) => (
                      <MenuItem key={element.id} value={element.id} style={{ textAlign: 'center' }}>
                        <img
                          src={element.icon}
                          alt={element.name}
                          className="worship-icon"
                          width={30}
                          height={30}
                          style={{ marginRight: '10px' }}
                        />
                        {element.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              {/*tên sản phẩm*/}
              <CustomTextField
                id="product_name"
                name='product_name'
                label='Tên sản phẩm'
                onChange={handleProductName}
                value={product_name}
                required
              />
              <div className='row'>
                {/*giá gốc*/}
                <div className="col">

                  <CustomTextField
                    type='number'
                    id="price"
                    name='price'
                    label='Giá gốc'
                    onChange={handlePrice}
                    value={price}
                    required
                  />
                </div>
                {/*giá đã giảm*/}
                <div className="col">
                  <CustomTextField
                    type='number'
                    id="price_sale"
                    name='price_sale'
                    label='Giá đã giảm'
                    onChange={handlePriceSale}
                    value={price_sale}
                    required
                  />
                </div>
              </div>
              {/*đơn vị*/}
              <div className='row'>
                <div className="col mt-4">
                  <FormControl fullWidth>
                    <InputLabel id='unit'
                      required
                    >
                      Đơn vị

                    </InputLabel>
                    <Select
                      labelId='unit'
                      id='unit'
                      label='Đơn vị'
                      onChange={handleUnit}
                      value={unit}
                      size='small'
                      className='custum-rpsive-job'
                    >
                      <MenuItem value='Gam(g)' style={{ textAlign: 'left' }}> Gam(g)</MenuItem>
                      <MenuItem value='Ky-lô-gam(kg)' style={{ textAlign: 'left' }}> Ky-lô-gam(kg)</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <div className="col">
                  <CustomTextField
                    id="weight"
                    name='weight'
                    label='Khối lượng'
                    onChange={handleWeight}
                    value={weight}
                    required
                  />
                </div>
              </div>
              {/*tồn*/}
              <div className='row'>
                <div className="col">
                  <CustomTextField
                    type='number'
                    id="stock"
                    name='stock'
                    label='Tồn'
                    onChange={handleStock}
                    value={stock}
                    required
                  />
                </div>
                <div className="col">
                  <CustomTextField
                    id="brand"
                    name='brand'
                    label='Thương hiệu'
                    onChange={handleBrand}
                    value={brand}
                    required
                  />
                </div>
              </div>
              {/*thương hiệu*/}

              {/*mô tả*/}
              <div className="col mt-4 mb-4">
                <TextField
                  id="description"
                  name="description"
                  label=' Mô tả'
                  onChange={handleDescription}
                  value={description}
                  variant="outlined"
                  fullWidth
                  multiline
                  minRows={5}
                  className="custom-textfield"
                />
              </div>
              <Form.Group className="mb-2" controlId="is_active">
                <Form.Label style={{ marginRight: '10px' }}>Trạng thái:</Form.Label>
                <div style={{ display: 'inline-block', marginRight: '20px' }}>
                  <Form.Switch
                    name="is_active"
                    checked={is_active}
                    onChange={handleIsActive}
                  />
                </div>
                <span
                  style={{
                    backgroundColor: is_active ? '#00ff62' : '#df2d05',
                    color: '#ffffff',
                    borderRadius: '8px',
                    border: `1px solid ${is_active ? '#00ff62' : '#df2d05'}`,
                    padding: '5px 10px',
                    fontSize: '13px',
                    fontWeight: 'bold',
                  }}
                >
                  {is_active ? 'Đang hoạt động' : 'Đã khóa'}
                </span>
              </Form.Group>
            </div>
            <div className='col-sm-5 blog-img-product'>
              <Form.Group className="mb-3" controlId="product_image">
                <div className="image-container">
                  <img
                    src={product_image || No}
                    alt=""
                    className="d-block object-fit-contain"
                    width='100%'
                    height='400px'
                  />
                </div>
                <FileInput id="image" text='Chọn ảnh' onChange={handleImageChange} />
              </Form.Group>
            </div>
            <div className='btn-new-product d-flex justify-content-center'>
              <div className='btn-container'>
                <Button type="button" variant="contained" color="error" startIcon={<FaTimes />} style={{ marginRight: '10px', textTransform: 'math-auto' }}>
                  Hủy
                </Button>
                <Button
                  type="button"
                  variant="contained"
                  color="success"
                  startIcon={<FaCheck />}
                  onClick={handleSubmit}
                  style={{ marginLeft: '10px', textTransform: 'math-auto' }}
                >
                  {create ? "Đang tạo sản phẩm mới..." : "Tạo mới"}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
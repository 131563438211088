import companyLogo from '../../assets/images/undraw_file_sync_ot38.svg';
import { LoginForm } from './components';
import './login.scss';

function LoginPage() {
  return (
    <div className="container-login-form container">
      <div className="row">
        <div className='img-fluid col-sm-6'>
          <div className='img-loginpage'>
            <img src={companyLogo} alt='' width={500} height={368} />
          </div>
        </div>
        <div className="col-sm-6">
          <LoginForm />
        </div>
      </div>
    </div>
  );
}
export default LoginPage;

import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import FileInput from '../../components/FileInput/FileInput';
import Alert from '@mui/material/Alert';
import { TextField } from '@mui/material';
import { uploadPopupImage } from '../../services/popup';
import './style/Popup.scss';

export function Popup() {
  const [newImageFile, setNewImageFile] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [isShow, setIsShow] = useState(false);
  const [editedData, setEditedData] = useState('');

  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');
  const resetForm = () => {
    setImagePreview(null);
    setNewImageFile(null);
    setEditedData('');
    setIsShow(false);
  };
  const closeAlerts = () => {
    setTimeout(() => {
      setSuccessMessage('');
      setError(null);
    }, 2000);
  };
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setNewImageFile(file);

    const reader = new FileReader();
    reader.onloadend = () => {
      setImagePreview(reader.result);
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleIsShowChange = (e) => {
    setIsShow(e.target.checked);
  };

  const handleDataChange = (e) => {
    setEditedData(e.target.value);
  };

  const handleSave = async () => {
    try {
      const formData = new FormData();
      if (newImageFile) {
        formData.append('image', newImageFile);
      }
      formData.append('is_show', isShow);
      formData.append('data', editedData);
      const response = await uploadPopupImage(formData);
      setSuccessMessage('Tạo mới thành công!');
      window.scrollTo(0, 0);
      closeAlerts();
      setImagePreview(null);
      setNewImageFile(null);
      resetForm();
    } catch (error) {
      setError('Tạo mới thất bại, vui lòng thử lại!');
      window.scrollTo(0, 0);
      closeAlerts();
      console.error('Lỗi không thể tạo mới popup:', error);
    }
  };
  return (
    <div className="container-create-popup m-3">
      {successMessage && (
        <Alert
          variant="filled" severity="success"
          onClose={() => setSuccessMessage('')}
        >
          Tạo mới thành công!
        </Alert>
      )}
      {/* Error alert */}
      {error && (
        <Alert variant="filled" severity="error"
          onClose={() => setError('')}>
          Tạo mới thất bại, vui lòng thử lại!
        </Alert>
      )}
      <h3 className='h3 text-center mb-3'>Tạo mới Popup</h3>
      <div className="row">
        <div className="col-md-6 mt-3">
          {/*hiển thị Nội dung của popup*/}
          <Form.Group className="mb-3" controlId="id">
            <TextField
              id="id"
              name="id"
              label="Nội dung"
              variant="outlined"
              fullWidth
              multiline
              rows={7}
              className="custom-textfield"
              value={editedData}
              onChange={handleDataChange}
            />
          </Form.Group>
          <Form.Group controlId="formIsShow">
            <Form.Label style={{ marginRight: '10px' }}>Trang thái</Form.Label>
            <div style={{ display: 'inline-block', marginRight: '20px' }}>
              <Form.Switch
                name="is_show"
                checked={isShow}
                onChange={handleIsShowChange}
              />
            </div>
            <span
              style={{
                backgroundColor: isShow ? '#00ff62' : '#df2d05',
                color: '#f9f8f8',
                borderRadius: '8px',
                border: `1px solid ${isShow ? '#00ff62' : '#df2d05'}`,
                padding: '5px 10px',
                fontSize: '13px',
                fontWeight: 'bold',
              }}
            >
              {isShow ? 'Đang hoạt động' : 'Đã khóa'}
            </span>
          </Form.Group>
        </div>
        <div className="col-sm-6 d-flex justify-content-center">
          <div className="d-flex flex-column align-items-center">
            <div className="text-center" >
              <Form.Label>
                Ảnh chi tiết
                <span style={{ color: 'red' }}>*</span>
              </Form.Label>
            </div>
            <Form.Group>
              <div className="d-flex flex-column align-items-center">
                {imagePreview && (
                  <img
                    src={imagePreview}
                    alt="Preview"
                    style={{ maxWidth: '450px', maxHeight: '500px' }}
                  />
                )}
                <FileInput id="avataInput" text='Chọn ảnh' onChange={handleImageChange} />
              </div>
            </Form.Group>
          </div>
        </div>
        <div className="text-center">
          <Button variant="success" onClick={handleSave}>
            Tạo mới
          </Button>
        </div>
      </div>
    </div>
  );
}

import { Axios } from "../utils/apiHelper";
//danh sách nhân viên
export const getListPartner = async ({ phone = null, current_page = 1, limit = 20 }) => {
    const url = 'staffs/staff-get-list-partner';
    return await Axios.get(url, { phone, current_page, limit });
}
//chi tiết
export const getDetailPartner = async (partnerId) => {
    const url = `staffs/staff-get-partner-information/${partnerId}`;
    return await Axios.get(url);
}
// cập nhật
export const updatePartner = async (partnerId, data) => {
    const url = `partners/staff-update-partner/${partnerId}`;
    return await Axios.put(url, data);
};
//tạo mới
export const createPartner = async (data) => {
    const url = 'partners/staff-create-partner';
    return await Axios.post(url, data);
};
//lấy danh sách tỉnh 
export const getListProvince = async (data) => {
    const url = `locations/get-list-province`;
    return await Axios.get(url, data);
}
//lấy danh sách huyện
export const getListDistrict = async (data) => {
    const url = `locations/get-list-district`;
    return await Axios.get(url, data);
}
//lấy danh sách xã
export const getListWard = async (data) => {
    const url = `locations/get-list-ward`;
    return await Axios.get(url, data);
}
//API trừ tiền
export const Withdrawal = async (data) => {
    const url = `transactions/staff-create-decrease-transaction`;
    return await Axios.post(url, data);
};
//API cộng tiền
export const PlusMoney = async (data) => {
    const url = `transactions/staff-create-increase-transaction`;
    return await Axios.post(url, data);
};
import { Axios } from "../utils/apiHelper";

// danh sách
export const getListVotes = async ({ partner_id = null, phone = null, current_page = 1, limit = 20, service_code = null, created_at = null, UserId = null }) => {
  const url = `votes/staff-get-list-vote?partner_id=${partner_id}&phone=${phone}&current_page=${current_page}&limit=${limit}&service_code=${service_code}&created_at=${created_at}&UserId=${UserId}`;
  return await Axios.get(url);
};
//chi tiết
export const getVotesUserDetail = async (userId) => {
  const url = `votes/staff-get-detail-vote/${userId}`;
  return await Axios.get(url);
};
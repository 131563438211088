import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import { getDeltailSofa, FinishJob, cancelStaffSofa } from '../../../services/sofa';
import LoadingSpinner from '../../../components/Loading/LoadingSpinner';
import numeral from 'numeral';
import moment from 'moment';
import {
  STATUS_NEW,
  STATUS_HAS_PARTNER,
  STATUS_WAITTING,
  STATUS_DOING,
  STATUS_DONE,
  STATUS_CANCEL
} from '../../../utils/constants';
import Button from '@mui/material/Button';
import { Modal } from 'react-bootstrap';
import Alert from '@mui/material/Alert';
import { showToastSuccess, showToastError } from "../../../components/Toast/toastHandler";
import './deltailsofa.scss';
export function DetailSofa() {
  const formatDate = (date) => moment(date).format('HH:mm:ss  DD/MM/YYYY');
  const formatMoney = (value) => numeral(value).format('0,0');
  const { service_code } = useParams();
  const [data, setConditionerData] = useState({});
  const [loading, setLoading] = useState(true);
  const [isConfirmingCancel, setIsConfirmingCancel] = useState(false);
  const [isFinishCancel, setIsFinishCancel] = useState(false);
  let listStatus = [STATUS_NEW, STATUS_HAS_PARTNER, STATUS_WAITTING];
  const translateStatus = (status) => {
    switch (status) {
      case STATUS_NEW:
        return <button className="status-new">Công việc mới</button>;
      case STATUS_HAS_PARTNER:
        return <button className="status-has-partner">Đã có nhân viên</button>;
      case STATUS_WAITTING:
        return <button className="status-waiting">Đang chờ làm</button>;
      case STATUS_DOING:
        return <button className="status-doing">Đang thực hiện</button>;
      case STATUS_DONE:
        return <button className="status-done">Đã hoàn thành</button>;
      default:
        return <button className="status-cancelled">Đã huỷ</button>;
    }
  };
  const translateTypes = (status) => {
    switch (status) {
      case 'CLEANING_SOFA':
        return 'vệ sinh Sofa';
      default:
        return '';
    }
  };
  const translateDeltailTypes = (detail_type) => {
    switch (detail_type) {
      case 'sofa-1-ghe':
        return 'Sofa 1 ghế';
      default:
        return '';
    }
  };
  const translatePayment = (payment_method) => {
    switch (payment_method) {
      case 'cash':
        return 'Tiền mặt';
      default:
        return 'Chuyển khoản';
    }
  };
  const translateIsPaid = (isPaid) => {
    return isPaid ? 'Đã thanh toán' : 'Chưa thanh toán';
  };
  const handleCancelClick = () => {
    setIsConfirmingCancel(true);
  };
  const handleFinishClick = () => {
    setIsFinishCancel(true);
  };
  const handleCancel = () => {
    setIsConfirmingCancel(false);
  };
  const handleCancelFinish = () => {
    setIsFinishCancel(false);
  };
  useEffect(() => {
    fetchSofaData();
  }, []);
  const fetchSofaData = async () => {
    try {
      const jobResponse = await getDeltailSofa(service_code);
      setConditionerData(jobResponse.data.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  const handleCancelConfirm = async () => {
    try {
      const response = await cancelStaffSofa(service_code);
      const successMessage = response.data.data;
      setConditionerData((prevJobData) => ({
        ...prevJobData,
        status: STATUS_CANCEL,
      }));
      await fetchSofaData();
      setIsConfirmingCancel(false);
      showToastSuccess(successMessage);
    } catch (error) {
      setIsConfirmingCancel(false);
      showToastError(error.response?.data?.data);
    }
  };
  const handleJobCompletion = async () => {
    try {
      const response = await FinishJob(data.service_code, data.service_type);
      const successMessage = response.data.message;
      setConditionerData((prevJobData) => ({
        ...prevJobData,
        status: STATUS_DONE,
      }));
      await fetchSofaData();
      setIsFinishCancel(false);
      showToastSuccess(successMessage);
    } catch (error) {
      setIsFinishCancel(false);
      showToastError(error.response?.data?.message);
    }
  };
  return (
    <div className="container-detail-safa">
      <h3 className='h3 text-center'>Chi tiết  vệ sinh Sofa</h3>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <div className="row m-3 columns-container">
          <div className="col-sm-12 mt-4 left-column">
            <div className="row">
              <p className="col custum-job">Mã công việc:</p>
              <p className="col custum-job-data">{data.service_code}</p>
            </div>
            <div className="row">
              <p className="col custum-job">Loại công việc:</p>
              <p className="col custum-job-data">{translateTypes(data.service_type)}</p>
            </div>
            <div className="row">
              <p className="col custum-job">Tổng tiền:</p>
              <p className="col custum-job-data">{data.discount_price != null ? formatMoney(data.discount_price) + "đ" : formatMoney(data.total_price) + "đ"}</p>
            </div>
            <div className="row">
              <p className="col custum-job">Phương thức thanh toán:</p>
              <p className="col custum-job-data">{translatePayment(data.payment_method)}</p>
            </div>
            <div className="row">
              <p className="col custum-job">Tình trạng thanh toán:</p>
              <p className="col custum-job-data">{translateIsPaid(data.is_paid)}</p>
            </div>
            <div className="row">
              <p className="col custum-job">Tên khách hàng:</p>
              <p className="col custum-job-data">{data.fullname}</p>
            </div>
            <div className="row">
              <p className="col custum-job">Số điện thoại:</p>
              <p className="col custum-job-data" >{data.phone}</p>
            </div>
            <div className="row">
              <p className="col custum-job">Ngày tạo:</p>
              <p className="col custum-job-data">{formatDate(data.created_at)}</p>
            </div>
            <div className="row">
              <p className="col custum-job">Địa chỉ làm việc:</p>
              <p className="col custum-job-data">{data.address}</p>
            </div>
            {data.note && (
              <div className="row">
                <p className="col custum-job">Ghi chú:</p>
                <p className="col custum-job-data">{data.note}</p>
              </div>
            )}
            {data.fee_go_to_market != null && (
              <div className="row">
                <p className="col custum-job">Phí đi chợ hộ:</p>
                <p className="col custum-job-data">{formatMoney(data.fee_go_to_market)}<sup>Đ</sup></p>
              </div>
            )}
            {data.fee_ironing_clothes != null && (
              <div className="row">
                <p className="col custum-job">Phí ủi đồ:</p>
                <p className="col custum-job-data">{formatMoney(data.fee_ironing_clothes)}<sup>Đ</sup></p>
              </div>
            )}
            {data.fee_tool != null && (
              <div className="row">
                <p className="col custum-job">Phí dụng cụ vệ sinh:</p>
                <p className="col custum-job-data">{formatMoney(data.fee_tool)}<sup>Đ</sup></p>
              </div>
            )}
            {data.fee_vat != null && (
              <div className="row">
                <p className="col custum-job">Phí VAT:</p>
                <p className="col custum-job-data">{formatMoney(data.fee_vat)}<sup>Đ</sup></p>
              </div>
            )}
            <div className="row">
              <p className="col custum-job">Trạng thái:</p>
              <p className="col custum-job-data">{translateStatus(data.status)}</p>
              {listStatus.includes(data.status) && (
                <p className="col">
                  <Button variant="outlined" color='error' style={{ textTransform: 'math-auto' }}
                    onClick={handleCancelClick}>
                    Hủy công việc
                  </Button>
                </p>
              )}
              {data.status === STATUS_DONE && (
                <p className="col">
                  <Button
                    variant="outlined"
                    color='success'
                    style={{ textTransform: 'math-auto' }}
                    onClick={handleFinishClick}
                  >
                    Hoàn thành
                  </Button>
                </p>
              )}
            </div>
          </div>
        </div >
      )}
      {loading ? (
        <LoadingSpinner />
      ) : (
        <div div className="container " style={{ margin: '0 auto' }}>
          <div className="row m-3">
            <div className="col-sm-9 mt-4 left-column gas-column">
              <h3 className="h3 text-center mb-3">Danh sách vệ sinh Sofa</h3>
              <div className="row" >
                <div className="col">
                  <p className=" custum-job">Loại Sofa:</p>
                  <p className=" custum-job-data">
                    {data.DetailCleanSofas && data.DetailCleanSofas.length > 0 ? translateDeltailTypes(data.DetailCleanSofas[0].detail_type) : 'N/A'}
                  </p>
                </div>
                <div className="col">
                  <p className=" custum-job">Chất liệu:</p>
                  <p className=" custum-job-data">
                    {data.DetailCleanSofas && data.DetailCleanSofas.length > 0 ? data.DetailCleanSofas[0].material : 'N/A'}
                  </p>
                </div>
                <div className="col">
                  <p className=" custum-job">Ngày tạo làm việc:</p>
                  <p className=" custum-job-data">
                    {data.Schedules && data.Schedules.length > 0 ? formatDate(data.Schedules[0].created_at) : 'N/A'}
                  </p>
                </div>
                <div className="col">
                  <p className=" custum-job">Giờ làm việc:</p>
                  <p className=" custum-job-data">
                    {data.Schedules && data.Schedules.length > 0 ? formatDate(data.Schedules[0].start_time) : 'N/A'}
                  </p>
                </div>
                <div className="col">
                  <p className=" custum-job">Số lượng:</p>
                  <p className=" custum-job-data">{data.DetailCleanSofas && data.DetailCleanSofas.length > 0 ? data.DetailCleanSofas[0].count : 'N/A'}</p>
                </div>
              </div>
            </div>
          </div >
        </div>
      )}
      {isConfirmingCancel && (
        <Modal show={isConfirmingCancel} onHide={handleCancel} style={{ top: '30%' }}>
          <Modal.Header closeButton style={{
            padding: '8px 8px 0 0',
            textAlign: 'center',
            border: 'none',
            outline: 'none',
            boxShadow: 'none',
            position: 'relative',
          }}>
            <Modal.Title ></Modal.Title>
          </Modal.Header>
          <Modal.Body style={{
            padding: '0',
            textAlign: 'center',
            border: 'none',
            outline: 'none',
            boxShadow: 'none',
            position: 'relative',
          }}>
            <h5 style={{ fontSize: '17px' }}>Bạn muốn hủy công việc này?</h5>
          </Modal.Body>
          <Modal.Footer style={{
            textAlign: 'center',
            border: 'none',
            outline: 'none',
            boxShadow: 'none',
            position: 'relative',
            justifyContent: 'center'
          }}>
            <button type="button" class="btn btn-success" onClick={handleCancelConfirm}>
              Đồng ý
            </button>
            <button type="button" class="btn btn-danger" onClick={handleCancel}>
              Quay lại
            </button>
          </Modal.Footer>
        </Modal>
      )}


      {isFinishCancel && (
        <Modal show={isFinishCancel} onHide={handleCancelFinish} style={{ top: '30%' }}>
          <Modal.Header closeButton style={{
            padding: '8px 8px 0 0',
            textAlign: 'center',
            border: 'none',
            outline: 'none',
            boxShadow: 'none',
            position: 'relative',
          }}>
            <Modal.Title ></Modal.Title>
          </Modal.Header>
          <Modal.Body style={{
            padding: '0',
            textAlign: 'center',
            border: 'none',
            outline: 'none',
            boxShadow: 'none',
            position: 'relative',
          }}>
            <h5 style={{ fontSize: '17px', padding: '0 10px' }}>Bạn xác nhận đã hoàn thành công việc này?</h5>
          </Modal.Body>
          <Modal.Footer style={{
            textAlign: 'center',
            border: 'none',
            outline: 'none',
            boxShadow: 'none',
            position: 'relative',
            justifyContent: 'center'
          }}>
            <button type="button" class="btn btn-success" onClick={handleJobCompletion}>
              Xác nhận
            </button>
            <button type="button" class="btn btn-danger" onClick={handleCancelFinish}>
              Quay lại
            </button>
          </Modal.Footer>
        </Modal>
      )}
    </div >
  );
}

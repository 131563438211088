import { Axios } from "../utils/apiHelper";
// danh sách
export const getListConditioner = async ({ phone = null, current_page = 1, limit = 20, status = 5, service_code = null, created_at }) => {
  const url = `repairs/staff-get-list-repair?phone=${phone}&current_page=${current_page}&limit=${limit}&status=${status}&service_code=${service_code}&created_at=${created_at}`;
  return await Axios.get(url);
};
// chi tiết
export const getDeltailConditioner = async (service_code) => {
  const url = `repairs/staff-get-detail-repair/${service_code}`;
  return await Axios.get(url);
};

// huy
export const cancelStaff = async (service_code) => {
  const url = `repairs/staff-cancel-repair/${service_code}`;
  return await Axios.put(url);
};


import './checkbox.scss';
import { Link } from 'react-router-dom';

export function CheckBox(props) {
  return (
    <div className='mt-4 container-checkbox clearfix'>
      <label className="checkbox-item"></label>
      <input type="checkbox" id="rememberMe" checked={props.checked} onChange={props.handleChange} className='form-check-input' />
      <label className="form-check-label caption" htmlFor="check1">Ghi nhớ</label>
      <span className='ml-auto'>
        <Link to="" className="forgot-pass">Quên mật khẩu ?</Link>
      </span>
    </div>
  )
}
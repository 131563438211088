import React from 'react';
import { Form } from 'react-bootstrap';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import './inputFile.scss'
const FileInput = ({ id, onChange, text }) => {
  return (
    <div className='image-container'>
      <Form.Label className="label-custom-input" htmlFor={id}>
        <FileUploadIcon /> {text}
      </Form.Label>
      <Form.Control
        id={id}
        type="file"
        style={{ display: 'none' }}
        onChange={onChange}
      />
    </div>
  );
};

export default FileInput;

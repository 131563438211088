import { Axios } from "../utils/apiHelper";

// danh sách
export const getListUser = async ({ phone = null, current_page = 1, limit = 20 }) => {
  const url = `users/staff-get-list-user?phone=${phone}&limit=${limit}&current_page=${current_page}`;
  return await Axios.get(url);
};

// chi tiết người dùng
export const getUserDetail = async (userId) => {
  const url = `users/staff-get-detail-user/${userId}`; // URL mới
  return await Axios.get(url);
};
// cập nhật
export const updateUser = async (userId, data) => {
  const url = `users/staff-update-user-information/${userId}`;
  return await Axios.put(url, data);
};

import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import AddButton from '../../../components/Button/AddButton';
import { getListBlogs, updateBlog } from '../../../services/blog';
import Pagination from '../../../components/Pagination/Pagination';
import LoadingSpinner from '../../../components/Loading/LoadingSpinner';
import './listblog.scss'

export function ListBlog() {
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [blogsPerPage,] = useState(20);
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
    window.scrollTo({ top: 0, behavior: 'smooth' });
    fetchBlog();
  };
  useEffect(() => {
    fetchBlog();
  }, [currentPage]);
  const fetchBlog = async () => {
    try {
      const response = await getListBlogs({
        current_page: currentPage,
        limit: blogsPerPage,
      });
      setBlogs(response.data.data);
      setLoading(false);
      setTotalPage(response.data.total_page);
    } catch (error) {
      console.error('Lỗi, không thể hiện danh sách blog:', error);
      setLoading(false);
    }
  };
  const groupBlogs = () => {
    const groups = [];
    const groupSize = 3;

    for (let i = 0; i < blogs.length; i += groupSize) {
      groups.push(blogs.slice(i, i + groupSize));
    }
    return groups;
  };
  // cập nhật trạng thái 
  const handleStatusUpdate = async (blogId, newStatus) => {
    try {
      const response = await updateBlog(blogId, { is_active: newStatus });
      if (response.status === 200) {
        const updatedBlogs = blogs.map((blog) =>
          blog.id === blogId ? { ...blog, is_active: newStatus } : blog
        );
        setBlogs(updatedBlogs);
      }
    } catch (error) {
      console.error('Lỗi khi cập nhật trạng thái:', error);
    }
  };

  return (
    <div className='container-blog m-3'>
      <Link to="/blog/create_blog">
        <AddButton text='Thêm Blog mới' />
      </Link>
      {loading ? (
        <LoadingSpinner />
      ) : (
        groupBlogs().map((group, index) => (
          <div key={index} className='row'>
            {group.map((blog) => (
              <div key={blog.id} className="card-blog col-sm-4">
                <div className="card" style={{ width: "100%" }}>
                  <img className="card-img-top" src={blog.image} alt="images_banner" style={{ width: "100%" }} />
                  <div className="card-body">
                    <Link to={`/blog/${blog.id}`}>
                      <h4 className="card-title" style={{ height: '45px', width: '100%', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                        {blog.title}
                      </h4>
                    </Link>
                    <Form.Group className="mb-2" controlId="is_active">
                      <Form.Label style={{ marginRight: '10px' }}>Trạng thái:</Form.Label>
                      <div style={{ display: 'inline-block', marginRight: '20px' }}>
                        <Form.Switch
                          name="is_active"
                          checked={blog.is_active}
                          onChange={(e) => handleStatusUpdate(blog.id, e.target.checked)}
                        />
                      </div>
                      <span
                        style={{
                          backgroundColor: blog.is_active ? '#00ff62' : '#df2d05',
                          color: '#ffffff',
                          borderRadius: '8px',
                          border: `1px solid ${blog.is_active ? '#00ff62' : '#df2d05'}`,
                          padding: '5px 10px',
                          fontSize: '13px',
                          fontWeight: 'bold',
                        }}
                      >
                        {blog.is_active ? 'Đang hoạt động' : 'Đã khóa'}
                      </span>
                    </Form.Group>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ))
      )
      }
      {totalPage > 1 && (
        <div className="container-page align-center">
          <Pagination totalPage={totalPage} currentPage={currentPage} handleClick={handlePageClick} />
        </div>
      )}
    </div>
  );
}
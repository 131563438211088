import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import moment from 'moment';
import numeral from "numeral";
import LoadingSpinner from "../../components/Loading/LoadingSpinner";
import { BsXLg } from "react-icons/bs";
import {
  getDetailCommission,
  commissionConFirmWithdraw,
  commissionCancelWithdraw,
} from '../../services/commission';
import {
  DEPOSIT,
  WITHDRAW,
  INCREASE,
  DECREACSE
} from '../../utils/constants'
import { FiX } from "react-icons/fi";
import './deltaicommission.scss'

export function DelTaiCommission() {
  const [modalImage, setModalImage] = useState(null);
  const [modalDisplay, setModalDisplay] = useState(false);

  const openModal = (imageSrc) => {
    setModalImage(imageSrc);
    setModalDisplay(true);
  };

  const closeModal = () => {
    setModalDisplay(false);
  };
  const { id } = useParams();
  const formatDate = (date) => { return moment(date).format('HH:mm:ss DD-MM-YYYY'); };
  const [transactionDetails, setTransactionDetails] = useState(null);
  // const shouldShowButtons = (transactionDetails?.status === 1) && (transactionDetails?.transaction_type === 'deposit');
  const ShowButtonWithdraw = (transactionDetails?.status === 1) && (transactionDetails?.transaction_type === 'withdraw');
  const [showWithdrawConfirmPopup, setShowWithdrawConfirmPopup] = useState(false);
  const [showWithdrawCancelPopup, setShowWithdrawCancelPopup] = useState(false);
  const StatusTransaction = (status) => {
    switch (status) {
      case 1:
        return <span className="status-new">Mới</span>;
      case 2:
        return <span className="status-cancelled">Đã huỷ</span>;
      case 3:
        return <span className="status-done">Thành công</span>;
      default:
        return null;
    }
  };
  const TranslateTransactionType = (transaction_type) => {
    switch (transaction_type) {
      case 'increase':
        return INCREASE;
      case 'deposit':
        return DEPOSIT;
      case 'withdraw':
        return WITHDRAW;
      default:
        return DECREACSE;
    }
  };


  const handleWithdrawConfirmClick = () => {
    setShowWithdrawConfirmPopup(true);
  };
  const handleWithdrawCancelClick = () => {
    setShowWithdrawCancelPopup(true);
  };

  const handleWithdrawCancelPopupCancelClick = () => {
    setShowWithdrawCancelPopup(false);
  };
  const handleWithdrawConfirmPopupCancelClick = () => {
    setShowWithdrawConfirmPopup(false);
  };

  useEffect(() => {
    getDetailCommission(id)
      .then(response => {
        setTransactionDetails(response.data.data);
      })
      .catch(error => {
        console.error("Lỗi không thể lấy chi tiết giao dịch:", error);
      });
  }, [id]);


  const handleWithdrawConfirmPopupConfirmClick = async () => {
    try {
      await commissionConFirmWithdraw(transactionDetails.id);
      setShowWithdrawConfirmPopup(false);
      getDetailCommission(id).then(response => {
        setTransactionDetails(response.data.data);
      });
    } catch (error) {
      console.error("Lỗi không thể xác nhận rút tiền:", error);
    }
  };
  const handleWithdrawCancelPopupConfirmClick = async () => {
    try {
      await commissionCancelWithdraw(transactionDetails.id);
      setShowWithdrawCancelPopup(false);
      getDetailCommission(id).then(response => {
        setTransactionDetails(response.data.data);
      });
    } catch (error) {
      console.error("Lỗi không thể hủy rút tiền:", error);
    }
  };
  if (!transactionDetails) {
    return <LoadingSpinner />
  }
  return (
    <div className="deltai-deltaicommission m-3">
      <div className="row text-left responsive-row" style={{ padding: '10px 80px 0 80px' }}>
        <div className="col-sm-6 ">
          <div className="row">
            <div className="col-sm-12 ">
              <p className="consaction-title">Mã giao dịch:</p>
              <p className="consaction-title">{transactionDetails ? transactionDetails.id : ''}</p>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 ">
              <p className="consaction-title">Trạng thái:</p>
              <p className="consaction-title">
                {transactionDetails ? StatusTransaction(transactionDetails.status) : ''}
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 ">
              <p className="consaction-title">Loại giao dịch:</p>
              <p className="consaction-title">
                {transactionDetails ? TranslateTransactionType(transactionDetails.transaction_type) : ''}
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 ">
              <p className="consaction-title">Ngày tạo giao dịch:</p>
              <p className="consaction-title">{transactionDetails ? formatDate(transactionDetails.created_at) : ''}</p>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 ">
              <p className="consaction-title">Khách hàng yêu cầu rút tiền:</p>
              <p className="consaction-title fullname-bold">{transactionDetails ? transactionDetails.User.fullname : ''}
                ({transactionDetails ? transactionDetails.User.id : ''})</p>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 ">
              <p className="consaction-title">Số điện thoại của khách hàng:</p>
              <p className="consaction-title">{transactionDetails ? transactionDetails.User.phone : ''}</p>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 ">
              <p className="consaction-title">Số dư Trước giao dịch:</p>
              {transactionDetails.old_balance != null ? <p className="consaction-title consaction-money">{numeral(transactionDetails.old_balance).format("0,0")}<sup>đ</sup></p> : ''}
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 ">
              <p className="consaction-title">Số tiền giao dịch:</p>
              {transactionDetails.amount != null ? <p className="consaction-title consaction-money">{numeral(transactionDetails.amount).format("0,0")}<sup>đ</sup></p> : ''}
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 ">
              <p className="consaction-title">Số dư sau giao dịch:</p>
              {transactionDetails.new_balance != null ? <p className="consaction-title consaction-money">{numeral(transactionDetails.new_balance).format("0,0")}<sup>đ</sup></p> : ''}
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 ">
              <p className="consaction-title">Nội dung giao dịch:</p>
              <p className="consaction-title">{transactionDetails ? transactionDetails.content : ''}</p>
            </div>
          </div>
          {/**/}
          <div className="d-flex justify-content-center btn-transaction" style={{ marginTop: '30px' }}>
            <div >
              {ShowButtonWithdraw && !showWithdrawCancelPopup && !showWithdrawConfirmPopup && (
                <button
                  type="button"
                  className="btn btn-danger btn-transaction"
                  style={{ marginBottom: '30px' }}
                  onClick={handleWithdrawCancelClick}
                >
                  Hủy Rút Tiền
                </button>
              )}
            </div>
            <div >
              {ShowButtonWithdraw && !showWithdrawCancelPopup && !showWithdrawConfirmPopup && (
                <button
                  type="button"
                  className="btn btn-success btn-transaction"
                  onClick={handleWithdrawConfirmClick}
                >
                  Xác Nhận Rút Tiền
                </button>
              )}
            </div>
          </div>

          {showWithdrawCancelPopup && (
            <div className="popup-container">
              <span className="icon-close">
                <FiX onClick={handleWithdrawCancelPopupCancelClick} />
              </span>
              <p>Bạn xác nhận muốn hủy rút tiền?</p>
              <div className="popup-buttons">
                <button
                  className="btn btn-danger custum-btnmobile"
                  onClick={handleWithdrawCancelPopupCancelClick}
                >
                  Hủy
                </button>
                <button
                  className="btn btn-success custum-btnmobile"
                  onClick={handleWithdrawCancelPopupConfirmClick}
                >
                  Xác nhận
                </button>
              </div>
            </div>
          )}
          {showWithdrawConfirmPopup && (
            <div className="popup-container">
              <span className="icon-close">
                <FiX onClick={handleWithdrawConfirmPopupCancelClick} />
              </span>
              <p>Bạn muốn xác nhận rút tiền?</p>
              <div className="popup-buttons">
                <button
                  className="btn btn-danger custum-btnmobile"
                  onClick={handleWithdrawConfirmPopupCancelClick}
                >
                  Hủy
                </button>
                <button
                  className="btn btn-success  custum-btnmobile"
                  onClick={handleWithdrawConfirmPopupConfirmClick}
                >
                  Xác nhận
                </button>
              </div>
            </div>
          )}
        </div>
        <div className="col-sm-6">
          <img
            src={transactionDetails ? transactionDetails.image_bill : ''}
            alt=""
            maxwidth="300"
            height="400"
            className="img-bill-mobile"
            onClick={() => openModal(transactionDetails ? transactionDetails.image_bill : '')}
          />
        </div>
      </div>
      {modalDisplay && (
        <div className="modal">
          <span className="close" onClick={closeModal}>
            <BsXLg />
          </span>
          <div className="relative__modal">
            <img className="modal-content image" src={modalImage} alt="Modal" style={{ objectFit: 'cover' }} />
          </div>
        </div>
      )}
    </div>
  );
}

import { Axios } from "../utils/apiHelper";

// danh sách
export const getListSofa = async ({ phone = null, current_page = 1, limit = 20, status = 5, service_code = null, created_at }) => {
  const url = `sofas/staff-get-list-cleaning-sofa?phone=${phone}&current_page=${current_page}&limit=${limit}&status=${status}&service_code=${service_code}&created_at=${created_at}`;
  return await Axios.get(url);
};

// chi tiết
export const getDeltailSofa = async (service_code) => {
  const url = `sofas/staff-get-detail-cleaning-sofa/${service_code}`;
  return await Axios.get(url);
};
//kết thúc công việc
export const FinishJob = async (service_code, service_type) => {
  const url = `sofas/staff-finish-cleaning-sofa`;
  const data = {
    service_code: service_code,
    service_type: service_type,
  };
  return await Axios.post(url, data);
};

// huy 
export const cancelStaffSofa = async (service_code) => {
  const url = `sofas/staff-cancel-sofa/${service_code}`;
  return await Axios.put(url);
};
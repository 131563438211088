import { Axios } from "../utils/apiHelper";

const getAllPromotions = async () => Axios.get(`/promotions/staff-get-list-pomotion`);
const getPromotionById = async (promotion_id) => Axios.get(`promotions/staff-get-detail-promotion/${promotion_id}`);
const postNewPromotion = async (data) => Axios.uploadFile(`/promotions/staff-create-promotion`, data);
const putUpdatePromotion = async (id, data) => Axios.post(`/promotions/staff-update-promotion/${id}`, data);
const uploadStaffFile = (data) => Axios.uploadFile(`/upload/staff-upload-file`, data);

export {
  getAllPromotions,
  getPromotionById,
  postNewPromotion,
  putUpdatePromotion,
  uploadStaffFile
};
import { useEffect, useState } from 'react';
import { getListSofa } from '../../../services/sofa';
import LoadingSpinner from '../../../components/Loading/LoadingSpinner';
import Pagination from '../../../components/Pagination/Pagination';
import numeral from 'numeral';
import moment from 'moment';
import Alert from '@mui/material/Alert';
import ClearIcon from '@mui/icons-material/Clear';
import React from "react";
import { Link } from 'react-router-dom';
import {
  InputLabel,
  MenuItem,
  Select,
  FormControl,
  TextField,
  IconButton,
  InputAdornment,
} from '@mui/material';
import './listsofa.scss'

export function ListSofa() {
  const [status, setStatus] = useState('');
  const [clearSelect, setClearSelect] = useState(false);
  const [searchPhone, setSearchPhone] = useState('');
  const [service_code, setServiceCode] = useState("");
  const [created_at, setcreated_at] = useState("");
  const [sofas, setSofas] = useState([]);
  const [totalPage, setTotalPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [Page,] = useState(20);
  const [loading, setLoading] = useState(true);
  const [hasSelectedItem, setHasSelectedItem] = useState(false);
  const [selectedMenuItem, setSelectedMenuItem] = useState(null);
  const [ErrorSearch, setErrorSearch] = useState('');
  const handleJobCodeChange = (event) => {
    let serviceCode = event.target.value;
    setServiceCode(serviceCode);
  }
  const handleDateCreatedChange = (event) => {
    const selectedDate = event.target.value;
    const formattedDate = moment(selectedDate).format('YYYY-MM-DD');
    setcreated_at(formattedDate);
  };
  const handleStatusChange = (event) => {
    const selectedValue = event.target.value;
    setStatus(selectedValue);
    setSelectedMenuItem(selectedValue);
    setHasSelectedItem(true);
  };

  const handleClearSelect = () => {
    setStatus('');
    setSelectedMenuItem(null);
    setClearSelect(false);
  };

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
    window.scrollTo({ top: 0, behavior: 'smooth' });
    fetchData();
  };
  const handleInputChange = (event) => { setSearchPhone(event.target.value); };
  const formatMoney = (value) => { return numeral(value).format('0,0'); };
  const formatDate = (date) => { return moment(date).format('YYYY-MM-DD'); };
  const translateStatusSofa = (status) => {
    switch (status) {
      case 1:
        return (
          <button className="status-new">Công việc mới</button>
        );
      case 2:
        return (
          <button className="status-has-partner">Đã có nhân viên</button>
        );
      case 3:
        return (
          <button className="status-waiting">Đang chờ làm</button>
        );
      case 4:
        return (
          <button className="status-doing">Đang thực hiện</button>
        );
      case 5:
        return (
          <button className="status-done">Đã hoàn thành</button>
        );
      default:
        return (
          <button className="status-cancelled">Đã huỷ</button>
        );
    }
  };
  const translateTypeSofa = (status) => {
    switch (status) {
      case 'CLEANING_SOFA':
        return 'vệ sinh sofa';
      default:
        return '';
    }
  };
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };
  const handleSearch = async () => {
    setCurrentPage(1);
    await fetchData();
  };
  const closeAlerts = () => {
    setTimeout(() => {
      setErrorSearch(null);
    }, 2500);
  };
  const fetchData = async () => {
    try {
      const params = {
        phone: searchPhone,
        service_code: service_code,
        current_page: currentPage,
        limit: Page,
        status: status ? parseInt(status, 10) : '',
        created_at: created_at,
      };
      const response = await getListSofa(params);
      const data = response.data.data;
      if (data.length === 0) {
        setErrorSearch('Không tìm thấy công việc tìm kiếm !');
      } else {
        setErrorSearch('');
      }
      setSofas(data);
      setTotalPage(response.data.total_page);
      setLoading(false);
      closeAlerts();
    } catch (error) {
      closeAlerts();
      setLoading(false);
      console.error('Lỗi khi tìm dữ liệu:', error);
    }
  };
  useEffect(() => {
    fetchData();
  }, [currentPage]);
  // phần giao diện
  return (
    <div className="container-list-sofa">
      <div className='container'>
        {ErrorSearch && (
          <Alert variant="filled" severity="error" className='search-error'>
            {ErrorSearch}
          </Alert>
        )}
        <h3 className="h3 text-center">Danh sách vệ sinh sofa</h3>
        <div className='container-job-filter text-center mb-3'>
          <div className="row ">
            {/* số điện thoại */}
            <div class="col-sm-2">
              <TextField
                type='number'
                id="phone"
                label="Số điện thoại"
                variant="outlined"
                size="small"
                value={searchPhone}
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
                className='custum-rpsive-job'
              />
            </div>
            {/* mã nhân viên */}
            <div class="col-sm-2">
              <TextField
                id="service_code"
                label="Mã công việc"
                variant="outlined"
                size="small"
                value={service_code}
                onChange={handleJobCodeChange}
                onKeyDown={handleKeyDown}
                className='custum-rpsive-job'
              />
            </div>
            {/* ngày tạo */}
            <div class="col-sm-3">
              <TextField
                id="date"
                name="date-created"
                label="Ngày tạo"
                type="date"
                size="small"
                className='custum-rpsive-job custum-date-job'
                value={created_at}
                InputLabelProps={{
                  shrink: true
                }}
                onChange={handleDateCreatedChange}
                onKeyDown={handleKeyDown}
              />

            </div>
            {/* trạng thái */}
            <div className='col-sm-3'>
              <FormControl fullWidth>
                <InputLabel id='status'>Trạng thái</InputLabel>
                <Select
                  labelId='status'
                  id='status'
                  label='trạng thái'
                  size='small'
                  value={status}
                  onChange={handleStatusChange}
                  className={`custum-rpsive-job selectContainer ${selectedMenuItem !== null ? 'hide-dropdown-icon' : ''}`}
                  endAdornment={
                    status && (
                      <InputAdornment position='end'>
                        <IconButton
                          aria-label='Clear Selection'
                          edge='end'
                          onClick={handleClearSelect}
                        >
                          <ClearIcon className='icon-clear-status' />
                        </IconButton>
                      </InputAdornment>
                    )
                  }

                  inputProps={{
                    onFocus: () => setClearSelect(!!status),
                    onBlur: () => setClearSelect(false),
                  }}
                >
                  <MenuItem value={1}>Công việc mới</MenuItem>
                  <MenuItem value={2}>Đã có nhân viên</MenuItem>
                  <MenuItem value={3}>Đang chờ làm</MenuItem>
                  <MenuItem value={4}>Đang thực hiện</MenuItem>
                  <MenuItem value={5}>Đã hoàn thành</MenuItem>
                  <MenuItem value={6}>Đã hủy</MenuItem>
                </Select>
              </FormControl>
            </div>
            {/* tìm kiếm */}
            <div className='col-sm-2'>
              <button type="button"
                class="btn btn-outline-success pe-auto custum-rpsive-job"
                onClick={handleSearch}>
                Tìm kiếm
              </button>
            </div>
          </div>
        </div>
        {/*bảng dữ liệu công việc*/}
        <div>
          {loading ? (
            <LoadingSpinner />
          ) : (
            <div className='cart-table table-responsive'>
              <table className="table table-prodcut table-sm table-hover">
                <thead>
                  <tr className='text-center'>
                    <th>Mã công việc</th>
                    <th>Loại công việc</th>
                    <th>User_id</th>
                    <th>Tên khách hàng</th>
                    <th>Số điện thoại</th>
                    <th>Ngày tạo</th>
                    <th>Tổng tiền</th>
                    <th>Trạng thái</th>
                  </tr>
                </thead>
                <tbody>
                  {sofas.map((sofa, index) => (
                    <tr key={index} className='text-center'>
                      <td>
                        <Link to={`/sofa/${sofa.service_code}`} className='custum-td-job'>{sofa.service_code}</Link>
                      </td>
                      <td style={{ textTransform: 'capitalize' }}>{translateTypeSofa(sofa.service_type)}</td>
                      <td>
                        <Link to={`/user/${sofa.user_id}`} className='custum-td-job'>
                          {sofa.user_id}
                        </Link>
                      </td>
                      <td >{sofa.fullname}</td>
                      <td>{sofa.phone}</td>
                      <td>{formatDate(sofa.created_at)}</td>
                      <td>
                        {formatMoney(sofa.discount_price != null ? sofa.discount_price : sofa.total_price, sofa.discount_price)}<sup>đ</sup>
                      </td>
                      <td>{translateStatusSofa(sofa.status)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
        {
          totalPage > 1 && (
            <Pagination totalPage={totalPage} currentPage={currentPage} handleClick={handlePageClick} />
          )
        }
      </div>
    </div >
  )
}
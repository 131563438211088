import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../../hooks/useAuth';
import { Button, CheckBox } from '..';
import { staffLogin } from '../../../../services/staff';
import { TextField } from '@mui/material';
import { FaEye, FaEyeSlash } from "react-icons/fa";
import './loginform.scss';

export function LoginForm() {
  const { login } = useAuth();
  const [phoneNumber, setPhoneNumber] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const [validPassword, setValidPassword] = useState(false);
  const [phoneNumberError, setPhoneNumberError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [enterPressed, setEnterPressed] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const handlePhoneNumberChange = (event) => {
    const phoneValue = event.target.value.trim();
    setPhoneNumber(phoneValue);
    if (phoneValue.length !== 10) {
      setPhoneNumberError('Số điện thoại phải có đúng 10 số');
    } else {
      setPhoneNumberError('');
    }
  };
  const handlePasswordChange = (event) => {
    const newPassword = event.target.value;
    setPassword(newPassword);
    setValidPassword(newPassword.length >= 6);
    if (newPassword.length < 6) {
      setPasswordError('Mật khẩu phải có ít nhất 6 kí tự');
    } else {
      setPasswordError('');
    }
  };
  const handleRememberMeChange = (event) => {
    setRememberMe(event.target.checked);
  };
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const handleSubmit = async () => {
    setPhoneNumberError('');
    setPasswordError('');
    if (!phoneNumber) {
      setPhoneNumberError('Số điện thoại không được để trống');
    }
    if (!validPassword) {
      setPasswordError('Mật khẩu phải có ít nhất 6 kí tự');
    }
    if (!phoneNumber || !validPassword) {
      return;
    }
    try {
      const response = await staffLogin({
        phone: phoneNumber,
        password: password
      });
      const userData = response.data.data;
      setPhoneNumber('');
      setPassword('');
      setRememberMe(false);
      login(userData);
    } catch (error) {
      console.error('An error occurred:', error);
      setPhoneNumberError('Số điện thoại không chính xác. Vui lòng thử lại.');
      setPasswordError('Mật khẩu không chính xác. Vui lòng thử lại.');
    }
  };
  const handleKeyPress = async (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      if (!phoneNumber || !validPassword) {
        if (!phoneNumber) {
          setPhoneNumberError('Số điện thoại không được để trống');
        } else {
          setPhoneNumberError('');
        }
        if (!validPassword) {
          setPasswordError('Mật khẩu phải có ít nhất 6 kí tự');
        } else {
          setPasswordError('');
        }
      } else {
        try {
          const response = await staffLogin({
            phone: phoneNumber,
            password: password
          });
          const userData = response.data.data;
          setPhoneNumber('');
          setPassword('');
          setRememberMe(false);
          login(userData);
        } catch (error) {
          console.error('An error occurred:', error);
          setPhoneNumberError('Số điện thoại không chính xác. Vui lòng thử lại.');
          setPasswordError('Mật khẩu không chính xác. Vui lòng thử lại.');
        }
      }
    }
  };
  useEffect(() => {
    if (enterPressed) {
      handleSubmit();
      setEnterPressed(false);
    }
  }, [enterPressed]);
  return (
    <form>
      <div className='container-form-login'>
        <h3 className='h3'>Đăng nhập vào <strong> Ting</strong></h3>
        <p className='title-form-login'>Quản lý thông tin Khách hàng và đối tác</p>
        <div className="custum-input-form-login mb-3 col-sm-6">
          <TextField
            type='number'
            id="phone"
            label={
              <span>
                Số điện thoại <span className="red-asterisk">*</span>
              </span>
            }
            variant="standard"
            size="small"
            className='custum-input-form-login'
            value={phoneNumber}
            onChange={handlePhoneNumberChange}
            error={!!phoneNumberError}
            helperText={phoneNumberError}
            onKeyDown={handleKeyPress}
          />
        </div>
        <div className="custum-input-form-login mt-2 col-sm-6">
          <TextField
            type={showPassword ? 'text' : 'password'}
            id="password"
            label={
              <span>
                Mật khẩu <span className="red-asterisk">*</span>
              </span>
            }
            variant="standard"
            size="small"
            className='custum-input-form-login'
            value={password}
            onChange={handlePasswordChange}
            error={!!passwordError}
            helperText={passwordError}
            onKeyDown={handleKeyPress}
            InputProps={{
              endAdornment: (
                <span onClick={togglePasswordVisibility} className="password-toggle-icon">
                  {showPassword ? <FaEyeSlash /> : <FaEye />}
                </span>
              ),
            }}
          />
        </div>
        <CheckBox checked={rememberMe} handleChange={handleRememberMeChange} />
        <Button handleClick={handleSubmit} />
      </div>
    </form>
  );
}
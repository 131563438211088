import React from 'react';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';

const AddButton = ({ text }) => (
  <Button variant="contained" color="success" startIcon={<AddIcon />}
    className='btn-statistical btn-addprotomotion btn-add-blog btn-addparter btn-add-blog btn-addpopup btn-addwordship'
    style={{ textTransform: 'math-auto', }}>
    {text}
  </Button>
);

export default AddButton;

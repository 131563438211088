import { Axios } from "../utils/apiHelper";
// list
export const getListProdcut = async ({ current_page = 1, limit = 20, product_code, category_id }) => {
  const url = `worships/staff-get-list-product?current_page=${current_page}&limit=${limit}&product_code=${product_code}&category_id=${category_id}`;
  return await Axios.get(url);
};
// deltail
export const getDeltailProdcut = async (id) => {
  const url = `worships/staff-get-detail-product/${id}`;
  return await Axios.get(url);
};
// create
export const createNewProdcuts = async (data) => {
  const url = 'worships/staff-create-product';
  return await Axios.post(url, data);
};
// update
export const updateProdcut = async (id, data) => {
  const url = `worships/staff-update-product/${id}`;
  return await Axios.put(url, data);
};
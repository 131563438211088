import React, { useEffect, useState } from 'react';
import { Card, Form } from 'react-bootstrap';
import { getAllPopups, updatePopupImage } from '../../services/popup';
import { Link } from 'react-router-dom';
import AddButton from '../../components/Button/AddButton';
import LoadingSpinner from '../../components/Loading/LoadingSpinner'
import './style/Listpopup.scss';

export function ListPopup() {
  const [popupImages, setPopupImages] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    fetchPopupImages();
  }, []);

  const fetchPopupImages = async () => {
    try {
      const response = await getAllPopups();
      const popupData = response.data.data;

      if (Array.isArray(popupData) && popupData.length > 0) {
        setPopupImages(popupData);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error('Lỗi không thể hiển thị dữ liệu của Popup:', error);
    }
  };
  const handleStatusChange = async (popupId, newStatus) => {
    try {
      await updatePopupImage(popupId, { is_show: newStatus });
      setPopupImages(prevPopupImages =>
        prevPopupImages.map(popup =>
          popup.id === popupId ? { ...popup, is_show: newStatus } : popup
        )
      );
    } catch (error) {
      console.error('Lỗi khi cập nhật trạng thái popup:', error);
    }
  };
  return (
    <div className="container-list-popup">
      <Link Link to="/popup/create_popup">
        <AddButton text='Tạo PoPup' />
      </Link>
      {/* Hiển thị popups theo hàng */}
      {loading ? (
        <LoadingSpinner />
      ) : (
        <div className="popup-container">
          {popupImages.map((popupImage, index) => (
            <Card className="popup-item" key={index}
              style={{ marginLeft: '20px' }}
            >
              <div className="card-img-wrapper">
                <img src={popupImage.image} alt='' />
              </div>
              <Card.Body>
                <Form.Group className="mb-2" controlId="is_show">
                  <Form.Label style={{ marginRight: '10px' }}>Trạng thái:</Form.Label>
                  <div style={{ display: 'inline-block', marginRight: '20px' }}>
                    <Form.Switch
                      name="is_show"
                      checked={popupImage.is_show}
                      onChange={() =>
                        handleStatusChange(popupImage.id, !popupImage.is_show)
                      }
                    />
                  </div>
                  <span
                    style={{
                      backgroundColor: popupImage.is_show ? '#00ff62' : '#df2d05',
                      color: '#ffffff',
                      borderRadius: '8px',
                      border: `1px solid ${popupImage.is_show ? '#00ff62' : '#df2d05'}`,
                      padding: '5px 10px',
                      fontSize: '13px',
                      fontWeight: 'bold',
                    }}
                  >
                    {popupImage.is_show ? 'Đang hoạt động' : 'Đã khóa'}
                  </span>
                </Form.Group>
                <Link
                  to={`/popup/${popupImage.id}`}
                  className="btn-detail-promotion"
                  style={{
                    textDecoration: 'none',
                    display: 'inline-block',
                    position: 'relative',
                    color: '#c2187d',
                  }}>
                  Xem chi tiết
                  <svg
                    width="15"
                    height="15"
                    viewBox="0 0 15 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.18194 4.18185C6.35767 4.00611 6.6426 4.00611 6.81833 4.18185L9.81833 7.18185C9.90272 7.26624 9.95013 7.3807 9.95013 7.50005C9.95013 7.6194 9.90272 7.73386 9.81833 7.81825L6.81833 10.8182C6.6426 10.994 6.35767 10.994 6.18194 10.8182C6.0062 10.6425 6.0062 10.3576 6.18194 10.1819L8.86374 7.50005L6.18194 4.81825C6.0062 4.64251 6.0062 4.35759 6.18194 4.18185Z"
                      fill="currentColor"
                      fillRule="evenodd"
                      clipRule="evenodd"
                    />
                  </svg>
                </Link>
              </Card.Body>
            </Card>
          ))}
        </div>
      )}
    </div>
  );
}

import React from "react";
import { Link } from 'react-router-dom';
import { FaChevronRight, FaChevronLeft, FaAngleDoubleRight, FaAngleDoubleLeft } from "react-icons/fa";
import './pagination.scss'
function Pagination({ totalPage, currentPage, handleClick }) {
  const isFirstPage = currentPage === 1;
  const isLastPage = currentPage === totalPage;

  const handlePreviousClick = () => {
    if (!isFirstPage) {
      handleClick(currentPage - 1);
    }
  };
  const handleNextClick = () => {
    if (!isLastPage) {
      handleClick(currentPage + 1);
    }
  };
  const handleFirstClick = () => {
    if (!isFirstPage) {
      handleClick(1);
    }
  };
  const handleLastClick = () => {
    if (!isLastPage) {
      handleClick(totalPage);
    }
  };
  const getPageNumbersToShow = () => {
    const pageNumbersToShow = [];
    const MAX_PAGES = 5;

    if (totalPage <= MAX_PAGES) {
      for (let i = 1; i <= totalPage; i++) {
        pageNumbersToShow.push(i);
      }
    } else {
      if (currentPage <= 5) {
        for (let i = 1; i <= 5; i++) {
          pageNumbersToShow.push(i);
        }
        if (totalPage > 5) {
          pageNumbersToShow.push('...');
        }
        pageNumbersToShow.push(totalPage);
      } else if (currentPage >= totalPage - 2) {
        pageNumbersToShow.push(1);
        if (totalPage > 4) {
          pageNumbersToShow.push('...');
        }
        for (let i = totalPage - 2; i <= totalPage; i++) {
          pageNumbersToShow.push(i);
        }
      } else {
        pageNumbersToShow.push(1);
        if (currentPage > 4) {
          pageNumbersToShow.push('...');
        }
        pageNumbersToShow.push(currentPage - 1);
        pageNumbersToShow.push(currentPage);
        pageNumbersToShow.push(currentPage + 1);
        if (totalPage > currentPage + 2) {
          pageNumbersToShow.push('...');
        }
        pageNumbersToShow.push(totalPage);
      }
    }
    return pageNumbersToShow;
  };
  const pageNumbersToShow = getPageNumbersToShow();
  return (
    <div className="container-page">
      <div className="container">
        <ul className="pagination container-page">
          {!isFirstPage && (
            <li
              className="page-item"
              onClick={handleFirstClick}
              style={{ marginRight: '20px' }}
            >
              <Link to="" className="page-link">
                <FaAngleDoubleLeft />
              </Link>
            </li>
          )}
          {!isFirstPage && (
            <li className="page-item" onClick={handlePreviousClick}>
              <Link to="" className="page-link">
                <FaChevronLeft />
              </Link>
            </li>
          )}
          {pageNumbersToShow.map((pageNumber, index) => (
            <li
              key={index}
              onClick={pageNumber === '...' ? null : () => handleClick(pageNumber)}
              className={currentPage === pageNumber ? 'page-item active' : 'page-item'}
            >
              {pageNumber === '...' ? (
                <span className="page-link" style={{ cursor: 'not-allowed' }}>...</span>
              ) : (
                <Link to="" className="page-link">
                  {pageNumber}
                </Link>
              )}
            </li>
          ))}
          {!isLastPage && (
            <li className="page-item" onClick={handleNextClick}>
              <Link to="" className="page-link">
                <FaChevronRight />
              </Link>
            </li>
          )}
          {!isLastPage && (
            <li
              className="page-item"
              onClick={handleLastClick}
              style={{ marginLeft: '20px' }}
            >
              <Link to="" className="page-link">
                <FaAngleDoubleRight />
              </Link>
            </li>
          )}
        </ul>
      </div>
    </div>
  );
}
export default Pagination;
import { React, useState, useContext, createContext, useMemo } from 'react';
import { Outlet, Navigate } from 'react-router-dom'
import { Navigation } from '../Navigation';
// import { Footer } from '../Footer';
import { Sidebar } from '../Sidebar';
// import { AuthProvider } from "../../hooks/useAuth";
import { useAuth } from "../../hooks/useAuth";
import './dashboard.scss';

export const DashboardContext = createContext();

export function DashboardLayout() {
    const { user } = useAuth();
    const [isOpenSidebar, setToggleSidebar] = useState(false);
    const ToggleSidebar = (isOpenSidebar) => {
        setToggleSidebar(!isOpenSidebar);
    }
    const value = useMemo(
        () => ({
            isOpenSidebar,
            ToggleSidebar,
        }),
        [isOpenSidebar]
    );

    if (!user) {
        return <Navigate to="/login" />;
    }
    return (
        <DashboardContext.Provider value={value}>
            <div className=''>
                <div className="flex-container">
                    <Sidebar />
                    <div className="page-container">
                        <Navigation />
                        <Outlet />
                    </div>
                </div>
            </div>
        </DashboardContext.Provider>
    )
}
export const useDashboardLayout = () => {
    return useContext(DashboardContext);
};

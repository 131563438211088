import { Axios } from "../utils/apiHelper";

export const getWorShip = async () => {
  const url = 'worships/staff-get-worship-categories';
  return await Axios.get(url);
}
export const uploadStatusWorships = async (Id, data) => {
  const url = `worships/staff-lock-worship-category/${Id}`;
  return await Axios.put(url, data);
};




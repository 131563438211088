import { Axios } from "../utils/apiHelper";
// danh sách
export const getListJob = async ({ phone = null, current_page = 1, limit = 20, status = 5, service_code = null, created_at, service_type }) => {
  const url = `services/staff-get-list-task?phone=${phone}&current_page=${current_page}&limit=${limit}&status=${status}&service_code=${service_code}&created_at=${created_at}&service_type=${service_type}`;
  return await Axios.get(url);
};
// chi tiết
export const getStaffDetailTask = async (service_code) => {
  const url = `services/staff-get-detail-task/${service_code}`;
  return await Axios.get(url);
};
// nút hủy công việc
export const cancelTask = async (service_code) => {
  const url = `services/staff-cancel-task/${service_code}`;
  return await Axios.post(url);
};
// nút Chọn 
export const choosePartnerForTask = async (service_code, service_type, partner_id) => {
  const url = `services/staff-choose-partner-doing-task`;
  const data = {
    service_code: service_code,
    service_type: service_type,
    partner_id: partner_id,
  };
  return await Axios.post(url, data);
};

export const getListPartnerAcceptJob = async (service_code) => {
  const url = `services/staff-get-list-partner-accept-job/${service_code}`;
  return await Axios.get(url);
}

// api Đổi nhân viên
export const changePartnerForTask = async (schedule_id, service_code, partner_id) => {
  const url = `services/staff-change-partner-doing`;
  const data = {
    schedule_id: schedule_id,
    service_code: service_code,
    partner_id: partner_id,
  };
  return await Axios.put(url, data);
};

// API gửi lại yêu cầu thông báo
export const SendRequire = async (service_code, service_type, partner_id) => {
  const url = `services/staff-resend-job`;
  const data = {
    service_code: service_code,
    service_type: service_type,
    partner_id: partner_id,
  };
  return await Axios.post(url, data);
};

import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
import { getStaffDetailTask, cancelTask, choosePartnerForTask, changePartnerForTask, SendRequire } from '../../../services/Job';
import LoadingSpinner from '../../../components/Loading/LoadingSpinner';
import { showToastSuccess, showToastError } from "../../../components/Toast/toastHandler";
import { getListPartner } from '../../../services/partner';
import { getListPartnerAcceptJob } from '../../../services/Job';
import numeral from 'numeral';
import moment from 'moment';
import { Form, Modal } from 'react-bootstrap';
import { FiX } from "react-icons/fi";
import {
  InputLabel,
  MenuItem,
  Select,
  FormControl,
  // TextField,
} from '@mui/material';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import {
  STATUS_NEW,
  STATUS_HAS_PARTNER,
  STATUS_WAITTING,
  STATUS_DOING,
  STATUS_DONE,
  STATUS_CANCEL
} from '../../../utils/constants';
import './detailjob.scss';

export function DetailJob() {
  const [schedules, setSchedules] = useState([]);
  const [loadingSchedules, setLoadingSchedules] = useState(true);
  const [Foods, setFoods] = useState([]);
  const [loadingFoods, setLoadingFoods] = useState(true);
  const [Market, setMarket] = useState([]);
  const [loadingMarket, setLoadingMarket] = useState(true);
  const [serialNumber, setSerialNumber] = useState(1);
  const formatDate = (date) => moment(date).format('HH:mm:ss  DD/MM/YYYY');
  const formatMoney = (value) => numeral(value).format('0,0');
  const { service_code } = useParams();
  const [jobData, setJobData] = useState({});
  const [loading, setLoading] = useState(true);
  const [isConfirmingCancel, setIsConfirmingCancel] = useState(false);
  const [successMessageJob, setSuccessMessageJob] = useState('');
  const [successMessageSaff, setSuccessMessageSaff] = useState('');
  const [errorSaff, setErrorSaff] = useState(null);
  const [errorJob, setErrorJob] = useState(null);
  const [showLabelChangeTask, setShowLabelChangeTask] = useState(true);
  const [showLabel, setShowLabel] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [showModalChangeTask, setShowModalChangeTas] = useState(false);
  const [partnerList, setPartnerList] = useState([]);
  const [partnerListChange, setChangePartnerList] = useState([]);
  const [partner_id, setpartner_id] = useState("");
  const [ChangepartnerId, setChangeartnerId] = useState(null);
  const [selectedNewStaffId, setSelectedNewStaffId] = useState(null);

  const handleSelectStaffClick = () => {
    setShowModal(true);
  };
  const handleChangSelectStaffClick = (partnerId) => {
    setSelectedNewStaffId(partnerId);
    setShowModalChangeTas(true);
  };
  const handleCloseModal = () => {
    setChangeartnerId(null);
    setShowModal(false);
  };
  const handleChangeCloseModal = () => {
    setChangeartnerId(null);
    setShowModalChangeTas(false);
  };

  const translateStatusJob = (status) => {
    switch (status) {
      case STATUS_NEW:
        return <button className="status-new">Công việc mới</button>;
      case STATUS_HAS_PARTNER:
        return <button className="status-has-partner">Đã có nhân viên</button>;
      case STATUS_WAITTING:
        return <button className="status-waiting">Đang chờ làm</button>;
      case STATUS_DOING:
        return <button className="status-doing">Đang thực hiện</button>;
      case STATUS_DONE:
        return <button className="status-done">Đã hoàn thành</button>;
      default:
        return <button className="status-cancelled">Đã huỷ</button>;
    }
  };
  const translateTypesJob = (status) => {
    switch (status) {
      case 'ODD_SHIFT':
        return 'Ca lẻ';
      case 'TOTAL_SANITATION':
        return 'Tổng vệ sinh';
      default:
        return '';
    }
  };
  const translatePaymentMethod = (payment_method) => {
    switch (payment_method) {
      case 'cash':
        return 'Tiền mặt';
      default:
        return 'Chuyển khoản';
    }
  };
  const translateIsPaid = (isPaid) => {
    return isPaid ? 'Đã thanh toán' : 'Chưa thanh toán';
  };
  // chức vụ partner
  const translateTypePartner = (type) => {
    switch (type) {
      case 'cleaner':
        return 'Nhân viên vệ sinh';
      case 'delivery':
        return 'Nhân viên giao hàng';
      case 'collector':
        return 'Nhân viên thu gom';
      default:
        return 'Default';
    }
  };
  // thực hiện nút hủy
  const handleCancelClick = () => {
    setIsConfirmingCancel(true);
  };
  const handleEnterKeyPress = (event) => {
    if (event.key === 'Enter') {
      setSerialNumber((prevSerialNumber) => prevSerialNumber + 1);
    }
  };
  const handleCancelCancel = () => {
    setIsConfirmingCancel(false);
  };
  const arrStatus = [STATUS_HAS_PARTNER, STATUS_WAITTING];
  let listStatus = [STATUS_NEW, STATUS_HAS_PARTNER, STATUS_WAITTING];
  const handleSelectChange = (event) => {
    const selectedPartnerId = event.target.value;
    setpartner_id(selectedPartnerId);
    setShowLabelChangeTask(false);
  };
  const closeAlerts = () => {
    setTimeout(() => {
      setSuccessMessageJob('');
      setSuccessMessageSaff('');
      setErrorSaff(null);
      setErrorJob(null);
    }, 2000);
  };
  useEffect(() => {
    fetchJobData();
    fetchPartnerList();
    fetchChangePartnerList();
  }, []);
  useEffect(() => {
    if (Object.keys(jobData).length > 0) {
      setShowModalChangeTas(false);
      setSelectedNewStaffId(null);
    }
  }, [jobData]);
  useEffect(() => {
  }, [selectedNewStaffId]);

  const fetchJobData = async () => {
    try {
      const jobResponse = await getStaffDetailTask(service_code);
      setJobData(jobResponse.data.data);
      setLoading(false);
      setSchedules(jobResponse.data.data.Schedules);
      setLoadingSchedules(false);
      setFoods(jobResponse.data.data.Foods);
      setLoadingFoods(false);
      setMarket(jobResponse.data.data.Market);
      setLoadingMarket(false);
    } catch (error) {
      console.error("Lỗi khi tải dữ liệu công việc và lịch làm việc:", error);
      setLoading(false);
      setLoadingSchedules(false);
      setLoadingFoods(false);
      setLoadingMarket(false);
    }
  };
  // xử lý nút chọn
  const handleSelectButtonClick = async (e) => {
    try {
      await choosePartnerForTask(
        jobData.service_code,
        jobData.service_type,
        partner_id
      );
      fetchJobData();
      setShowModal(false);
      setSuccessMessageSaff('Chọn nhân viên thành công!');
      window.scrollTo(0, 0);
      closeAlerts();
    } catch (error) {
      setErrorSaff('Lỗi khi chọn nhân viên!');
      window.scrollTo(0, 0);
      closeAlerts();
      console.error("Lỗi khi chọn nhân viên:", error);
    }
  };
  const handleChangeSendRequire = async () => {
    try {
      const updated = await changePartnerForTask(
        jobData.Schedules[0].id,
        jobData.service_code,
        selectedNewStaffId
      );
      const successMessage = updated.data.message;
      setpartner_id(selectedNewStaffId);
      // fetchJobData();
      // setSuccessMessageSaff("Thay đổi nhân viên làm việc thành công");
      setShowModalChangeTas(false);
      await fetchJobData();
      showToastSuccess(successMessage);
      window.scrollTo(0, 0);
      // closeAlerts();
    } catch (error) {
      setShowModalChangeTas(false);
      // setErrorSaff('Lỗi khi chọn nhân viên mới!');
      showToastError(error.response?.data?.message);
      window.scrollTo(0, 0);
      // closeAlerts();
    }
  };


  const handleCancelConfirm = async () => {
    try {
      await cancelTask(service_code);
      setJobData((prevJobData) => ({
        ...prevJobData,
        status: STATUS_CANCEL,
      }));
      fetchJobData();
      setSuccessMessageJob('Công việc đã được hủy!');
      setIsConfirmingCancel(false);
      closeAlerts();
      setTimeout(() => {
        setSuccessMessageJob('');
      }, 2000);
    } catch (error) {
      setErrorJob('Lỗi khi hủy công việc!');
      console.error("Lỗi khi hủy công việc:", error);
      setIsConfirmingCancel(false);
      closeAlerts();
    }
  };
  // API nút chọn nhân viên 
  const fetchPartnerList = async () => {
    try {
      const response = await getListPartnerAcceptJob(service_code);
      setPartnerList(response.data.data);
    } catch (error) {
      console.error("Lỗi khi tải nhân viên lên:", error);
    }
  };
  const fetchChangePartnerList = async () => {
    try {
      const response = await getListPartner({
        limit: 1000
      });
      const activePartners = response.data.data.filter(partner => partner.is_active);
      setChangePartnerList(activePartners);
    } catch (error) {
    }
  };

  // nút gửi yêu cầu
  const handleSendRequestClick = async () => {
    try {
      await SendRequire(jobData.service_code, jobData.service_type, jobData.send_to_partner);
      setSuccessMessageJob('Yêu cầu đã được gửi thành công!');
      closeAlerts();
    } catch (error) {
      setErrorJob('Lỗi khi gửi yêu cầu, vui lòng thử lại sau!');
      console.error("Lỗi khi gửi yêu cầu:", error);
      closeAlerts();
    }
  };
  return (
    <div className="container-detail-job">
      {successMessageJob && (
        <Alert
          variant="filled" severity="success"
          onClose={() => setSuccessMessageJob('')}
          className="custum-alert"
        >
          Công việc đã được hủy thành công!
        </Alert>
      )}
      {successMessageSaff && (
        <Alert
          variant="filled" severity="success"
          onClose={() => successMessageSaff('')}
          className="custum-alert"
        >
          Chọn nhân viên mới thành công!
        </Alert>
      )}
      {errorSaff && (
        <Alert variant="filled" severity="error"
          onClose={() => setErrorSaff('')} className="custum-alert">
          Lỗi khi chọn nhân viên, vui lòng thử lại sau!
        </Alert>
      )}
      <h3 className='h3 text-center mb-2'>Chi tiết công việc</h3>
      {!loading ? (
        Object.keys(jobData).length > 0 ? (
          <div className="row m-3 columns-container">
            <div className="col-sm-5 mt-4 left-column">
              {jobData.send_to_partner != null && (
                <div className="row">
                  <p className="col custum-job">Yêu cầu CTV làm việc</p>
                  <p className="col custum-job-data">{jobData.send_to_partner}
                    <Button variant="contained" color="warning"
                      className='btn-statistical send-require'
                      style={{ textTransform: 'math-auto' }}
                      onClick={handleSendRequestClick}
                    >
                      Gửi yêu cầu
                    </Button>
                  </p>
                </div>
              )}
              <div className="row">
                <p className="col custum-job">Mã công việc:</p>
                <p className="col custum-job-data">{jobData.service_code}</p>
              </div>
              <div className="row">
                <p className="col custum-job">Loại công việc:</p>
                <p className="col custum-job-data">{translateTypesJob(jobData.service_type)}</p>
              </div>
              {jobData.voucher != null && (
                <div className="row">
                  <p className="col custum-job">Khuyến mãi:</p>
                  <p className="col custum-job-data">{jobData.voucher}</p>
                </div>
              )}
              <div className="row">
                <p className="col custum-job">Tổng tiền:</p>
                <p className="col custum-job-data">{jobData.discount_price != null ? formatMoney(jobData.discount_price) + "đ" : formatMoney(jobData.total_price) + "đ"}</p>
              </div>
              <div className="row">
                <p className="col custum-job">Phương thức thanh toán:</p>
                <p className="col custum-job-data">{translatePaymentMethod(jobData.payment_method)}</p>
              </div>
              <div className="row">
                <p className="col custum-job">Tình trạng thanh toán:</p>
                <p className="col custum-job-data">{translateIsPaid(jobData.is_paid)}</p>
              </div>
              <div className="row">
                <p className="col custum-job">Tên khách hàng:</p>
                <p className="col custum-job-data">{jobData.fullname}</p>
              </div>
              <div className="row">
                <p className="col custum-job">Số điện thoại:</p>
                <p className="col custum-job-data" >{jobData.phone}</p>
              </div>
              <div className="row">
                <p className="col custum-job">Ngày tạo:</p>
                <p className="col custum-job-data">{formatDate(jobData.created_at)}</p>
              </div>
              <div className="row">
                <p className="col custum-job">Địa chỉ làm việc:</p>
                <p className="col custum-job-data">{jobData.address}</p>
              </div>
              {jobData.Schedules[0].user_reason_cancel && (
                <div className="row">
                  <p className="col custum-job">Lý do hủy:</p>
                  <p className="col custum-job-data">{jobData.Schedules[0].user_reason_cancel}</p>
                </div>
              )}
              {jobData.note && (
                <div className="row">
                  <p className="col custum-job">Ghi chú:</p>
                  <p className="col custum-job-data">{jobData.note}</p>
                </div>
              )}
              {jobData.fee_go_to_market != null && (
                <div className="row">
                  <p className="col custum-job">Phí đi chợ hộ:</p>
                  <p className="col custum-job-data">{formatMoney(jobData.fee_go_to_market)}<sup>Đ</sup></p>
                </div>
              )}
              {jobData.fee_ironing_clothes != null && (
                <div className="row">
                  <p className="col custum-job">Phí ủi đồ:</p>
                  <p className="col custum-job-data">{formatMoney(jobData.fee_ironing_clothes)}<sup>Đ</sup></p>
                </div>
              )}
              {jobData.fee_tool != null && (
                <div className="row">
                  <p className="col custum-job">Phí dụng cụ vệ sinh:</p>
                  <p className="col custum-job-data">{formatMoney(jobData.fee_tool)}<sup>Đ</sup></p>
                </div>
              )}
              {jobData.fee_choose_partner != null && (
                <div className="row">
                  <p className="col custum-job">Phí chọn nhân viên:</p>
                  <p className="col custum-job-data">{formatMoney(jobData.fee_choose_partner)}<sup>Đ</sup></p>
                </div>
              )}
              {jobData.fee_vat != null && (
                <div className="row">
                  <p className="col custum-job">Phí VAT:</p>
                  <p className="col custum-job-data">{formatMoney(jobData.fee_vat)}<sup>Đ</sup></p>
                </div>
              )}
              <div className="row">
                <p className="col custum-job">Trạng thái:</p>
                <p className="col custum-job-data">{translateStatusJob(jobData.status)}</p>
              </div>
              {jobData.has_pet && (
                <div className="row">
                  <p className="col custum-job">Nhà có thú cưng:</p>
                  <p className="col custum-job-data">Có</p>
                </div>
              )}
            </div>
            {/*phần hiển thị danh sách lịch làm việc*/}
            <div className="table-responsive col-sm-7 mt-4 right-column">
              <h5 className='h5'>Lịch làm việc</h5>
              <div>
                <table className="table table-hover">
                  <thead>
                    <tr className="text-center">
                      <th>Buổi</th>
                      <th>Ngày làm việc</th>
                      <th>Thời gian làm việc</th>
                      <th>Trạng thái</th>
                      {schedules.some(schedule => schedule.partner_id) && <th>nhân viên làm viêc</th>}
                      {arrStatus.includes(jobData.status) && <th></th>}
                    </tr>
                  </thead>
                  <tbody>
                    {schedules.map((schedule, index) => (
                      <tr key={index} className="text-center">
                        {/* Display schedule data */}
                        <td>{index + 1}</td>
                        <td>{moment(schedule.start_time).format("DD-MM-YYYY")}</td>
                        <td>{`${moment(schedule.start_time).format("HH:mm")} - ${moment(
                          schedule.end_time
                        ).format("HH:mm")}`}</td>
                        <td>{translateStatusJob(schedule.status)}</td>
                        {schedule.partner_id ? (
                          <td>
                            <Link to={`/partner/${schedule.partner_id}`}>
                              {schedule.Partner.fullname}({schedule.Partner.id})
                            </Link>
                          </td>
                        ) : null}
                        {arrStatus.includes(jobData.status) && (
                          <td>
                            <Tooltip title="cập nhật nhân viên" placement="top">
                              {jobData.status !== STATUS_DONE && jobData.status !== STATUS_CANCEL ? (
                                <button
                                  type="button"
                                  className="btn btn-success"
                                  onClick={() => handleChangSelectStaffClick(schedule.partner_id)}
                                >
                                  Cập nhật
                                </button>
                              ) : null}
                            </Tooltip>
                          </td>
                        )}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              {/*phần hiển thị danh sách đi chợ hộ*/}
              {Foods.length > 0 && Market.name_market && (
                <div className="mt-4 pt-4">
                  <h5 className='h5' style={{ marginBottom: '20px' }}>Đi chợ hộ</h5>
                  <div className="col-sm-6">
                    <div className="row">
                      <p className="col custum-job">Nơi mua hàng:</p>
                      <p className="col custum-job-data">{Market.name_market}</p>
                    </div>
                  </div>
                  <table className="table table-hover" onKeyPress={handleEnterKeyPress}>
                    <thead>
                      <tr className='text-center'>
                        <th>STT</th>
                        <th>Tên sản phẩm</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Foods.map((Food, index) => (
                        <tr key={index} className='text-center'>
                          <td>{serialNumber + index}</td>
                          <td>{Food.name_product}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}

            </div>
            <div className="d-flex justify-content-center" style={{ marginTop: '40px' }}>
              {listStatus.includes(jobData.status) && (
                <div className="mx-3">
                  <button
                    type="button"
                    className="btn btn-danger custum-btn"
                    style={{ marginBottom: '30px' }}
                    onClick={handleCancelClick}
                  >
                    Hủy công việc
                  </button>
                </div>
              )}
              {jobData.status === 1 && (
                <div className="mx-3">
                  <button type="button" className="btn btn-success" onClick={handleSelectStaffClick}>
                    Chọn nhân viên
                  </button>
                </div>
              )}
            </div>

          </div >
        ) : (
          <LoadingSpinner />
        )
      ) : (
        <LoadingSpinner />
      )
      }
      <Modal show={showModal} onHide={handleCloseModal} backdrop="static" keyboard={false} className="modal-select-container">
        <Modal.Header closeButton>
          <Modal.Title>Hiển thị nhân viên đã chọn</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <FormControl
              fullWidth
              className="modal-select"
              style={{
                border: 'none',
                outline: 'none',
                boxShadow: 'none',
                position: 'relative',
                maxWidth: '100%',
              }}
            >
              {showLabel && (
                <InputLabel
                  id="select-partner"
                  className="custum-btn"
                  style={{
                    position: 'absolute',
                    top: '50%',
                    transform: 'translateY(-50%)',
                    left: 16,
                    pointerEvents: 'none',
                    zIndex: 1,
                    background: '#fff',
                  }}
                >

                </InputLabel>
              )}
              <Select
                labelId="partnerId"
                id="partnerId"
                style={{
                  border: 'none',
                  outline: 'none',
                  boxShadow: 'none',
                  appearance: 'none',
                  WebkitAppearance: 'none',
                  paddingLeft: 16,
                  width: '100%',
                }}
                onChange={handleSelectChange}
                value={partner_id}
              >
                {partnerList.map((partner, index) => (
                  <MenuItem key={index} value={partner.id}>
                    <div className="partner-item">
                      {partner.fullname} ({partner.id})
                    </div>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button type="button" class="btn btn-danger" onClick={handleCloseModal}>
            Hủy
          </button>
          <button type="button" class="btn btn-success" onClick={handleSelectButtonClick}>
            Chọn
          </button>
        </Modal.Footer>
      </Modal>
      {
        isConfirmingCancel && (
          <div className="confirmation-dialog">
            <span className="icon-close"><FiX onClick={handleCancelCancel} /></span>
            <p>Bạn có muốn hủy công việc này không?</p>
            <div>
              <button type="button" className="btn btn-success" onClick={handleCancelConfirm}>
                Đồng ý
              </button>
              <button type="button" className="btn btn-danger" onClick={handleCancelCancel}>
                Hủy
              </button>
            </div>
          </div>
        )
      }
      <Modal show={showModalChangeTask} onHide={handleChangeCloseModal} backdrop="static" keyboard={false} className="modal-select-container">
        <Modal.Header closeButton>
          <Modal.Title>Chọn lại nhân viên mới</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <FormControl
              fullWidth
              className="modal-select"
              style={{
                border: 'none',
                outline: 'none',
                boxShadow: 'none',
                position: 'relative',
                maxWidth: '100%',
              }}
            >
              {showLabelChangeTask && (
                <InputLabel
                  id="select-partner"
                  className="custum-btn"
                  style={{
                    position: 'absolute',
                    top: '50%',
                    transform: 'translateY(-50%)',
                    left: 16,
                    pointerEvents: 'none',
                    zIndex: 1,
                    background: '#fff',
                  }}
                >

                </InputLabel>
              )}
              <Select
                labelId="partnerId"
                id="partnerId"
                style={{
                  border: 'none',
                  outline: 'none',
                  boxShadow: 'none',
                  appearance: 'none',
                  WebkitAppearance: 'none',
                  paddingLeft: 16,
                  width: '100%',
                }}
                onChange={(event) => setSelectedNewStaffId(event.target.value)}
                value={selectedNewStaffId || ''}
              >
                {partnerListChange.map((partner, index) => (
                  <MenuItem key={index} value={partner.id}>
                    <div className="partner-item">
                      {partner.fullname} ({partner.id})
                    </div>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button class="btn btn-danger" onClick={handleChangeCloseModal}>
            Hủy bỏ
          </Button>
          <Button class="btn btn-success" onClick={handleChangeSendRequire}>
            Xác nhận
          </Button>
        </Modal.Footer>
      </Modal>
    </div >
  );
}

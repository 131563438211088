import { Axios } from "../utils/apiHelper";

// Lấy danh sách popup
const getAllPopups = async () => Axios.get(`/promotions/staff-get-list-image-popup`);

// Lấy chi tiết  popup
const getPopupDetail = async (popupId) => {
  try {
    const response = await Axios.get(`/promotions/staff-get-detail-image-popup/${popupId}`);
    return response.data.data;
  } catch (error) {
    throw new Error('Không thể lấy chi tiết của ảnh popup: ' + error.message);
  }
};

// Cập nhật  popup
const updatePopupImage = async (popupId, updatedData) => {
  try {
    const response = await Axios.put(`/promotions/staff-update-image-popup/${popupId}`, updatedData);
    return response.data.data;
  } catch (error) {
    throw new Error('Không thể cập nhật thông tin của ảnh popup: ' + error.message);
  }
};

// tạo mới  popup
const uploadPopupImage = async (uploadData) => {
  try {
    const response = await Axios.post(`/promotions/staff-upload-image-popup`, uploadData);
    return response.data.data;
  } catch (error) {
    throw new Error('Không thể tạo popup: ' + error.message);
  }
};

export { getAllPopups, getPopupDetail, updatePopupImage, uploadPopupImage };

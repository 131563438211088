import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { TextField } from '@mui/material';
import { FaCheck, FaTimes } from "react-icons/fa";
import FileInput from '../../../components/FileInput/FileInput';
import { uploadStaffFile } from '../../../services/promotion';
import Button from '@mui/material/Button';
import './newworkship.scss';
import No from '../../../assets/images/No.jpg'
export function AddWrokShips() {
  const [image, setImage] = useState(null);
  const handleImageChange = async (e) => {
    const file = e.target.files[0];

    if (file) {
      let fData = new FormData();
      fData.append("image", file);
      const resultUpload = await uploadStaffFile(fData);
      if (resultUpload.status === 200) {
        const url = resultUpload.data.url;
        setImage(url);
      }
    }
  };
  return (
    <div className="container-new-offting m-3">
      <div className="row justify-content-center">
        <div className='container container-blog-new-offting'>
          <h3 className='h3 text-center mt-3'>Thêm danh mục sản phẩm mới</h3>
          <div className='row'>
            <div className="col">
              <TextField
                id=""
                label={
                  <span>
                    Nhập tên danh mục <span className="red-asterisk">*</span>
                  </span>
                }
                variant="outlined"
                size="small"
                className="edit-text-field"
              />
            </div>
            <div className='col blog-img-wordship'>
              <Form.Group className="mb-3" controlId="image">
                <div className="image-container">
                  <img
                    src={image || No}
                    alt=""
                    className="d-block img-add-wordship"
                    width="300"
                    height="300"
                    style={{ maxWidth: '300', marginTop: '10px', maxHeight: '400', marginBottom: '20px' }}
                  />
                </div>
                <FileInput id="avataInput" text='Chọn ảnh' onChange={handleImageChange} />
              </Form.Group>
            </div>
            <div className='btn-new-offering d-flex justify-content-center'>
              <div className='btn-container'>
                <Button type="button" variant="contained" color="error" startIcon={<FaTimes />} style={{ marginRight: '10px', textTransform: 'math-auto' }}>
                  Hủy
                </Button>
                <Button type="button" variant="contained" color="success" startIcon={<FaCheck />} style={{ marginLeft: '10px', textTransform: 'math-auto' }}>
                  Tạo mới
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

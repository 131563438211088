import React, { useEffect, useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import Alert from '@mui/material/Alert';
import { TextField } from '@mui/material';
import { useParams } from 'react-router-dom';
import { getPopupDetail, updatePopupImage } from '../../services/popup';
import LoadingSpinner from '../../components/Loading/LoadingSpinner'
import FiledInput from '../../components/FileInput/FileInput';
import './style/DetailPopup.scss';

export function DetailPopup() {
  const { popupId } = useParams();
  const [popupData, setPopupData] = useState(null);
  const [newImageFile, setNewImageFile] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [isShow, setIsShow] = useState(false);
  const [dataObject, setDataObject] = useState(null);
  const [editeContent, setEditeContent] = useState('');
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const hideAlerts = () => {
    setShowSuccessAlert(false);
    setShowErrorAlert(false);
  };
  useEffect(() => {
    fetchPopupDetail();
  }, []);
  const fetchPopupDetail = async () => {
    try {
      const response = await getPopupDetail(popupId);
      setPopupData(response);
      setIsShow(response.is_show);
      setDataObject(response.data);
      setEditeContent(response.data);
    } catch (error) {
      console.error('Lỗi không thể hiện dữ liệu chi tiết của Popup:', error);
    }
  };
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setNewImageFile(file);

    const reader = new FileReader();
    reader.onloadend = () => {
      setImagePreview(reader.result);
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };
  const handleIsShowChange = (e) => {
    setIsShow(e.target.checked);
  };
  const handleContentChange = (e) => {
    setEditeContent(e.target.value);
  };
  const handleSave = async () => {
    try {
      const formData = new FormData();
      if (newImageFile) {
        formData.append('image', newImageFile);
      }
      formData.append('is_show', isShow);
      formData.append('data', editeContent);

      const response = await updatePopupImage(popupId, formData);
      setImagePreview(response.image);
      setNewImageFile(null);
      setShowSuccessAlert(false);
      setTimeout(() => {
        hideAlerts();
      }, 2000);
    } catch (error) {
      setShowErrorAlert(true);
      setTimeout(() => {
        hideAlerts();
      }, 2000);
      console.error('Lỗi không thể cập nhật dữ liệu:', error);
    }
  }
  return (
    <div className="container-deltail-popup m-3">
      <h3 className='h3 text-center mb-3'> Chi tiết Popup</h3>
      {showSuccessAlert && (
        <Alert
          variant="filled"
          severity="error"
          onClose={() => setShowErrorAlert(false)}
          className='custum-alert'
        >
          Lỗi khi cập nhật dữ liệu. Vui lòng thử lại sau.
        </Alert>
      )}
      {showErrorAlert && (
        <Alert
          variant="filled"
          severity="success"
          onClose={() => setShowSuccessAlert(false)}
          className='custum-alert'
        >
          Cập nhật thành công!
        </Alert>
      )}
      {popupData ? (
        <div className="row d-flex justify-content-center">
          <div className="col-md-6">
            <Form>
              {/*hiển thị thông tin popup*/}
              <Form.Group className="mb-3" controlId="id">
                <TextField
                  id="id"
                  name="id"
                  label="Nội dung của popup"
                  variant="outlined"
                  fullWidth
                  multiline
                  rows={6}
                  className="custom-textfield"
                  value={editeContent}
                  onChange={handleContentChange}
                />
              </Form.Group>
              <Form.Group controlId="formIsShow">
                <Form.Label style={{ marginRight: '10px' }}>Trang thái:</Form.Label>
                <div style={{ display: 'inline-block', marginRight: '20px' }}>
                  <Form.Switch
                    name="is_show"
                    checked={isShow}
                    onChange={handleIsShowChange}
                  />
                </div>
                <span
                  style={{
                    backgroundColor: isShow ? '#00ff62' : '#df2d05',
                    color: '#f9f8f8',
                    borderRadius: '8px',
                    border: `1px solid ${isShow ? '#00ff62' : '#df2d05'}`,
                    padding: '5px 10px',
                    fontSize: '13px',
                    fontWeight: 'bold',
                  }}
                >
                  {isShow ? 'Đang hoạt động' : 'Đã khóa'}
                </span>
              </Form.Group>
            </Form>
          </div>
          <div className="col-sm-6 d-flex justify-content-center">
            <div className="d-flex flex-column align-items-center">
              <Form.Group>
                <div className="d-flex flex-column align-items-center">
                  <img
                    src={imagePreview || popupData.image}
                    alt={`Popup ${popupData.id}`}
                    style={{ width: '100%', maxHeight: '300px' }}
                  />
                  <FiledInput id="avataInput" text='Chọn ảnh' onChange={handleImageChange} />
                </div>
              </Form.Group>
            </div>
          </div>
        </div>
      ) : (
        <LoadingSpinner />
      )}
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
        <Button variant="success" onClick={handleSave}>
          Cập nhật
        </Button>
      </div>
    </div>
  );
}
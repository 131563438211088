import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import moment from 'moment';
import numeral from "numeral";
import LoadingSpinner from '../../../components/Loading/LoadingSpinner'
import { BsXLg } from "react-icons/bs";
import {
  getDetailTransacTion,
  cancelTransacTion,
  conFirmTransacTion,
  conFirmWithdraw,
  cancelWithdraw
} from '../../../services/transaction';
import {
  DEPOSIT,
  WITHDRAW,
  INCREASE,
  DECREACSE
} from '../../../utils/constants'
import { FiX } from "react-icons/fi";
import './deltaitransaction.scss'

export function DelTaiTranSacTion() {
  const [modalImage, setModalImage] = useState(null);
  const [modalDisplay, setModalDisplay] = useState(false);

  const openModal = (imageSrc) => {
    setModalImage(imageSrc);
    setModalDisplay(true);
  };

  const closeModal = () => {
    setModalDisplay(false);
  };
  const [showPopup, setShowPopup] = useState(false);
  const { id } = useParams();
  const formatDate = (date) => { return moment(date).format('HH:mm:ss DD-MM-YYYY'); };
  const [transactionDetails, setTransactionDetails] = useState(null);
  const shouldShowButtons = (transactionDetails?.status === 1) && (transactionDetails?.transaction_type === 'deposit');
  const ShowButtonWithdraw = (transactionDetails?.status === 1) && (transactionDetails?.transaction_type === 'withdraw');
  const [showWithdrawConfirmPopup, setShowWithdrawConfirmPopup] = useState(false);
  const [showWithdrawCancelPopup, setShowWithdrawCancelPopup] = useState(false);
  const [showConfirmPopup, setShowConfirmPopup] = useState(false);
  const StatusTransaction = (status) => {
    switch (status) {
      case 1:
        return <span className="status-new">Mới</span>;
      case 2:
        return <span className="status-cancelled">Đã huỷ</span>;
      case 3:
        return <span className="status-done">Thành công</span>;
      default:
        return null;
    }
  };
  const TranslateTransactionType = (transaction_type) => {
    switch (transaction_type) {
      case 'increase':
        return INCREASE;
      case 'deposit':
        return DEPOSIT;
      case 'withdraw':
        return WITHDRAW;
      default:
        return DECREACSE;
    }
  };
  const handleCancelClick = () => {
    setShowPopup(true);
  };
  const handlePopupCancelClick = () => {
    setShowPopup(false);
  };
  const handleWithdrawConfirmClick = () => {
    setShowWithdrawConfirmPopup(true);
  };
  const handleWithdrawCancelClick = () => {
    setShowWithdrawCancelPopup(true);
  };
  const handleConfirmClick = () => {
    setShowConfirmPopup(true);
  };
  const handleWithdrawCancelPopupCancelClick = () => {
    setShowWithdrawCancelPopup(false);
  };
  const handleWithdrawConfirmPopupCancelClick = () => {
    setShowWithdrawConfirmPopup(false);
  };
  const handleConfirmPopupCancelClick = () => {
    setShowConfirmPopup(false);
  };
  useEffect(() => {
    getDetailTransacTion(id)
      .then(response => {
        setTransactionDetails(response.data.data);
      })
      .catch(error => {
        console.error("Lỗi không thể lấy chi tiết giao dịch:", error);
      });
  }, [id]);
  const handlePopupConfirmCancelClick = async () => {
    try {
      await cancelTransacTion(transactionDetails.id);
      setShowPopup(false);
      getDetailTransacTion(id).then(response => {
        setTransactionDetails(response.data.data)
      });
    } catch (error) {
      console.error("Không thể hủy giao dịch:", error);
    }
  };
  const handleConfirmPopupConfirmClick = async () => {
    try {
      await conFirmTransacTion(transactionDetails.id);
      setShowConfirmPopup(false);
      getDetailTransacTion(id).then(response => {
        setTransactionDetails(response.data.data)
      });
    } catch (error) {
      console.error("Lỗi không thể xác nhận giao dịch:", error);
    }
  };
  const handleWithdrawConfirmPopupConfirmClick = async () => {
    try {
      await conFirmWithdraw(transactionDetails.id);
      setShowWithdrawConfirmPopup(false);
      getDetailTransacTion(id).then(response => {
        setTransactionDetails(response.data.data);
      });
    } catch (error) {
      console.error("Lỗi không thể xác nhận rút tiền:", error);
    }
  };
  const handleWithdrawCancelPopupConfirmClick = async () => {
    try {
      await cancelWithdraw(transactionDetails.id);
      setShowWithdrawCancelPopup(false);
      getDetailTransacTion(id).then(response => {
        setTransactionDetails(response.data.data);
      });
    } catch (error) {
      console.error("Lỗi không thể hủy rút tiền:", error);
    }
  };
  if (!transactionDetails) {
    return <LoadingSpinner />
  }
  return (
    <div className="deltai-transaction m-3">
      <div className="row text-left responsive-row" style={{ padding: '10px 80px 0 80px' }}>
        <div className="col-sm-6 ">
          <div className="row">
            <div className="col-sm-12 ">
              <p className="consaction-title">Mã nạp tiền:</p>
              <p className="consaction-title">{transactionDetails ? transactionDetails.id : ''}</p>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 ">
              <p className="consaction-title">Trạng thái:</p>
              <p className="consaction-title">
                {transactionDetails ? StatusTransaction(transactionDetails.status) : ''}
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 ">
              <p className="consaction-title">Loại giao dịch:</p>
              <p className="consaction-title">
                {transactionDetails ? TranslateTransactionType(transactionDetails.transaction_type) : ''}
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 ">
              <p className="consaction-title">Ngày tạo giao dịch:</p>
              <p className="consaction-title">{transactionDetails ? formatDate(transactionDetails.created_at) : ''}</p>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 ">
              <p className="consaction-title">Nhân viên yêu cầu nạp tiền:</p>
              <p className="consaction-title fullname-bold">{transactionDetails ? transactionDetails.Partner.fullname : ''}
                ({transactionDetails ? transactionDetails.Partner.id : ''})</p>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 ">
              <p className="consaction-title">Số điện thoại của nhân viên:</p>
              <p className="consaction-title">{transactionDetails ? transactionDetails.Partner.phone : ''}</p>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 ">
              <p className="consaction-title">Số dư Trước giao dịch:</p>
              {transactionDetails.old_balance != null ? <p className="consaction-title consaction-money">{numeral(transactionDetails.old_balance).format("0,0")}<sup>đ</sup></p> : ''}
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 ">
              <p className="consaction-title">Số tiền giao dịch:</p>
              {transactionDetails.amount != null ? <p className="consaction-title consaction-money">{numeral(transactionDetails.amount).format("0,0")}<sup>đ</sup></p> : ''}
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 ">
              <p className="consaction-title">Số dư sau giao dịch:</p>
              {transactionDetails.new_balance != null ? <p className="consaction-title consaction-money">{numeral(transactionDetails.new_balance).format("0,0")}<sup>đ</sup></p> : ''}
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 ">
              <p className="consaction-title">Nội dung giao dịch:</p>
              <p className="consaction-title">{transactionDetails ? transactionDetails.content : ''}</p>
            </div>
          </div>
          {/**/}
          <div className="d-flex justify-content-center btn-transaction" style={{ marginTop: '30px' }}>
            <div >
              {shouldShowButtons && !showPopup && !showConfirmPopup && (
                <button
                  type="button"
                  className="btn btn-danger btn-transaction"
                  style={{ marginBottom: '30px' }}
                  onClick={handleCancelClick}
                >
                  Hủy Nạp Tiền
                </button>
              )}
            </div>
            <div >
              {shouldShowButtons && !showPopup && !showConfirmPopup && (
                <button
                  type="button"
                  className="btn btn-success btn-transaction"
                  onClick={handleConfirmClick}
                >
                  Xác Nhận Nạp Tiền
                </button>
              )}
            </div>
            <div >
              {ShowButtonWithdraw && !showWithdrawCancelPopup && !showWithdrawConfirmPopup && (
                <button
                  type="button"
                  className="btn btn-danger btn-transaction"
                  style={{ marginBottom: '30px' }}
                  onClick={handleWithdrawCancelClick}
                >
                  Hủy Rút Tiền
                </button>
              )}
            </div>
            <div >
              {ShowButtonWithdraw && !showWithdrawCancelPopup && !showWithdrawConfirmPopup && (
                <button
                  type="button"
                  className="btn btn-success btn-transaction"
                  onClick={handleWithdrawConfirmClick}
                >
                  Xác Nhận Rút Tiền
                </button>
              )}
            </div>
          </div>
          {/* hiển thị chọn hủy giao dịch*/}
          {showPopup && (
            <div className="popup-container">
              <span className="icon-close"><FiX onClick={handlePopupCancelClick} /></span>
              <p>Bạn muốn hủy giao dịch này?</p>
              <div className="popup-buttons">
                <button className="btn btn-success custum-btnmobile" onClick={handlePopupCancelClick}>
                  Không
                </button>
                <button className="btn btn-danger custum-btnmobile" onClick={handlePopupConfirmCancelClick}>
                  Có
                </button>
              </div>
            </div>
          )}
          {/* hiển thị xác giao dịch*/}
          {showConfirmPopup && (
            <div className="popup-container">
              <span className="icon-close"><FiX onClick={handleConfirmPopupCancelClick} /></span>
              <p>Bạn muốn xác nhận giao dịch này?</p>
              <div className="popup-buttons">
                <button className="btn btn-danger custum-btnmobile" onClick={handleConfirmPopupCancelClick}>
                  Không
                </button>
                <button className="btn btn-success custum-btnmobile" onClick={handleConfirmPopupConfirmClick}>
                  Xác nhận
                </button>
              </div>
            </div>
          )}
          {showWithdrawCancelPopup && (
            <div className="popup-container">
              <span className="icon-close">
                <FiX onClick={handleWithdrawCancelPopupCancelClick} />
              </span>
              <p>Bạn xác nhận muốn hủy rút tiền?</p>
              <div className="popup-buttons">
                <button
                  className="btn btn-danger custum-btnmobile"
                  onClick={handleWithdrawCancelPopupCancelClick}
                >
                  Không
                </button>
                <button
                  className="btn btn-success custum-btnmobile"
                  onClick={handleWithdrawCancelPopupConfirmClick}
                >
                  Có
                </button>
              </div>
            </div>
          )}
          {showWithdrawConfirmPopup && (
            <div className="popup-container">
              <span className="icon-close">
                <FiX onClick={handleWithdrawConfirmPopupCancelClick} />
              </span>
              <p>Bạn muốn xác nhận rút tiền?</p>
              <div className="popup-buttons">
                <button
                  className="btn btn-danger custum-btnmobile"
                  onClick={handleWithdrawConfirmPopupCancelClick}
                >
                  Hủy
                </button>
                <button
                  className="btn btn-success  custum-btnmobile"
                  onClick={handleWithdrawConfirmPopupConfirmClick}
                >
                  Xác nhận
                </button>
              </div>
            </div>
          )}
        </div>
        <div className="col-sm-6">
          <img
            src={transactionDetails ? transactionDetails.image_bill : ''}
            alt=""
            maxwidth="300"
            height="400"
            className="img-bill-mobile"
            onClick={() => openModal(transactionDetails ? transactionDetails.image_bill : '')}
          />
        </div>
      </div>
      {modalDisplay && (
        <div className="modal">
          <span className="close" onClick={closeModal}>
            <BsXLg />
          </span>
          <div className="relative__modal">
            <img className="modal-content image" src={modalImage} alt="Modal" style={{ objectFit: 'cover' }} />
          </div>
        </div>
      )}
    </div>
  );
}

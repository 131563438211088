import { Link } from "react-router-dom";

export default function TabStore() {
  return (
    <ul className="nav nav-tabs flex-column">
      <li className="nav-item">
        <Link className="nav-link" data-bs-toggle="tab" to=''>cửa hàng tiện lợi Ting 107 </Link>
      </li>
      <li className="nav-item">
        <Link className="nav-link" data-bs-toggle="tab" to=''>cửa hàng tiện lợi Ting 107 </Link>
      </li>
      <li className="nav-item">
        <Link className="nav-link" data-bs-toggle="tab" to=''>cửa hàng tiện lợi Ting 107 </Link>
      </li>
      <li className="nav-item">
        <Link className="nav-link" data-bs-toggle="tab" to=''>cửa hàng tiện lợi Ting 107 </Link>
      </li>
    </ul>
  )
}
import React, { useState, useRef, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import Alert from '@mui/material/Alert';
import { TextField } from '@mui/material';
import { createNewBlogs } from '../../../services/blog';
import { uploadStaffFile } from '../../../services/promotion';
import { Editor } from '@tinymce/tinymce-react';
import FileInput from '../../../components/FileInput/FileInput';
import './addblog.scss';

export function AddBlog() {
  const isMountedRef = useRef(true);
  const [title, setTitle] = useState("");
  const [content, setcontent] = useState("");
  const [description, setdescription] = useState("");
  const [is_active, setIsActive] = useState(true);
  const [image, setBlogImage] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');
  const [create, setCreate] = useState(false);
  const handleTitleChange = (event) => setTitle(event.target.value);
  const handlecontentChange = (event) => setcontent(event.target.value);
  const handledescriptionChange = (event) => setdescription(event.target.value);
  const [error, setError] = useState(null);
  const [validationErrors, setValidationErrors] = useState({});
  const handleIsActiveChange = (e) => {
    setIsActive(e.target.checked);
  }
  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      try {
        const resizeImage = (file) => {
          return new Promise((resolve) => {
            const reader = new FileReader();
            reader.onload = (event) => {
              const img = new Image();
              img.src = event.target.result;
              img.onload = () => {
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');
                const maxWidth = 800;
                const maxHeight = 800;

                let width = img.width;
                let height = img.height;

                if (width > height) {
                  if (width > maxWidth) {
                    height *= maxWidth / width;
                    width = maxWidth;
                  }
                } else {
                  if (height > maxHeight) {
                    width *= maxHeight / height;
                    height = maxHeight;
                  }
                }
                canvas.width = width;
                canvas.height = height;
                ctx.drawImage(img, 0, 0, width, height);
                canvas.toBlob((blob) => {
                  resolve(new File([blob], file.name, { type: blob.type }));
                }, file.type);
              };
            };
            reader.readAsDataURL(file);
          });
        };
        const resizedFile = await resizeImage(file);
        let fData = new FormData();
        fData.append("image", resizedFile);
        const resultUpload = await uploadStaffFile(fData);
        if (resultUpload.status === 200) {
          const url = resultUpload.data.url;
          setBlogImage(url);
        }
      } catch (error) {
        console.error("Lỗi không thể tải ảnh lên được:", error);
      }
    }
  };
  const handleBlur = (e) => {
    const { name, value } = e.target;

    if (!value) {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        [name]: 'Vui lòng điền dữ liệu!',
      }));
    } else {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        [name]: null,
      }));
    }
  };
  useEffect(() => {
    return () => {
      isMountedRef.current = false;
    };
  }, []);
  //sự kiện ẩn thông báo
  const closeAlerts = () => {
    setTimeout(() => {
      setSuccessMessage('');
      setError(null);
    }, 1500);
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      setCreate(true);
      const errors = {};
      let hasError = false;

      if (!title) {
        errors.title = "Tiêu đề không được để trống!";
        hasError = true;
      }
      if (!description) {
        errors.description = "Mô tả không được để trống!";
        hasError = true;
      }
      if (!content) {
        errors.content = "Nội dung không được để trống";
        hasError = true;
      }
      if (!image) {
        errors.image = "Ảnh blog không được để trống";
        hasError = true;
      }
      if (hasError) {
        setValidationErrors(errors);
        return;
      }
      const data = {
        title,
        content,
        description,
        image,
        is_active,
      };
      await createNewBlogs(data);
      setSuccessMessage('Tạo mới thành công!');
      window.scrollTo(0, 0);
      closeAlerts();
    } catch (error) {
      setError('Tạo mới thất bại!');
      window.scrollTo(0, 0);
      closeAlerts();
      console.error('Lỗi không thể tạo Blog mới:', error);
    } finally {
      setCreate(false);
    }
  };
  return (
    <Form onSubmit={handleSubmit} className='container-create-blog' style={{ margin: '20px' }} >
      <h3 className='h3 text-center' style={{ marginBottom: '40px' }}>Tạo Blog mới</h3>
      {successMessage && (
        <Alert
          variant="filled" severity="success"
          onClose={() => setSuccessMessage('')}
        >
          Bạn đã tạo mới blog thành công!
        </Alert>
      )}
      {error && (
        <Alert variant="filled" severity="error"
          onClose={() => setError('')}>
          Rất tiếc bạn đã tạo mới Blog thất bại mất rồi, hãy thử lại nào!
        </Alert>
      )}
      <div className='row'>
        <div className='col-sm-6'>
          {/* Tiêu đề */}
          <Form.Group className="mb-3" controlId="title">
            <TextField
              id="title"
              name="title"
              label={
                <span>
                  Tiêu đề <span className="red-asterisk">*</span>
                </span>
              }
              variant="outlined"
              size="small"
              className="custom-textfield"
              value={title}
              onChange={handleTitleChange}
              onBlur={handleBlur}
            />
            {validationErrors.title && <span style={{ color: 'red' }}>{validationErrors.title}</span>}
          </Form.Group>
          {/* Mô tả */}
          <Form.Group className="mb-3" controlId="description">
            <TextField
              id="description"
              name="description"
              label={
                <span>
                  Mô tả <span className="red-asterisk">*</span>
                </span>
              }
              variant="outlined"
              fullWidth
              multiline
              minRows={5}
              className="custom-textfield"
              value={description}
              onChange={handledescriptionChange}
              onBlur={handleBlur}
            />

            {validationErrors.description && <span style={{ color: 'red' }}>{validationErrors.description}</span>}
          </Form.Group>
          {/* nội dung blog */}
          <Form.Group className="mb-3" controlId="content">
            <Form.Label>Nội dung <span style={{ color: 'red' }}>*</span></Form.Label>

            <p style={{ color: 'red' }}>
              <strong style={{ textDecoration: 'underline' }}>Ghi chú:</strong> khi viết song nhớ copy nội dung vừa viết rồi chọn chữ là màu đen!
            </p>
            <Editor
              apiKey='00v9uql5l6aaerb4fcev4roo2porrtzammbxsbghcmo7lm22'
              value={content}
              onEditorChange={(content) =>
                handlecontentChange({ target: { name: 'content', value: content } }
                )}
              init={{
                plugins: 'link image lists textcolor',
                toolbar: 'undo redo | formatselect | bold italic underline strikethrough | alignleft aligncenter alignright | bullist numlist outdent indent | forecolor backcolor | image',
                content_style: `body { color: black !important; line-height: 1.4; }h1, h2, h3, h4, h5, h6, p {margin: 0; height: auto;} `
              }} />
            {validationErrors.content && (
              <span style={{ color: 'red' }}>{validationErrors.content}</span>
            )}
          </Form.Group>
          {/* Trạng thái */}
          <Form.Group className="mb-2" controlId="is_active">
            <Form.Label style={{ marginRight: '10px' }}>Trạng thái:</Form.Label>
            <div style={{ display: 'inline-block', marginRight: '20px' }}>
              <Form.Switch
                name="is_active"
                checked={is_active}
                onChange={handleIsActiveChange}
              />
            </div>
            <span
              style={{
                backgroundColor: is_active ? '#00FF00' : '#FF0000',
                color: '#FFFFFF',
                borderRadius: '8px',
                border: `1px solid ${is_active ? '#00FF00' : '#FF0000'}`,
                padding: '5px 10px',
                fontSize: '13px',
                fontWeight: 'bold',
              }}
            >
              <label className={`form-check-label ${is_active ? 'btn-true' : 'btn-false'}`} htmlFor="isActiveSwitch">
                {is_active ? 'Hoạt động' : 'Đã khóa'}
              </label>
            </span>
          </Form.Group>
        </div>
        <div className='col-sm-6'>
          {/* Ảnh blog */}
          <Form.Group>
            <div className="d-flex flex-column align-items-center">
              <Form.Label>Ảnh Blog<span style={{ color: 'red' }}>*</span></Form.Label>
              {image && (
                <img
                  src={image}
                  alt=""
                  style={{ width: "100%", objectFit: "cover" }}
                />
              )}
              <FileInput id="avataInput" text='Tải ảnh blog' onChange={handleImageChange} />
              {!image && validationErrors.image && (
                <span style={{ color: 'red' }}>{validationErrors.image}</span>
              )}
            </div>
          </Form.Group>
        </div>
      </div>
      {/* Button Thêm */}
      <div className="d-flex justify-content-center mt-3" style={{ marginBottom: '30px' }}>
        <button className="btn btn-success">
          {create ? "Đang tạo mới..." : "Tạo mới"}
        </button>
      </div>
    </Form >
  );
}

import React from 'react';
import './Loading.scss'
function LoadingSpinner() {
  return (
    <div className="container-loading">
      <span className=""></span>
      <div className="line">
        <div className="inner"></div>
      </div>
    </div>
  );
}

export default LoadingSpinner;

import moment from 'moment';
import numeral from 'numeral';
import { GetCommission } from '../../services/commission';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  InputLabel,
  MenuItem,
  Select,
  FormControl,
  TextField,
} from '@mui/material';
import {
  DEPOSIT,
  WITHDRAW,
  INCREASE,
  DECREACSE
} from '../../utils/constants';
import LoadingSpinner from '../../components/Loading/LoadingSpinner';
import Pagination from '../../components/Pagination/Pagination';
import { Form } from 'react-bootstrap';
import './listcommission.scss'

export function ListCommistion() {
  const [status, setStatus] = useState('');
  const [transaction_type, settransaction_type] = useState('');
  const [transaction_id, setTransactionId] = useState('');
  const [user_id, setUserId] = useState('');
  const [created_at, setCreatedAt] = useState('');
  const [loading, setLoading] = useState(true);
  const [data, setTransactions] = useState([]);
  const [totalPage, setTotalPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [TransacctionPerPage] = useState(20);
  const [noResultsFound, setNoResultsFound] = useState(false);

  const dateNow = new Date();
  const year = dateNow.getFullYear();
  const monthWithOffset = dateNow.getMonth() + 1;
  const month = monthWithOffset.toString().padStart(2, '0');
  const date = dateNow.getDate().toString().padStart(2, '0');
  const materialDateInput = `${year}-${month}-${date}`;

  const formatMoney = (value) => { return numeral(value).format('0,0'); };
  const formatDate = (date) => { return moment(date).format('YYYY-MM-DD'); };
  const handleStatusChange = (event) => { setStatus(event.target.value); };
  const handleTransactionTypeChange = (event) => { settransaction_type(event.target.value); };
  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
    window.scrollTo({ top: 0, behavior: 'smooth' });
    fetchData();
  };
  const handleDateCreatedChange = (event) => {
    const selectedDate = event.target.value;
    const formattedDate = moment(selectedDate).format('YYYY-MM-DD');
    setCreatedAt(formattedDate);
  };
  const translateStatusTransaction = (status) => {
    switch (status) {
      case 1:
        return <span className="status-new">Mới</span>;
      case 2:
        return <span className="status-cancelled">Đã huỷ</span>;
      case 3:
        return <span className="status-done">Thành công</span>;
      default:
        return null;
    }
  };
  const TranslateTransactionType = (transaction_type) => {
    switch (transaction_type) {
      case 'increase':
        return INCREASE;
      case 'deposit':
        return DEPOSIT;
      case 'withdraw':
        return WITHDRAW;
      default:
        return DECREACSE;
    }
  };
  const handleTransactionIdChange = (event) => {
    let transactionId = event.target.value;
    setTransactionId(transactionId);
  }
  const handlePartnerIdChange = (event) => {
    let partnerId = event.target.value;
    setUserId(partnerId);
  }
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };
  const handleSearch = async () => {
    setCurrentPage(1);
    await fetchData();
  };
  const fetchData = async () => {
    try {
      const params = {
        transaction_type: transaction_type,
        user_id: user_id,
        transaction_id: transaction_id,
        current_page: currentPage,
        limit: TransacctionPerPage,
        status: status ? parseInt(status, 6) : '',
        created_at: created_at,
      }

      const response = await GetCommission(params);
      const data = response.data.data;
      if (data.length === 0) {
        setNoResultsFound(true);
      } else {
        setNoResultsFound(false);
      }
      setTransactions(data);
      setTotalPage(response.data.total_page);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error('Lỗi khi tìm dữ liệu:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [currentPage]);
  // phần giao diện
  return (
    <div className="container-commission">
      <div className='container'>
        <h3 className="h3">Danh sách giao dịch</h3>
        <div className='container-job-filter text-center' style={{ marginTop: '60px' }}>
          <div className="row ">
            {/* mã giao dịch */}
            <div className="col-sm-2">
              <TextField
                id="transaction_id"
                label="Mã Giao dịch"
                variant="outlined"
                className='custum-rpsive-transaction'
                size="small"
                value={transaction_id}
                onChange={handleTransactionIdChange}
                onKeyDown={handleKeyDown}
              />
            </div>
            <div className="col-sm-2">
              <TextField
                id="transaction_id"
                label="Mã khách hàng"
                variant="outlined"
                size="small"
                className='custum-rpsive-transaction'
                value={user_id}
                onChange={handlePartnerIdChange}
                onKeyDown={handleKeyDown}
              />
            </div>
            {/* ngày tạo */}
            {/* Trạng thái giao dịch */}
            <div className='col-sm-2'>
              <FormControl fullWidth >
                <InputLabel id="status">Trạng thái giao dịch</InputLabel>
                <Select
                  labelId="status"
                  id="status"
                  label="Trạng thái giao dịch"
                  size="small"
                  value={status}
                  onChange={handleStatusChange}
                  className='custum-rpsive-transaction'
                >
                  <MenuItem value={1}>Mới</MenuItem>
                  <MenuItem value={2}>Huỷ</MenuItem>
                  <MenuItem value={3}>Thành công</MenuItem>
                </Select>
              </FormControl>
            </div>
            {/* Loại giao dịch */}
            <div className='col-sm-2'>
              <FormControl fullWidth >
                <InputLabel id="transaction_type">Loại giao dịch</InputLabel>
                <Select
                  labelId="transaction_type"
                  id="transaction_type"
                  label="Loại giao dịch "
                  size="small"
                  value={transaction_type}
                  onChange={handleTransactionTypeChange}
                  className='custum-rpsive-transaction'
                >
                  <MenuItem value='deposit'>{DEPOSIT}</MenuItem>
                  <MenuItem value='increase'>{INCREASE}</MenuItem>
                  <MenuItem value='decrease'>{DECREACSE}</MenuItem>
                  <MenuItem value='withdraw'>{WITHDRAW}</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="col-sm-2">
              <Form.Group className="mb-2" controlId="created_at">
                <TextField
                  id="fromDate"
                  name="created_at"
                  label="Ngày tạo"
                  type="date"
                  size="small"
                  className="custom-textfield custum-rpsive-transaction"
                  defaultValue={materialDateInput}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={created_at}
                  onChange={handleDateCreatedChange}
                />
              </Form.Group>
            </div>
            {/* tìm kiếm */}
            <div className='col-sm-2'>
              <button type="button"
                className="btn btn-outline-success custum-rpsive-transaction"
                onClick={handleSearch}
              >
                Tìm kiếm
              </button>
            </div>
          </div>
        </div>
        <div>
          {loading ? (
            <LoadingSpinner />
          ) : (
            noResultsFound ? (
              <h3 className="text-center h3 search-results" style={{ marginTop: '50px' }}>Không tìm thấy giao dịch</h3>
            ) : (
              <div className='cart-table table-responsive'>
                <table className="table table-prodcut table-sm table-hover">
                  <thead>
                    <tr className='text-center'>
                      <th>Mã Giao dịch</th>
                      <th>Loại giao dịch</th>
                      <th>Khách hàng</th>
                      <th>Tiền giao dịch</th>
                      <th className='d-none d-sm-table-cell'>Ngày tạo giao dịch</th>
                      <th>Trạng thái</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((element, index) => (
                      <tr key={index} className='text-center'>
                        <td>
                          <Link to={`/commission/${element.id}`}>{element.id}</Link>
                        </td>
                        <td>{TranslateTransactionType(element.transaction_type)}</td>
                        <td>
                          <Link to={`/user/${element.user_id}`} className='custum-td-job'>
                            {element.User.fullname}({element.user_id})
                          </Link>
                        </td>
                        <td>
                          {formatMoney(element.amount)}<sup>đ</sup>
                        </td>
                        <td className='d-none d-sm-table-cell'>{formatDate(element.created_at)}</td>
                        <td>{translateStatusTransaction(element.status)}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )
          )}
        </div>
        {
          totalPage > 1 && (
            <Pagination totalPage={totalPage} currentPage={currentPage} handleClick={handlePageClick} />
          )
        }
      </div>
    </div >
  )

}
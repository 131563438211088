import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { getListPartner } from '../../../services/partner';
import AddButon from '../../../components/Button/AddButton';
import LoadingSpinner from '../../../components/Loading/LoadingSpinner';
import Pagination from '../../../components/Pagination/Pagination';
import { useAuth } from '../../../hooks/useAuth';
import { TextField } from '@mui/material';
import './listPartner.scss';

export function ListPartner() {
    const { user } = useAuth();
    const [partners, setPartners] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [partnersPerPage,] = useState(20);
    const [totalPage, setTotalPage] = useState(0);
    const [searchPhone, setSearchPhone] = useState('');
    const [loading, setLoading] = useState(true);
    const [ErrorSearch, setErrorSearch] = useState(false);
    useEffect(() => {
        fetchPartners();
    }, [currentPage]);
    const fetchPartners = async () => {
        try {
            const response = await getListPartner({
                phone: searchPhone,
                current_page: currentPage,
                limit: partnersPerPage,
            });
            const data = response.data.data;
            if (data.length === 0) {
                setErrorSearch(true);
            } else {
                setErrorSearch(false);
            }
            setPartners(data);
            setTotalPage(response.data.total_page);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.error('Lỗi, không tìm thấy dữ liệu:', error);
        }
    };

    const handleInputChange = (event) => {
        setSearchPhone(event.target.value);
    };
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleSearch();
        }
    };
    const handleSearch = () => {
        setCurrentPage(1);
        fetchPartners();
    };

    const handlePageClick = (pageNumber) => {
        setCurrentPage(pageNumber);
        window.scrollTo({ top: 0, behavior: 'smooth' });
        fetchPartners();
    };

    const translateTypePartner = (type) => {
        switch (type) {
            case 'cleaner':
                return 'Nhân viên vệ sinh';
            case 'delivery':
                return 'Nhân viên giao hàng';
            case 'collector':
                return 'Nhân viên thu gom';
            default:
                return 'Default';
        }
    };
    const getStatusClass = (isActive) => {
        return isActive ? 'btn-active' : 'btn-inactive';
    };
    return (
        <div className="container-partner">
            <div className='container'>
                <h3 className="h3">Danh sách nhân viên</h3>
                <div className='btn-add-staff'>
                    <Link to='/partner/create_partner'>
                        <AddButon text='Thêm nhân viên' />
                    </Link>
                    <div class="col-sm-7 mb-3">
                        <div className='row d-flex '>
                            <div class="col-sm-4">
                                <TextField
                                    type='number'
                                    id="phone"
                                    label="Số điện thoại"
                                    variant="outlined"
                                    size="small"
                                    value={searchPhone}
                                    onChange={handleInputChange}
                                    onKeyDown={handleKeyDown}
                                />
                            </div>
                            <div className='col-sm-3  col-sm-2'>
                                <button type="button"
                                    class="btn btn-outline-success pe-auto"
                                    onClick={handleSearch}>
                                    Tìm kiếm
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='container-partner-filter text-center mb-4'>
                    {loading ? (
                        <LoadingSpinner />
                    ) : (
                        ErrorSearch ? (
                            <h3 className="text-center h3 error-search" style={{ marginTop: '50px' }}>Nhân viên không tồn tại</h3>
                        ) : (
                            <div className='cart-table table-responsive'>
                                <table className="table table-hover">
                                    <thead>
                                        <tr className='text-center'>
                                            <th>Mã nhân viên</th>
                                            <th>Tên nhân viên</th>
                                            <th>Công việc</th>
                                            <th>Số điện thoại</th>
                                            <th>Số CCCD/CMND</th>
                                            <th>Ngày sinh</th>
                                            <th>Trạng thái</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {partners.map((partner, index) => (
                                            <tr key={partner.id} className='text-center'>
                                                <td>
                                                    <Link to={`/partner/${partner.id}`}>{partner.id}</Link>
                                                </td>
                                                <td >{partner.fullname}</td>
                                                <td>{translateTypePartner(partner.type_partner)}</td>
                                                <td>{partner.phone}</td>
                                                <td>{partner.id_card_number}</td>
                                                <td>{partner.dob.slice(0, 10)}</td>
                                                <td>
                                                    <button className={getStatusClass(partner.is_active)}>
                                                        {partner.is_active ? 'Đang hoạt động' : 'Bị khóa'}
                                                    </button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        )
                    )}
                    {totalPage > 1 && (
                        <Pagination totalPage={totalPage} currentPage={currentPage} handleClick={handlePageClick} />
                    )}
                </div>
            </div>
        </div>
    );
}

import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import Alert from '@mui/material/Alert';
import { useParams } from 'react-router-dom';
import numeral from 'numeral';
import FileInput from '../../components/FileInput/FileInput';
import MultipleFileInput from '../../components/FileInput/MultipleFileInput';
import LoadingSpinner from '../../components/Loading/LoadingSpinner'
import CustomDateTimePicker from './TimePicker/CustomDateTimePicker';
import {
  getPromotionById,
  putUpdatePromotion,
  uploadStaffFile
} from '../../services/promotion';
import './style/updatepromotion.scss';
// import { stringify } from 'qs';
export function UpdatePromotion() {
  const formatMoney = (value) => numeral(value).format('0,0')
  const [bannerImagePreview, setBannerImagePreview] = useState(null);
  const [largeImageError, setLargeImageError] = useState(false);
  const [bannerImageError, setBannerImageError] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const [error, setError] = useState(null);
  const [rule, setRule] = useState(null);
  const [loading, setLoading] = useState(true);
  const handleBlur = (e) => {
    const { name, value } = e.target;
    if (!value) {
      setValidationErrors({ ...validationErrors, [name]: 'Vui lòng điền dữ liệu!' });
    } else {
      setValidationErrors({ ...validationErrors, [name]: null });
    }
  };
  const handleImageChange = async (e) => {
    const file = e.target.files[0];

    if (file) {
      let fData = new FormData();
      fData.append("image", file);
      const resultUpload = await uploadStaffFile(fData);
      if (resultUpload.status == 200) {
        const url = resultUpload.data.url;
        setBannerImagePreview(url);
        setFormData({ ...formData, image: url });
      }
    }
  };
  const handleToggleChange = (e) => {
    setFormData({ ...formData, is_active: e.target.checked });
  };
  const handnumberChange = (e) => {
    const { name, value } = e.target;
    if (!Number.isInteger(Number(value)) || Number(value) <= 0) {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        [name]: 'Vui lòng chỉ nhập số nguyên dương lớn hơn 0.',
      }));
    } else {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        [name]: null,
      }));
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };
  const [successMessage, setSuccessMessage] = useState('');
  const [detailImages, setDetailImages] = useState([]);
  const [detailImageFiles, setDetailImageFiles] = useState([]);
  const handleDetailImagesChange = (e) => {
    const files = e.target.files;
    if (files && files.length > 0) {
      setDetailImageFiles(Array.from(files));
      const urls = Array.from(files).map((file) => URL.createObjectURL(file));
      setDetailImages(urls);
    }
  };
  // Xóa ảnh chi tiết
  const handleRemoveImage = (index) => {
    const updatedImages = detailImages.filter((_, i) => i !== index);
    const updatedFiles = detailImageFiles.filter((_, i) => i !== index);
    setDetailImages(updatedImages);
    setDetailImageFiles(updatedFiles);
    const updatedFormData = { ...formData };
    updatedFormData.detail_images = updatedFiles;
    setFormData(updatedFormData);
  };
  const { promotionId } = useParams();
  const [formData, setFormData] = useState({
    title: '',
    code: '',
    type: '',
    type_discount: '',
    discount: '',
    category: '',
    wogi_id: '',
    description: '',
    brand: '',
    point: '',
    rule: '',
    is_active: true,
    time_from: new Date(),
    time_to: new Date(),
    image: null,
    image_thumbnail: [],
  });
  useEffect(() => {
    fetchPromotionDetail(promotionId);
  }, [promotionId]);
  const fetchPromotionDetail = async (promotionId) => {
    try {
      const response = await getPromotionById(promotionId);
      const promotionData = response.data.data;
      let rules = promotionData.rule.rules;
      let ruleString = '';
      for (let i = 0; i < rules.length; i++) {
        ruleString += rules[i];
        ruleString += "\n";
      }
      setRule(ruleString);
      const imageThumbnail = promotionData.image_thumbnail || [];
      setFormData({
        title: promotionData.title,
        code: promotionData.code,
        type: promotionData.type,
        type_discount: promotionData.type_discount,
        discount: promotionData.discount,
        category: promotionData.category,
        wogi_id: promotionData.wogi_id,
        description: promotionData.description,
        brand: promotionData.brand,
        point: promotionData.point,
        image: promotionData.image,
        image_thumbnail: JSON.stringify(imageThumbnail),
        rule: JSON.stringify(promotionData.rule),
        is_active: promotionData.is_active,
        time_from: new Date(promotionData.time_from),
        time_to: new Date(promotionData.time_to),
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error('Lỗi, không thể lấy thông tin chi tiết khuyến mãi:', error);
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    const updatedValue = name === 'code' ? value.toUpperCase() : value;
    setFormData({ ...formData, [name]: updatedValue });
  };

  const handleChangeRule = (e) => {
    const value = e.target.value;
    setRule(value);
    const ruleObject = { rules: value.trim().split('\n') };
    setFormData({
      ...formData,
      rule: JSON.stringify(ruleObject)
    });
  }
  const closeAlerts = () => {
    setTimeout(() => {
      setSuccessMessage('');
      setError(null);
    }, 2000);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await putUpdatePromotion(promotionId, formData);
      setSuccessMessage('Cập nhật thành công!');
      window.scrollTo(0, 0);
      closeAlerts();
    } catch (error) {
      setError('Cập nhật thất bại!');
      window.scrollTo(0, 0);
      closeAlerts();
    }
  };
  const handleStartDateChange = (date) => {
    setFormData({ ...formData, time_from: date });
  };
  const handleEndDateChange = (date) => {
    setFormData({ ...formData, time_to: date });
  };
  return (
    <Form onSubmit={handleSubmit} className='container-promotion-update' style={{ margin: '20px' }}>
      {/* Thông báo thành công */}
      {successMessage && (
        <Alert
          variant="filled" severity="success"
          onClose={() => setSuccessMessage('')}
          className="custum-alert"
        >
          Cập nhật thành công!
        </Alert>
      )}
      {/* Error alert */}
      {error && (
        <Alert variant="filled" severity="error"
          onClose={() => setError('')}
          className="custum-alert">
          Cập nhật thất bại!
        </Alert>
      )}
      {loading ? (
        <LoadingSpinner />
      ) : (
        <div className='row'>
          <div className='col-sm-6'>
            {/*hiển thị Tiêu đề */}
            <Form.Group className="mb-3" controlId="title">
              <Form.Label>Tiêu đề<span style={{ color: 'red' }}>*</span></Form.Label>
              <Form.Control
                type="text"
                name="title"
                required
                maxLength={100}
                value={formData.title}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {formData.title.length > 100 && (
                <div style={{ color: 'red' }}>
                  Vượt quá giới hạn ký tự: {formData.title.length - 100} ký tự
                </div>
              )}
              {validationErrors.title && <span style={{ color: 'red' }}>{validationErrors.title}</span>}
            </Form.Group>
            <div className='row'>
              <div className='col-sm-6'>
                {/*hiển thị Mã khuyến mãi */}
                <Form.Group className="mb-3" controlId="code">
                  <Form.Label>Mã khuyến mãi</Form.Label>
                  <Form.Control
                    type="text"
                    name="code"
                    required
                    value={formData.code?.toUpperCase() || 'CODE'}
                    readOnly
                  />
                </Form.Group>
              </div>
              <div className='col-sm-6'>
                {/*hiển thị Phân loại */}
                <Form.Group className="mb-3" controlId="type">
                  <Form.Label>Phân loại<span style={{ color: 'red' }}>*</span></Form.Label>
                  <Form.Select
                    name="type"
                    value={formData.type}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    required
                  >
                    <option value="">-- Chọn phân loại --</option>
                    <option value="in-app">Ting ưu đãi</option>
                    <option value="3rd">Đối tác bên ngoài</option>
                  </Form.Select>
                  {validationErrors.type && <span style={{ color: 'red' }}>{validationErrors.type}</span>}
                </Form.Group>
              </div>
            </div>
            <div className='row'>
              <div className='col-sm-6'>
                {/*hiển thị Loại giảm giá */}
                <Form.Group className="mb-3" controlId="type_discount">
                  <Form.Label>Loại giảm giá <span style={{ color: 'red' }}>*</span></Form.Label>
                  <Form.Select
                    name="type_discount"
                    value={formData.type_discount}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    required
                  >
                    <option value="">-- Chọn Loại giảm giá --</option>
                    <option value="percent">Phần %</option>
                    <option value="money">Tiền</option>
                  </Form.Select>
                  {validationErrors.type_discount && <span style={{ color: 'red' }}>{validationErrors.type_discount}</span>}
                </Form.Group>
              </div>

              <div className='col-sm-6'>
                {/*hiển thị Giảm giá */}
                <Form.Group className="mb-3" controlId="discount">
                  <Form.Label>Giảm giá <span style={{ color: 'red' }}>*</span></Form.Label>
                  <div className="input-group">
                    <Form.Control
                      type="text"
                      name="discount"
                      required
                      value={formData.type_discount === 'percent' ? formatMoney(formData.discount) : formatMoney(formData.discount)}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {formData.type_discount && (
                      <div className="input-group-append">
                        <span className="input-group-text">
                          {formData.type_discount === 'percent' ? '%' : 'đ'}
                        </span>
                      </div>
                    )}
                  </div>
                  {validationErrors.discount && (
                    <span style={{ color: 'red' }}>{validationErrors.discount}</span>
                  )}
                </Form.Group>
              </div>
            </div>
            <div className='row'>
              <div className='col-sm-6'>
                {/*hiển thị Danh mục */}
                <Form.Group className="mb-3" controlId="category">
                  <Form.Label>Danh mục <span style={{ color: 'red' }}>*</span></Form.Label>
                  <Form.Select
                    name="category"
                    value={formData.category}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    required
                  >
                    <option value="">-- Chọn danh mục --</option>
                    <option value="hot">Ưu đãi hot</option>
                    <option value="f&b">Góc ăn uống</option>
                    <option value="shopping">Góc mua sắm</option>
                  </Form.Select>
                  {validationErrors.category && <span style={{ color: 'red' }}>{validationErrors.category}</span>}

                </Form.Group>
              </div>
              <div className='col-sm-6'>
                {/*hiển thị Wogi_id */}
                <Form.Group className="mb-3" controlId="wogi_id">
                  <Form.Label>Wogi_id</Form.Label>
                  <Form.Control
                    type="text"
                    name="wogi_id"
                    value={formData.wogi_id}
                    onChange={handleChange}
                  />
                  {validationErrors.rule && <span style={{ color: 'red' }}>{validationErrors.rule}</span>}
                </Form.Group>
              </div>
            </div>
            <Form.Group className="mb-3" controlId="description">
              <Form.Label>Mô tả<span style={{ color: 'red' }}>*</span></Form.Label>
              <Form.Control
                as="textarea"
                rows={5}
                type="text"
                name="description"
                maxLength={200}
                value={formData.description}
                onChange={handleChange}
                onBlur={handleBlur}
                required
              />
              {formData.description.length > 200 && (
                <div style={{ color: 'red', }}>
                  Vượt quá giới hạn ký tự: {formData.description.length - 200} ký tự
                </div>
              )}{validationErrors.description && <span style={{ color: 'red' }}>{validationErrors.description}</span>}
            </Form.Group>

            <div className='row'>
              {/*hiển thị Thương hiệu */}
              <div className='col-sm-6'>
                <Form.Group className="mb-3" controlId="brand">
                  <Form.Label>Thương hiệu<span style={{ color: 'red' }}>*</span></Form.Label>
                  <Form.Control
                    type="text"
                    name="brand"
                    value={formData.brand}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    required
                  />
                  {validationErrors.brand && <span style={{ color: 'red' }}>{validationErrors.brand}</span>}
                </Form.Group>
              </div>
              {/* hiển thị Điểm */}
              <div className='col-sm-6'>
                <Form.Group className="mb-3" controlId="point">
                  <Form.Label>
                    Point <span style={{ color: 'red' }}>*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="point"
                    value={formData.point}
                    onChange={(e) => {
                      handleChange(e);
                      handnumberChange(e);
                    }}
                    onBlur={handleBlur}
                    required
                  />
                  {validationErrors.point && <span style={{ color: 'red' }}>{validationErrors.point}</span>}
                  {formData.point && !validationErrors.point && isNaN(formData.point) && (
                    <span style={{ color: 'red' }}>Vui lòng chỉ nhập số.</span>
                  )}
                </Form.Group>
              </div>
            </div>
            {/* Điều kiện sử dụng */}
            <Form.Group className="mb-3" controlId="rule">
              <Form.Label>Điều kiện sử dụng <span style={{ color: 'red' }}>*</span></Form.Label>
              <Form.Control
                as="textarea"
                rows={5}
                name="rule"
                value={rule}
                onChange={handleChangeRule}
                onBlur={handleBlur}
                required
              />
              {validationErrors.rule && <span style={{ color: 'red' }}>{validationErrors.rule}</span>}
            </Form.Group>
            <Form.Group className="mb-3" controlId="is_active">
              <Form.Label style={{ marginRight: '10px' }}>Trạng thái:</Form.Label>
              <div style={{ display: 'inline-block', marginRight: '20px' }}>
                <Form.Switch
                  name="is_active"
                  checked={formData.is_active}
                  onChange={handleToggleChange}
                />
              </div>
              <span
                style={{
                  backgroundColor: formData.is_active ? '#00ff62' : '#df2d05',
                  color: '#ffffff',
                  borderRadius: '8px',
                  border: `1px solid ${formData.is_active ? '#00ff62' : '#df2d05'}`,
                  padding: '5px 10px',
                  fontSize: '13px',
                  fontWeight: 'bold',
                }}
              >
                {formData.is_active ? 'Đang hoạt động' : 'Đã khóa'}
              </span>
            </Form.Group>

            <div className='row'>
              {/* nơi hiển thi ngày giờ*/}
              <div className='col-sm-6'>
                <Form.Group className="mb-3" controlId="time_from">
                  <Form.Label>Ngày bắt đầu<span style={{ color: 'red' }}>*</span></Form.Label><br></br>
                  <CustomDateTimePicker selectedDate={formData.time_from} onChange={handleStartDateChange} />
                </Form.Group>
              </div>
              <div className='col-sm-6'>
                <Form.Group className="mb-3" controlId="time_to">
                  <Form.Label>
                    Ngày kết thúc<span style={{ color: 'red' }}>*</span>
                  </Form.Label>
                  <div className="datetime-picker-container">
                    <CustomDateTimePicker selectedDate={formData.time_to} onChange={handleEndDateChange} />
                  </div>
                </Form.Group>
              </div>
            </div>
          </div>
          <div className="col-md-6 ">
            {/*hiển thi ảnh banner*/}
            <Form.Group className="mb-3" controlId="image">
              {formData ? (
                <div>
                  <div className="text-center">
                    <Form.Label>
                      Ảnh Banner
                      <span style={{ color: 'red' }}>*</span>
                    </Form.Label>
                  </div>
                  <div className="image-container">
                    <img
                      src={bannerImagePreview || formData.image}
                      alt="Ảnh Banner"
                      style={{ maxWidth: '100%', maxHeight: '300px', }}
                    />
                    {/*nút tải ảnh*/}
                    <FileInput id="fileInput" text='Tải ảnh Banner' onChange={handleImageChange} />
                    {/* thông báo lỗi khi không có ảnh banner */}
                    {bannerImageError && <span style={{ color: 'red' }}>Vui lòng chọn ảnh banner!</span>}
                    {largeImageError && <span style={{ color: 'red' }}>Vui lòng chọn ảnh có kích thước nhỏ hơn 1 MB</span>}
                  </div>
                </div>
              ) : (
                <div className="spinner-container">
                  <div className="spinner-border text-secondary"></div>
                </div>
              )}
            </Form.Group>
            {/* nơi hiển thi ảnh chi tiêt*/}
            <Form.Group className="mb-3" controlId="detail_images">
              <div className="text-center">
                <Form.Label>
                  Ảnh Chi Tiết <span style={{ color: 'red' }}>*</span>
                </Form.Label>
              </div>
              {detailImages.length > 0 && (
                <div>
                  <div className="detail-images-container text-center">
                    {detailImages.map((imageUrl, index) => (
                      <div key={index} className="detail-image-item">
                        <div className="image-wrapper">
                          <img src={imageUrl} alt={`Ảnh chi tiết ${index}`} className="detail-image" />
                        </div>
                        <button
                          type="button"
                          className="btn btn-danger btn-sm delete-btn"
                          onClick={() => handleRemoveImage(index)}
                          style={{ borderRadius: '50%' }}
                        >
                          X
                        </button>
                      </div>
                    ))}
                  </div>
                </div>
              )}
              {/*nút tải ảnh*/}
              <MultipleFileInput id="detailImagesInput" text='Tải ảnh Chi tiết' onChange={handleDetailImagesChange} />

              {detailImages.length === 0 && (
                <div className='image-container'>
                  <span style={{ color: 'red' }}>Vui lòng chọn ít nhất 1 ảnh chi tiết!</span>
                </div>
              )}
            </Form.Group>
          </div>
          {/*nút cập nhật */}
          <div className="d-flex justify-content-center mt-3" style={{ marginBottom: '30px' }}>
            <button className="btn btn-success">
              Cập nhật
            </button>
          </div>
        </div>
      )}
    </Form>
  );
}


import { TextField } from '@mui/material';
import Button from '@mui/material/Button';
import { Form } from 'react-bootstrap';
import FileInput from '../../../components/FileInput/FileInput';
import { FaCheck, FaTimes } from "react-icons/fa";
import './updateworkship.scss'
import No from '../../../assets/images/No.jpg'
export function UpdateWorkShip() {
  return (
    <div className="container-update-workship m-3">
      <div className="row justify-content-center">
        <div className='container container-blog-new-offting'>
          <h3 className='h3 text-center mt-3'>Chỉnh sửa danh mục</h3>
          <div className='row'>
            <div className="col">
              <TextField
                id=""
                label={
                  <span>
                    Nhập tên danh mục <span className="red-asterisk">*</span>
                  </span>
                }
                variant="outlined"
                size="small"
                className="edit-text-field"
              />
            </div>
            <div className='col blog-img-wordship'>
              <Form.Group className="mb-3" controlId="image">
                <div className="image-container">
                  <img src={No} alt="" className="d-block img-add-wordship" width='300' height='300'
                    style={{ maxWidth: '100%', marginTop: '10px', maxHeight: '500px', marginBottom: '20px' }} />
                </div>
                <FileInput id="avataInput" text='Chọn ảnh' />
              </Form.Group>
            </div>
            <div className='btn-new-offering d-flex justify-content-center'>
              <div className='btn-container'>
                <Button type="button" variant="contained" color="error" startIcon={<FaTimes />} style={{ marginRight: '10px',textTransform:'math-auto' }}>
                  Hủy
                </Button>
                <Button type="button" variant="contained" color="success" startIcon={<FaCheck />} style={{ marginLeft: '10px',textTransform:'math-auto' }}>
                  Cập nhật
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

import React, { useState } from 'react';
import { Form, Button, Alert } from 'react-bootstrap';
import CustomDateTimePicker from './TimePicker/CustomDateTimePicker';
import { postNewPromotion } from '../../services/promotion';
import numeral from 'numeral';
import FormData from 'form-data';
import FileInput from '../../components/FileInput/FileInput';
import MultipleFileInput from '../../components/FileInput/MultipleFileInput';
import './style/promotion.scss';


export function AddPromotion() {
  // State để lưu thông tin form và thông báo lỗi
  const [formData, setFormData] = useState({
    title: '',
    code: '',
    type: '',
    point: '',
    description: '',
    brand: '',
    rule: '',
    is_active: true,
    image: null,
    time_from: new Date(),
    time_to: new Date(),
    detail_images: [],
  });
  const [validationErrors, setValidationErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState('');
  // Xử lý thay đổi giá trị các trường trong form
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const formatMoney = (value) => numeral(value).format('0,0')
  const handnumberChange = (e) => {
    const { name, value } = e.target;
    // Kiểm tra nếu giá trị mới không phải là số nguyên dương lớn hơn 0, không cập nhật state và hiển thị thông báo lỗi
    if (!Number.isInteger(Number(value)) || Number(value) <= 0) {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        [name]: 'Vui lòng chỉ nhập số nguyên dương lớn hơn 0.',
      }));
    } else {
      // Nếu giá trị mới hợp lệ, xóa thông báo lỗi và cập nhật state
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        [name]: null,
      }));
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };
  // Xử lý thay đổi ảnh banner
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFormData({ ...formData, image: file });
    }
  };
  // Xử lý thay đổi trạng thái (is_active)
  const handleToggleChange = (e) => {
    setFormData({ ...formData, is_active: e.target.checked });
  };
  // Xử lý kiểm tra và hiển thị lỗi khi người dùng blur khỏi trường
  const handleBlur = (e) => {
    const { name, value } = e.target;
    if (!value) {
      setValidationErrors({ ...validationErrors, [name]: 'Vui lòng điền dữ liệu!' });
    } else {
      setValidationErrors({ ...validationErrors, [name]: null });
    }
  };
  // xử lý ảnh chi tiết
  const [detailImages, setDetailImages] = useState([]);
  const handleDetailImagesChange = (e) => {
    const files = e.target.files;
    if (files && files.length > 0) {
      const urls = Array.from(files).map((file) => URL.createObjectURL(file));
      setDetailImages(urls);
      setFormData({ ...formData, detail_images: files });
    }
  };
  // Xóa ảnh chi tiết
  const handleRemoveImage = (index) => {
    const updatedImages = detailImages.filter((_, i) => i !== index);
    setDetailImages(updatedImages);
    const updatedFormData = { ...formData };
    updatedFormData.detail_images = updatedImages;
    setFormData(updatedFormData);
  };
  // thông báo lỗi khi không chọn ảnh 
  const showBannerImageError = () => {
    return <span style={{ color: 'red' }}>Vui lòng chọn ảnh banner!</span>;
  };
  const showDetailImagesError = () => {
    return <span style={{ color: 'red' }}>Vui lòng chọn ít nhất 1 ảnh chi tiết!</span>;
  };
  // Xử lý khi người dùng nhấn nút "Thêm"
  const handleSubmit = async (e) => {
    e.preventDefault();
    const invalidFields = Object.keys(validationErrors).filter((field) => !formData[field]);
    if (invalidFields.length > 0) {
      alert('Vui lòng điền đầy đủ thông tin');
      return;
    }
    try {
      let fData = new FormData();
      fData.append('images', formData.image);
      for (let i = 0; i < formData.detail_images.length; i++) {
        fData.append('images', formData.detail_images[i]);
      }
      fData.append('title', formData.title);
      const existingPromotionCodes = [];
      const code = formData.code.toUpperCase();
      if (existingPromotionCodes.includes(code)) {
        alert('Mã khuyến mãi đã tồn tại. Vui lòng chọn mã khác.');
        return;
      }
      fData.append('code', code);
      fData.append('type', formData.type);
      fData.append('category', formData.category);
      fData.append('type_discount', formData.type_discount);
      fData.append('discount', formData.discount);
      fData.append('point', formData.point);
      fData.append('description', formData.description);
      fData.append('brand', formData.brand);
      fData.append('is_active', formData.is_active);
      fData.append('time_from', formData.time_from);
      fData.append('time_to', formData.time_to);
      // Chuyển đổi chuỗi rule thành đối tượng { rules: [] }
      const ruleObject = { rules: formData.rule.trim().split('\n') };
      fData.append('rule', JSON.stringify(ruleObject));
      const response = await postNewPromotion(fData);
      setSuccessMessage('Tạo mới thành công!');
      setTimeout(() => {
        setSuccessMessage('');
      }, 2000);
      setFormData({
        title: '',
        code: '',
        type: '',
        type_discount: '',
        discount: '',
        category: '',
        point: '',
        description: '',
        brand: '',
        rule: '',
        is_active: true,
        image: null,
        time_from: new Date(),
        time_to: new Date(),
        detail_images: [],
      });
    } catch (error) {
      console.error('Không thể call API:', error);
    }
  };
  // Xử lý thay đổi ngày bắt đầu và kết thúc
  const handleStartDateChange = (date) => {
    setFormData({ ...formData, time_from: date });
  };
  const handleEndDateChange = (date) => {
    setFormData({ ...formData, time_to: date });
  };
  // Tạo URL cho ảnh banner (nếu có)
  const bannerImageUrl = formData.image ? URL.createObjectURL(formData.image) : '';
  // Render giao diện
  return (
    <Form onSubmit={handleSubmit} className='container-promotion' style={{ margin: '20px' }} >
      <div className='row'>
        <div className='col-sm-6'>
          {/* Tiêu đề */}
          <Form.Group className="mb-3" controlId="title">
            <Form.Label>Tiêu đề<span style={{ color: 'red' }}>*</span></Form.Label>
            <Form.Control
              type="text"
              name="title"
              required
              value={formData.title}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {validationErrors.title && <span style={{ color: 'red' }}>{validationErrors.title}</span>}
          </Form.Group>

          <div className='row'>
            <div className='col-sm-6'>
              {/* mã khuyến mãi */}
              <Form.Group className="mb-3" controlId="code">
                <Form.Label>Mã khuyến mãi<span style={{ color: 'red' }}>*</span></Form.Label>
                <Form.Control
                  type="text"
                  name="code"
                  required
                  value={formData.code}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {validationErrors.code && <span style={{ color: 'red' }}>{validationErrors.code}</span>}
              </Form.Group>
            </div>
            <div className='col-sm-6'>
              {/* Phân loại */}
              <Form.Group className="mb-3" controlId="type">
                <Form.Label>Phân loại<span style={{ color: 'red' }}>*</span></Form.Label>
                <Form.Select
                  name="type"
                  value={formData.type}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  required
                >
                  <option value="">-- Chọn phân loại --</option>
                  <option value="in-app">Ting ưu đãi</option>
                  <option value="3rd">Đối tác bên ngoài</option>
                </Form.Select>
                {validationErrors.type && <span style={{ color: 'red' }}>{validationErrors.type}</span>}
              </Form.Group>
            </div>
          </div>
          <div className='row'>
            <div className='col-sm-6'>
              {/* Loại giảm giá */}
              <Form.Group className="mb-3" controlId="type_discount">
                <Form.Label>Loại giảm giá<span style={{ color: 'red' }}>*</span></Form.Label>
                <Form.Select
                  name="type_discount"
                  value={formData.type_discount}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  required
                >
                  <option value="">-- Chọn Loại giảm giá --</option>
                  <option value="percent">Phần %</option>
                  <option value="money">Tiền</option>
                </Form.Select>
                {validationErrors.type_discount && <span style={{ color: 'red' }}>{validationErrors.type_discount}</span>}
              </Form.Group>
            </div>
            <div className='col-sm-6'>
              {/* Giảm giá */}
              <Form.Group className="mb-3" controlId="discount">
                <Form.Label>Giảm giá <span style={{ color: 'red' }}>*</span></Form.Label>
                <div className="input-group">
                  <Form.Control
                    type="text"
                    name="discount"
                    required
                    value={formData.type_discount === 'percent' ? formatMoney(formData.discount) : formatMoney(formData.discount)}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {formData.type_discount && (
                    <div className="input-group-append">
                      <span className="input-group-text">
                        {formData.type_discount === 'percent' ? '%' : 'đ'}
                      </span>
                    </div>
                  )}
                </div>
                {validationErrors.discount && (
                  <span style={{ color: 'red' }}>{validationErrors.discount}</span>
                )}
              </Form.Group>

            </div>
          </div>
          <div className='row'>
            <div className='col-sm-6'>
              {/* Phân loại */}
              <Form.Group className="mb-3" controlId="category">
                <Form.Label>Danh mục<span style={{ color: 'red' }}>*</span></Form.Label>
                <Form.Select
                  name="category"
                  value={formData.category}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  required
                >
                  <option value="">-- Chọn danh mục --</option>
                  <option value="hot">Ưu đãi hot</option>
                  <option value="f&b">Góc ăn uống</option>
                  <option value="shopping">Góc mua sắm</option>
                </Form.Select>
                {validationErrors.category && <span style={{ color: 'red' }}>{validationErrors.category}</span>}
              </Form.Group>
            </div>
            <div className='col-sm-6'>
              {/* Wogi_id */}
              <Form.Group className="mb-3" controlId="wogi_id">
                <Form.Label>Wogi_id</Form.Label>
                <Form.Control
                  type="text"
                  name="wogi_id"
                  value={formData.wogi_id}
                  onChange={handleChange}
                />

              </Form.Group>
            </div>
          </div>

          {/* Mô tả */}
          <Form.Group className="mb-3" controlId="description">
            <Form.Label>Mô tả<span style={{ color: 'red' }}>*</span></Form.Label>
            <Form.Control
              as="textarea"
              rows={5}
              type="text"
              name="description"
              value={formData.description}
              onChange={handleChange}
              onBlur={handleBlur}
              required
            />
            {validationErrors.description && <span style={{ color: 'red' }}>{validationErrors.description}</span>}
          </Form.Group>
          <div className='row'>



            {/* Thương hiệu */}
            <div className='col-sm-6'>
              <Form.Group className="mb-3" controlId="brand">
                <Form.Label>Thương hiệu<span style={{ color: 'red' }}>*</span></Form.Label>
                <Form.Control
                  type="text"
                  name="brand"
                  value={formData.brand}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  required
                />
                {validationErrors.brand && <span style={{ color: 'red' }}>{validationErrors.brand}</span>}
              </Form.Group></div>
            {/* Điểm */}
            <div className='col-sm-6'>
              <Form.Group className="mb-3" controlId="point">
                <Form.Label>
                  Point <span style={{ color: 'red' }}>*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  name="point"
                  value={formData.point}
                  onChange={(e) => {
                    handleChange(e);
                    handnumberChange(e);
                  }}
                  onBlur={handleBlur}
                  required
                />
                {validationErrors.point && <span style={{ color: 'red' }}>{validationErrors.point}</span>}
                {formData.point && !validationErrors.point && isNaN(formData.point) && (
                  <span style={{ color: 'red' }}>Vui lòng chỉ nhập số.</span>
                )}
              </Form.Group>
            </div>
          </div>

          {/* Điều kiện sử dụng */}
          <Form.Group className="mb-3" controlId="rule">
            <Form.Label>Điều kiện sử dụng <span style={{ color: 'red' }}>*</span></Form.Label>
            <Form.Control
              as="textarea"
              rows={5}
              name="rule"
              value={formData.rule}
              onChange={handleChange}
              onBlur={handleBlur}
              required
            />
            {validationErrors.rule && <span style={{ color: 'red' }}>{validationErrors.rule}</span>}
          </Form.Group>
          {/* Trạng thái */}
          <Form.Group className="mb-3" controlId="is_active">
            <Form.Label style={{ marginRight: '10px' }}>Trạng thái:</Form.Label>
            <div style={{ display: 'inline-block', marginRight: '20px' }}>
              <Form.Switch
                name="is_active"
                checked={formData.is_active}
                onChange={handleToggleChange}
              />
            </div>
            <span
              style={{
                backgroundColor: formData.is_active ? '#00ff62' : '#df2d05',
                color: '#ffffff',
                borderRadius: '8px',
                border: `1px solid ${formData.is_active ? '#00ff62' : '#df2d05'}`,
                padding: '5px 10px',
                fontSize: '13px',
                fontWeight: 'bold',
              }}
            >
              {formData.is_active ? 'Đang hoạt động' : 'Đã khóa'}
            </span>
          </Form.Group>
          {/* Ngày bắt đầu */}
          <div className='row'>
            <div className='col-sm-6'>
              <Form.Group className="mb-3" controlId="time_from">
                <Form.Label>Ngày bắt đầu<span style={{ color: 'red' }}>*</span></Form.Label><br></br>
                <CustomDateTimePicker selectedDate={formData.time_from} onChange={handleStartDateChange} />
              </Form.Group>
            </div>
            {/* Ngày kết thúc */}
            <div className='col-sm-6'>
              <Form.Group className="mb-3" controlId="time_to">
                <Form.Label>
                  Ngày kết thúc<span style={{ color: 'red' }}>*</span>
                </Form.Label>
                <div className="datetime-picker-container ">
                  <CustomDateTimePicker selectedDate={formData.time_to} onChange={handleEndDateChange} />
                </div>
              </Form.Group>
            </div>
          </div>
        </div>
        <div className='col-sm-6'>
          {/* Ảnh banner */}
          <Form.Group className="mb-3" controlId="image">
            <div className="text-center">
              <Form.Label>
                Ảnh Banner
                <span style={{ color: 'red' }}>*</span>
              </Form.Label>
            </div>
            <div className="image-container">
              {/* Hiển thị thông báo lỗi cho ảnh banner */}
              {!formData.image && showBannerImageError()}
            </div>
            {/* Hiển thị ảnh đã chọn */}
            {bannerImageUrl && <img src={bannerImageUrl || formData.image} alt="Banner Preview"
              style={{ maxWidth: '100%', marginTop: '10px', maxHeight: '400px' }} />}
            <FileInput id="fileInput" text='Tải ảnh Banner' onChange={handleImageChange} />

          </Form.Group>
          {/* nơi hiển thi ảnh chi tiêt*/}
          <Form.Group className="mb-3" controlId="detail_images">
            <div className="text-center">
              <Form.Label>
                Ảnh Chi Tiết <span style={{ color: 'red' }}>*</span>
              </Form.Label>
            </div>
            {detailImages.length > 0 && (
              <div>
                <div className="detail-images-container text-center">
                  {detailImages.map((imageUrl, index) => (
                    <div key={index} className="detail-image-item">
                      <div className="image-wrapper">
                        <img src={imageUrl} alt={`Ảnh chi tiết ${index}`} className="detail-image" />
                      </div>
                      <button
                        type="button"
                        className="btn btn-danger btn-sm delete-btn"
                        onClick={() => handleRemoveImage(index)}
                        style={{ borderRadius: '50%' }}
                      >
                        X
                      </button>
                    </div>
                  ))}
                </div>
              </div>
            )}
            {/* ảnh chi tiết */}
            <MultipleFileInput id="detailImagesInput" text='Tải ảnh Chi tiết' onChange={handleDetailImagesChange} />
            {detailImages.length === 0 && (
              <div className='image-container'>
                <span style={{ color: 'red' }}>Vui lòng chọn ít nhất 1 ảnh chi tiết!</span>
              </div>
            )}
          </Form.Group>
        </div>
      </div >
      {/* Button Thêm */}
      <div div className="d-flex justify-content-center mt-3" >
        <button className="btn btn-success">
          Thêm mới
        </button>
      </div >
      {/* Thông báo thành công */}
      {
        successMessage && (
          <Alert
            variant="success"
            onClose={() => setSuccessMessage('')}
            dismissible
            style={{ position: 'fixed', bottom: '15px', right: '15px', zIndex: 9999 }}
          >
            {successMessage}
          </Alert>
        )
      }
    </Form >
  );
}

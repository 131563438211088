import React from 'react';
import { Link } from 'react-router-dom';
import { FiMoreVertical } from 'react-icons/fi';
import { FaEyeDropper, FaLock } from 'react-icons/fa';

export default function TabWorkShip({
  workships,
  getStatusWorkShip,
  showAdditionalIcons,
  handleEllipsisClick,
  setShowAdditionalIcons,
  updateOfferingStatus,
}) {
  return (
    <div className='cart-table table-responsive'>
      <table className="table table-prodcut table-sm table-hover">
        <thead>
          <tr className='text-center'>
            <th>ID</th>
            <th>Ảnh</th>
            <th>Tên danh mục</th>
            <th>Slug</th>
            <th>Trạng thái</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {workships.map((workship, index) => (
            <tr key={index} className='text-center'>
              <td style={{ verticalAlign: 'middle' }}>{workship.id}</td>
              <td>
                <img src={workship.icon}
                  alt=""
                  width="50"
                  height="50"
                  style={{ verticalAlign: 'middle', textAlign: 'center' }} />
              </td>
              <td style={{ verticalAlign: 'middle' }}>{workship.name}</td>
              <td style={{ verticalAlign: 'middle' }}>{workship.slug}</td>
              <td style={{ verticalAlign: 'middle' }}>
                <button className={getStatusWorkShip(workship.is_active)}>
                  {workship.is_active ? 'Đang hoạt động' : 'Bị khóa'}
                </button>
              </td>
              <td style={{ position: 'relative', verticalAlign: 'middle' }}>
                <FiMoreVertical
                  onClick={() => handleEllipsisClick(index)}
                  style={{ cursor: 'pointer', marginRight: '2px' }}
                />
                {showAdditionalIcons === index && (
                  <div className="additional-icons-container">
                    <div className="additional-icon">
                      <Link to={`/workship/${workship.id}`} className="btn-edit-worship ">
                        <FaEyeDropper
                          style={{
                            marginRight: '10px',
                            color: 'green',
                          }}
                        />
                        Sửa
                      </Link>
                    </div>
                    <div
                      className="additional-icon"
                      onClick={() => {
                        updateOfferingStatus(index);
                        setShowAdditionalIcons(null);
                      }}
                      style={{
                        marginRight: '2px',
                        width: 'auto',
                        color: workship.is_active ? 'red' : 'green',
                      }}
                    >
                      <FaLock style={{ marginRight: '10px' }} />
                      <span style={{ fontWeight: '500' }}>
                        {workship.is_active ? 'Khóa' : 'Mở khóa'}
                      </span>
                    </div>
                  </div>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

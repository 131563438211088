import { Axios } from "../utils/apiHelper";
// list blog
export const getListBlogs = async ({ current_page = 1, limit = 20 }) => {
  const url = `blogs/staff-get-list-blog?current_page=${current_page}&limit=${limit}`;
  return await Axios.get(url);
};
// deltail blog
export const getDetailBlog = async (blog_id) => {
  const url = `blogs/staff-get-detail-blog/${blog_id}`;
  return await Axios.get(url);
};
//create blog
export const createNewBlogs = async (data) => {
  const url = 'blogs/staff-create-blog';
  return await Axios.post(url, data);
};
//update blog
export const updateBlog = async (blog_id, data) => {
  const url = `blogs/staff-update-blog/${blog_id}`;
  return await Axios.put(url, data);
};
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getAllPromotions, putUpdatePromotion } from '../../services/promotion';
import AddButon from '../../components/Button/AddButton';
import LoadingSpinner from '../../components/Loading/LoadingSpinner'
import { Form } from 'react-bootstrap';
import './style/ListPromotion.scss';
export function ListPromotion() {
  const [promotions, setPromotions] = useState([]);
  const [loading, setLoading] = useState(true);

  const formatDateTime = (dateTime) => {
    const dateObj = new Date(dateTime);
    const year = dateObj.getFullYear();
    const month = String(dateObj.getMonth() + 1).padStart(2, '0');
    const day = String(dateObj.getDate()).padStart(2, '0');
    const hours = String(dateObj.getHours()).padStart(2, '0');
    const minutes = String(dateObj.getMinutes()).padStart(2, '0');
    const seconds = String(dateObj.getSeconds()).padStart(2, '0');
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };
  useEffect(() => {
    fetchPromotions();
  }, []);

  const fetchPromotions = async () => {
    try {
      const response = await getAllPromotions();
      setPromotions(response.data.data);
      setLoading(false);
    } catch (error) {
      console.error('Lỗi, không thể hiện danh sách khuyến mãi:', error);
      setLoading(false);
    }
  };
  const groupPromotions = () => {
    const groups = [];
    const groupSize = 3;

    for (let i = 0; i < promotions.length; i += groupSize) {
      groups.push(promotions.slice(i, i + groupSize));
    }
    return groups;
  };
  const handleToggleChange = async (promotionId) => {
    try {
      const updatedPromotions = promotions.map((promotion) =>
        promotion.id === promotionId
          ? { ...promotion, is_active: !promotion.is_active }
          : promotion
      );
      setPromotions(updatedPromotions);
      await putUpdatePromotion(promotionId, { is_active: !updatedPromotions.find(p => p.id === promotionId).is_active });
    } catch (error) {
      console.error('Lỗi, không thể cập nhật trạng thái khuyến mãi:', error);
    }
  };
  return (
    <div className='container-listpromotion  m-3'>
      <Link to="/promotion/create_promotion">
        <AddButon text='Tạo khuyến mãi' s />
      </Link>
      {loading ? (
        <LoadingSpinner />
      ) : (
        // Hiển thị dữ liệu sau khi đã được tải
        groupPromotions().map((group, index) => (
          <div key={index} className='row'>
            {group.map((promotion) => (
              <div key={promotion.id} className="card-promotion col-sm-4">
                <div className="card" style={{ width: "100%" }}>
                  <img className="card-img-top" src={promotion.image} alt="images_banner" style={{ width: "100%" }} />
                  <div className="card-body">
                    <h4 className="card-title" style={{ height: '45px', width: '100%', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                      {promotion.title}
                    </h4>
                    <Form.Group className="mb-2" controlId="is_active">
                      <Form.Label style={{ marginRight: '10px' }}>Trạng thái:</Form.Label>
                      <div style={{ display: 'inline-block', marginRight: '20px' }}>
                        <Form.Switch
                          name="is_active"
                          checked={promotion.is_active}
                          onChange={() => handleToggleChange(promotion.id)}
                        />
                      </div>
                      <span
                        style={{
                          backgroundColor: promotion.is_active ? '#00ff62' : '#df2d05',
                          color: '#ffffff',
                          borderRadius: '8px',
                          border: `1px solid ${promotion.is_active ? '#00ff62' : '#df2d05'}`,
                          padding: '5px 10px',
                          fontSize: '13px',
                          fontWeight: 'bold',
                        }}
                      >
                        {promotion.is_active ? 'Đang hoạt động' : 'Đã khóa'}
                      </span>
                    </Form.Group>
                    <div className='row time-mobile'>
                      {/* nơi hiển thi ngày giờ*/}
                      <div className='col-sm-6 time-mobile'>
                        <Form.Group className="" controlId="time_from">
                          <Form.Label className='lable-time'>Ngày bắt đầu<span style={{ color: 'red' }}> *</span></Form.Label><br></br>
                          {formatDateTime(promotion.time_from)}
                        </Form.Group>
                      </div>
                      <div className='col-sm-6 time-mobile'>
                        <Form.Group className="" controlId="time_from">
                          <Form.Label className='lable-time'>
                            Ngày kết thúc<span style={{ color: 'red' }}> *</span></Form.Label><br></br>
                          {formatDateTime(promotion.time_to)}
                        </Form.Group>
                      </div>
                    </div>
                    <Link
                      to={`/promotion/${promotion.id}`}
                      className="btn-detail-promotion"
                      style={{
                        textDecoration: "none", display: "inline-block",
                        position: "relative", color: "#c2187d",
                      }}
                    >
                      Xem chi tiết
                      <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ position: "absolute", top: "50%", transform: "translateY(-50%)", textDecoration: 'underline' }}>
                        <path d="M6.18194 4.18185C6.35767 4.00611 6.6426 4.00611 6.81833 4.18185L9.81833 7.18185C9.90272 7.26624 9.95013 7.3807 9.95013 7.50005C9.95013 7.6194 9.90272 7.73386 9.81833 7.81825L6.81833 10.8182C6.6426 10.994 6.35767 10.994 6.18194 10.8182C6.0062 10.6425 6.0062 10.3576 6.18194 10.1819L8.86374 7.50005L6.18194 4.81825C6.0062 4.64251 6.0062 4.35759 6.18194 4.18185Z" fill="currentColor" fill-rule="evenodd" clip-rule="evenodd" />
                      </svg>
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ))
      )
      }
    </div >
  );
}
